import React from 'react';
import { Card, CardBody } from 'reactstrap';

const Error500 = () => (
  <Card className="text-center">
    <CardBody className="p-5">
      <div className="display-1 text-200 fs-error">500</div>
      <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">Whoops, lỗi gì đó đã xảy ra!</p>
      <hr />
      <p>
        Hãy thử refresh lại trang, hoặc thực hiện lại thao tác thêm một lần. Nếu vấn đề vẫn tiếp diễn thì hãy,
        <a href="https://pety.vn/contact.html" className="mx-1">
          liên hệ
        </a>
        với chúng tôi để được trợ giúp.
      </p>
    </CardBody>
  </Card>
);

export default Error500;
