import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './helpers/initFA';
import AppProvider from './provider/AppProvider';
import J from './lib/Jalio';
import User from './lib/User';

// prepare token
J._token();

// live the user
User.live();

// remove console.log
if (process.env.REACT_APP_DEV_MODE === 'false') console.log = () => {};

ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById('main')
);
