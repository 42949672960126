import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

import PostProvider from '../../provider/PostProvider';
import _ from '../../lib/Var';
import J from '../../lib/Jalio';
import { apis } from '../../lib/Config';
import SinglePost from './Post';
import Loader from '../../components/common/Loader';
import Flex from '../../components/common/Flex';
import Background from '../../components/common/Background';
import corner1 from '../../assets/img/illustrations/corner-1.png';
import AppContext from '../../context/Context';
import ButtonIconTooltip from '../../components/common/ButtonIconTooltip';

const PostWrapper = ({ match }) => {
  const PostId = _.get(match, 'params.id');
  const [Post, setPost] = useState({});
  const [IsLoading, setIsLoading] = useState(false);
  const { isGuest } = useContext(AppContext);

  useEffect(() => {
    if (typeof PostId !== 'undefined' && !IsLoading) {
      setIsLoading(true);
      J.post(apis.post, { PostId })
        .then(res => {
          setPost(_.get(res, 'module.Post'));
        })
        .finally(() => setIsLoading(false));
    }

    // eslint-disable-next-line
  }, [PostId]);

  return (
    <Row className="pt-4" noGutters>
      <Col />
      <Col lg="7">
        {IsLoading && <Loader />}
        {!IsLoading && !!Post && typeof Post.PostId !== 'undefined' && parseInt(Post.PostId) > 0 && (
          <PostProvider Post={Post}>
            {!isGuest && <PostToolbar />}
            <SinglePost />
          </PostProvider>
        )}
        {!IsLoading && (!Post || typeof Post.PostId === 'undefined') && (
          <Card>
            <CardBody className="text-center">
              <div className="my-5 hover-scale">
                <img
                  className="w-50 d-inline-block"
                  alt="Sleepy cat"
                  src={require('../../assets/img/generic/tabby_sleeping.png')}
                />
              </div>
              <p className="text-danger">Không tìm thấy nội dung...</p>
            </CardBody>
          </Card>
        )}
      </Col>
      <Col />
    </Row>
  );
};

const PostToolbar = () => {
  const { history } = useContext(AppContext);
  return (
    <Card className="mb-3 shadow-sm">
      <CardBody tag={Flex} justify="between" className="p-3">
        <Background image={corner1} className="p-card bg-card" style={{ zIndex: 1 }} />
        <ButtonIconTooltip
          style={{ zIndex: 2 }}
          className="btn-default"
          onClick={() => history.goBack()}
          tag="a"
          id="back"
          icon="arrow-left"
          placement="right"
        >
          Quay Lại Trang Trước
        </ButtonIconTooltip>
        <Flex>
          <h4 className="m-0 text-primary font-weight-semi-bold">Chi tiết bài đăng</h4>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default PostWrapper;
