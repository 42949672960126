const shiba = {
  Name: 'Shiba',
  Key: 'shiba',
  Icon: require('./shiba1.gif'),
  Tag: '',
  Items: [
    { Key: 'shiba1.gif', File: require('./shiba1.gif') },
    { Key: 'shiba2.gif', File: require('./shiba2.gif') },
    { Key: 'shiba3.gif', File: require('./shiba3.gif') },
    { Key: 'shiba4.gif', File: require('./shiba4.gif') },
    { Key: 'shiba5.gif', File: require('./shiba5.gif') },
    { Key: 'shiba6.gif', File: require('./shiba6.gif') }
  ]
};
export default shiba;
