import React from 'react';
import { isMobile } from 'react-device-detect';

import ContentWithAsideLayout from '../../layout/ContentWithAsideLayout';
import Feed from './Feed';
import FeedSideBar from './FeedSideBar';
import FeedProvider from '../../provider/FeedProvider';
import _ from '../../lib/Var';

const FeedWrapper = ({ match }) => {
  return (
    <FeedProvider Hashtag={_.get(match, 'params.hashtag')}>
      <ContentWithAsideLayout aside={!isMobile && <FeedSideBar />} isStickyAside={false}>
        <Feed />
      </ContentWithAsideLayout>
    </FeedProvider>
  );
};

export default FeedWrapper;
