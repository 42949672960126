const rabbit = {
  Name: 'Rabbit',
  Key: 'rabbit',
  Icon: require('./rabbit1.gif'),
  Tag: '',
  Items: [
    { Key: 'rabbit1.gif', File: require('./rabbit1.gif') },
    { Key: 'rabbit10.gif', File: require('./rabbit10.gif') },
    { Key: 'rabbit11.gif', File: require('./rabbit11.gif') },
    { Key: 'rabbit12.gif', File: require('./rabbit12.gif') },
    { Key: 'rabbit13.gif', File: require('./rabbit13.gif') },
    { Key: 'rabbit14.gif', File: require('./rabbit14.gif') },
    { Key: 'rabbit15.gif', File: require('./rabbit15.gif') },
    { Key: 'rabbit16.gif', File: require('./rabbit16.gif') },
    { Key: 'rabbit17.gif', File: require('./rabbit17.gif') },
    { Key: 'rabbit18.gif', File: require('./rabbit18.gif') },
    { Key: 'rabbit19.gif', File: require('./rabbit19.gif') },
    { Key: 'rabbit2.gif', File: require('./rabbit2.gif') },
    { Key: 'rabbit20.gif', File: require('./rabbit20.gif') },
    { Key: 'rabbit21.gif', File: require('./rabbit21.gif') },
    { Key: 'rabbit22.gif', File: require('./rabbit22.gif') },
    { Key: 'rabbit23.gif', File: require('./rabbit23.gif') },
    { Key: 'rabbit24.gif', File: require('./rabbit24.gif') },
    { Key: 'rabbit25.gif', File: require('./rabbit25.gif') },
    { Key: 'rabbit26.gif', File: require('./rabbit26.gif') },
    { Key: 'rabbit27.gif', File: require('./rabbit27.gif') },
    { Key: 'rabbit28.gif', File: require('./rabbit28.gif') },
    { Key: 'rabbit29.gif', File: require('./rabbit29.gif') },
    { Key: 'rabbit3.gif', File: require('./rabbit3.gif') },
    { Key: 'rabbit30.gif', File: require('./rabbit30.gif') },
    { Key: 'rabbit31.gif', File: require('./rabbit31.gif') },
    { Key: 'rabbit32.gif', File: require('./rabbit32.gif') },
    { Key: 'rabbit33.gif', File: require('./rabbit33.gif') },
    { Key: 'rabbit34.gif', File: require('./rabbit34.gif') },
    { Key: 'rabbit35.gif', File: require('./rabbit35.gif') },
    { Key: 'rabbit36.gif', File: require('./rabbit36.gif') },
    { Key: 'rabbit37.gif', File: require('./rabbit37.gif') },
    { Key: 'rabbit38.gif', File: require('./rabbit38.gif') },
    { Key: 'rabbit39.gif', File: require('./rabbit39.gif') },
    { Key: 'rabbit4.gif', File: require('./rabbit4.gif') },
    { Key: 'rabbit40.gif', File: require('./rabbit40.gif') },
    { Key: 'rabbit41.gif', File: require('./rabbit41.gif') },
    { Key: 'rabbit5.gif', File: require('./rabbit5.gif') },
    { Key: 'rabbit6.gif', File: require('./rabbit6.gif') },
    { Key: 'rabbit7.gif', File: require('./rabbit7.gif') },
    { Key: 'rabbit8.gif', File: require('./rabbit8.gif') },
    { Key: 'rabbit9.gif', File: require('./rabbit9.gif') }
  ]
};
export default rabbit;
