import React, { Fragment } from 'react';
import { Container, Card, Row, Col } from 'reactstrap';
import LazyLoad from 'react-lazyload';
import knowledge from '../../assets/img/landing/s-knowledge.webp';
import profile from '../../assets/img/landing/s-profile.webp';
import pet from '../../assets/img/landing/s-pet-profile.webp';

const Boards = () => {
  return (
    <Fragment>
      <Container>
        <div className="w-lg-50 text-center mx-lg-auto mt-8 mb-7">
          <span className="d-block font-size-1 font-weight-medium text-uppercase">Hãy xem qua...</span>
          <h2 className="font-size-md-down-3 font-weight-semi-bold">Pety sẽ như ngôi nhà thứ 2</h2>
          <p className="text-muted font-weight-medium">
            Bạn hãy hình dung 10 năm về sau, bạn sẽ vui thế nào khi xem lại những khoảnh khắc vui vẻ với những người bạn
            nhỏ cưng hết nấc của mình...
          </p>
        </div>
        <LazyLoad>
          <Row>
            <Col md="4" sm="12">
              <Card className="shadow-sm mb-3">
                <div className="w-100 bg-soft-primary text-primary text-center rounded p-2 py-4">
                  <img className="w-75" src={knowledge} alt="Share Knowledge" title="Share Knowledge" />
                  <p className="font-weight-bold mt-3 mb-0 text-shadow">Chia sẻ kiến thức</p>
                </div>
              </Card>
            </Col>
            <Col md="4" sm="12">
              <Card className="shadow-sm mb-3">
                <div className="w-100 bg-soft-info text-warning text-center rounded p-2 py-4">
                  <img className="w-75" src={profile} alt="Profile" title="Profile" />
                  <p className="font-weight-bold mt-3 mb-0 text-shadow text-success">Kết nối bạn bè</p>
                </div>
              </Card>
            </Col>
            <Col md="4" sm="12">
              <Card className="shadow-sm mb-3">
                <div className="w-100 bg-soft-danger text-warning text-center rounded p-2 py-4">
                  <img className="w-75" src={pet} alt="Pet Profile" title="Pet Profile" />
                  <p className="font-weight-bold mt-3 mb-0 text-shadow text-danger">Tính năng chuyên biệt</p>
                </div>
              </Card>
            </Col>
          </Row>
        </LazyLoad>
      </Container>
      <div className="p-4 mb-5">
        <div className="text-center my-5">
          <p className="h3 font-weight-bold" data-caption="nhieu dieu thu vi">
            <span className="ml-1">... và hàng ngàn điều thú vị khác</span>
          </p>
        </div>
        <div>
          <LazyLoad>
            <div className="container-fluid">
              <div className="row justify-content-md-center align-items-center mx-n2 mx-lg-n3">
                <div className="d-none d-md-inline-block col-md px-2 px-lg-3">
                  <img
                    alt="app screen 1"
                    className="img-fluid shadow rounded mb-2 mb-lg-3 lazy"
                    src="https://d6cpoqt24b96v.cloudfront.net/v/d4.jpg"
                  />
                  <img
                    alt="app screen 2"
                    className="img-fluid shadow rounded lazy"
                    src="https://d6cpoqt24b96v.cloudfront.net/v/d3.jpg"
                  />
                </div>
                <div
                  className="col-3 col-md px-2 px-lg-3"
                  data-parallaxanimation='[{property: "transform", value:" translate3d(0,{{val}}rem,0)", initial:"7", mid:"0", final:"-7"}]'
                >
                  <img
                    alt="app screen 3"
                    className="img-fluid shadow rounded mb-2 mb-lg-3 lazy"
                    src="https://d6cpoqt24b96v.cloudfront.net/v/d2.jpg"
                  />
                  <img
                    alt="app screen 10"
                    className="img-fluid shadow rounded lazy"
                    src="https://d6cpoqt24b96v.cloudfront.net/v/d10.jpg"
                  />
                </div>
                <div className="col-6 col-md px-2 px-lg-3">
                  <img
                    alt="app screen 4"
                    className="img-fluid shadow rounded mb-2 mb-lg-3 lazy"
                    src="https://d6cpoqt24b96v.cloudfront.net/v/d5.jpg"
                  />
                  <img
                    alt="app screen 5"
                    className="img-fluid shadow rounded lazy"
                    src="https://d6cpoqt24b96v.cloudfront.net/v/d7.jpg"
                  />
                </div>
                <div
                  className="col-3 col-md px-2 px-lg-3"
                  data-parallaxanimation='[{property: "transform", value:" translate3d(0,{{val}}rem,0)", initial:"-7", mid:"0", final:"7"}]'
                >
                  <img
                    alt="app screen 6"
                    className="img-fluid shadow rounded mb-2 mb-lg-3 lazy"
                    src="https://d6cpoqt24b96v.cloudfront.net/v/d8.jpg"
                  />
                  <img
                    alt="app screen 7"
                    className="img-fluid shadow rounded lazy"
                    src="https://d6cpoqt24b96v.cloudfront.net/v/d11.jpg"
                  />
                </div>
                <div className="d-none d-md-inline-block col-md px-2 px-lg-3">
                  <img
                    alt="app screen 8"
                    className="img-fluid shadow rounded mb-2 mb-lg-3 lazy"
                    src="https://d6cpoqt24b96v.cloudfront.net/v/d13.jpg"
                  />
                  <img
                    alt="app screen 9"
                    className="img-fluid shadow rounded lazy"
                    src="https://d6cpoqt24b96v.cloudfront.net/v/d9.jpg"
                  />
                </div>
              </div>
            </div>
          </LazyLoad>
        </div>
      </div>
    </Fragment>
  );
};

export default Boards;
