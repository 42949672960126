const cat = {
  Name: 'Cat',
  Key: 'cat',
  Icon: require('./cat2.gif'),
  Tag: '',
  Items: [
    { Key: 'cat1.gif', File: require('./cat1.gif') },
    { Key: 'cat10.gif', File: require('./cat10.gif') },
    { Key: 'cat11.gif', File: require('./cat11.gif') },
    { Key: 'cat12.gif', File: require('./cat12.gif') },
    { Key: 'cat13.gif', File: require('./cat13.gif') },
    { Key: 'cat14.gif', File: require('./cat14.gif') },
    { Key: 'cat15.gif', File: require('./cat15.gif') },
    { Key: 'cat16.gif', File: require('./cat16.gif') },
    { Key: 'cat17.gif', File: require('./cat17.gif') },
    { Key: 'cat18.gif', File: require('./cat18.gif') },
    { Key: 'cat19.gif', File: require('./cat19.gif') },
    { Key: 'cat2.gif', File: require('./cat2.gif') },
    { Key: 'cat20.gif', File: require('./cat20.gif') },
    { Key: 'cat3.gif', File: require('./cat3.gif') },
    { Key: 'cat4.gif', File: require('./cat4.gif') },
    { Key: 'cat5.gif', File: require('./cat5.gif') },
    { Key: 'cat6.gif', File: require('./cat6.gif') },
    { Key: 'cat7.gif', File: require('./cat7.gif') },
    { Key: 'cat8.gif', File: require('./cat8.gif') },
    { Key: 'cat9.gif', File: require('./cat9.gif') }
  ]
};
export default cat;
