import React, { useContext } from 'react';
import { Card, CardFooter, CardBody, Media } from 'reactstrap';
import PostHeader from './PostHeader';

import { PostContext } from '../../context/Context';
import createMarkup from '../../helpers/createMarkup';
import CountFavo from './CountFavo';
import CountComment from './CountComment';
import TagPets from './Pets';
import { getBgFromName } from '../../assets/pety/bg/bg.js';
import _ from '../../lib/Var';
import { isIterableArray } from '../../helpers/utils';
import { Link } from 'react-router-dom';

const PostSimple = () => {
  const {
    PostedAt,
    PostedBy,
    PostedByAvatar,
    PostedByName,
    ProfileUrl,
    PostUrl,
    ShareWith,
    Content,
    Photos,
    PostTypeId,
    Bg,
    Pets
  } = useContext(PostContext);

  return (
    <Card className="mb-3 shadow-sm">
      {!!PostedBy && <PostHeader {...{ PostedBy, PostedByAvatar, PostedByName, PostedAt, ProfileUrl, ShareWith }} />}
      {!!Content && <PostContent {...{ Content, Photos, Pets, PostTypeId, Bg, PostUrl }} />}

      <CardFooter className="pt-1">
        <div className="fs--1 d-flex flex-wrap">
          <CountFavo />
          <CountComment />
        </div>
      </CardFooter>
    </Card>
  );
};

const PostContent = ({ Content, Photos, Bg, Pets, PostTypeId, PostUrl }) => {
  return (
    <CardBody>
      <Media>
        <Media body>
          <Link to={`/post/${PostUrl}`} style={{ textDecoration: 'none' }} className="text-700">
            <p
              dangerouslySetInnerHTML={createMarkup(
                typeof Content !== 'undefined' && Content.length === 128 ? Content + '...' : Content
              )}
            />
          </Link>
          <TagPets />
        </Media>

        {!!Photos && Photos.length > 0 && (
          <Media right>
            <img
              alt={_.get(Photos[0], 'Name')}
              className="text-center rounded"
              src={_.get(Photos[0], 'FileThumbnail')}
              style={{
                maxWidth: '8rem',
                maxHeight: '6rem'
              }}
            />
          </Media>
        )}

        {isIterableArray(Pets) && parseInt(PostTypeId) === 21 && (
          <Media right>
            <Link to={`/post/${PostUrl}`}>
              <img
                alt={_.get(Pets[0], 'Name')}
                className="text-center rounded"
                src={_.get(Pets[0], 'Avatar')}
                style={{
                  maxWidth: '8rem',
                  maxHeight: '8rem'
                }}
              />
            </Link>
          </Media>
        )}

        {!!Bg && (
          <Media right href="#">
            <Link to={`/post/${PostUrl}`}>
              <Media
                object
                className="text-center rounded"
                style={{
                  width: '5rem',
                  height: '5rem',
                  backgroundImage: `url(${getBgFromName(Bg).file})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'bottom'
                }}
              />
            </Link>
          </Media>
        )}
      </Media>
    </CardBody>
  );
};

export default PostSimple;
