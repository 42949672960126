import React from 'react';
import LazyLoad from 'react-lazyload';
import cats from '../../assets/img/landing/cats.webp';
import hamster from '../../assets/img/landing/hamster.webp';
import shiba from '../../assets/img/landing/shiba.webp';
import kid from '../../assets/img/landing/kid.webp';
import cat from '../../assets/img/landing/cat.webp';

const Dots = () => {
  return (
    <LazyLoad>
      <div className="container position-relative z-index-2">
        <div className="row justify-content-center">
          <div className="col-3 col-lg-2 mt-n7">
            <div className="bg-soft-primary u-lg-avatar shadow-sm rounded-circle p-1 mx-auto">
              <img className="img-fluid lazy" alt="Cat" src={cat} />
            </div>
          </div>
          <div className="col-lg-2 d-none d-lg-inline-block mt-lg-n8">
            <div className="bg-soft-primary u-xl-avatar shadow-sm rounded-circle p-2 mx-auto">
              <img className="img-fluid lazy" alt="Kid" src={kid} />
            </div>
          </div>
          <div className="col-3 col-lg-2 mt-n4">
            <div className="bg-soft-primary u-lg-avatar shadow-sm rounded-circle p-3 mx-auto">
              <img className="img-fluid lazy" alt="Shiba" src={shiba} />
            </div>
          </div>
          <div className="col-3 col-lg-2 d-none d-lg-inline-block mt-n7">
            <div className="bg-soft-primary u-lg-avatar shadow-sm rounded-circle p-2 mx-auto">
              <img className="img-fluid lazy" alt="Hamster" src={hamster} />
            </div>
          </div>
          <div className="col-lg-2 mt-lg-n8">
            <div className="bg-soft-primary u-xl-avatar shadow-sm rounded-circle p-3 mx-auto">
              <img
                className="img-fluid lazy w-80 h-80"
                alt="Pet Owners"
                src="https://d6cpoqt24b96v.cloudfront.net/pety/other.png"
              />
            </div>
          </div>
          <div className="col-3 col-lg-2 d-none d-lg-inline-block">
            <div className="bg-soft-primary u-lg-avatar shadow-sm rounded-circle p-2 mx-auto">
              <img className="img-fluid lazy w-80 h-80" alt="Other Cats" src={cats} />
            </div>
          </div>
        </div>
      </div>
    </LazyLoad>
  );
};

export default Dots;
