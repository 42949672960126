const bird = {
  Name: 'Bird',
  Key: 'bird',
  Icon: require('./big-laugh-bird.png'),
  Tag: '',
  Items: [
    { Key: 'big-laugh-bird.png', File: require('./big-laugh-bird.png') },
    { Key: 'bird-and-coffee.png', File: require('./bird-and-coffee.png') },
    { Key: 'bird-with-flowers.png', File: require('./bird-with-flowers.png') },
    { Key: 'birds-in-love.png', File: require('./birds-in-love.png') },
    { Key: 'earplugs-bird.png', File: require('./earplugs-bird.png') },
    { Key: 'love-on-my-mind.png', File: require('./love-on-my-mind.png') },
    { Key: 'musical-bird.png', File: require('./musical-bird.png') },
    { Key: 'party-bird.png', File: require('./party-bird.png') }
  ]
};
export default bird;
