import React, { useState, useContext } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import Member from './Member';
import { CommentContext } from '../../context/Context';
import Loader from '../common/Loader';
import _ from '../../lib/Var';

const ModalFavoComment = ({ isModalOpen, setIsModalOpen }) => {
  const { PostCommentId, Favo, FavoPeople, loadFavoPeople, FavoPeopleLoadedAll } = useContext(CommentContext);

  const [isLoading, setIsLoading] = useState(false);

  const loadPeople = () => {
    setIsLoading(true);
    loadFavoPeople(() => setIsLoading(false));
  };

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={() => {
        setIsModalOpen(!isModalOpen);
      }}
    >
      <ModalHeader className="py-2" toggle={() => setIsModalOpen(!isModalOpen)}>
        {`${_.round(Favo)} người thích bình luận này`}
      </ModalHeader>
      <ModalBody style={{ maxHeight: '500px', overflow: 'auto' }}>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            !isLoading && !FavoPeopleLoadedAll && loadPeople();
          }}
          threshold={10}
          hasMore={true}
          useWindow={false}
          loader={isLoading && <Loader key="comment-favo-loader" />}
        >
          {FavoPeople.map((Person, Idx) => {
            return <Member {...{ ...Person, At: Person.FavoAt }} key={`comment-${PostCommentId}-favo-${Idx}`} />;
          })}
        </InfiniteScroll>
      </ModalBody>
    </Modal>
  );
};

export default ModalFavoComment;
