import React, { Fragment, useContext, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { isMobile } from 'react-device-detect';

import ComposePost from './ComposePost';
import PostNormal from '../../components/post/PostNormal';
import PostNewPet from '../../components/post/PostNewPet';
import { isIterableArray } from '../../helpers/utils';
import Loader from '../../components/common/Loader';
import { FeedContext } from '../../context/Context';
import PostProvider from '../../provider/PostProvider';
import HashtagTrending from '../../widget/hashtag/HashtagTrending';
import PeopleToFollow from '../../widget/people/PeopleToFollow';
// import LeaderBoard from '../../widget/contest/LeaderBoard';

import AsideLeaderBoard from '../../widget/contest/LeaderBoard';

const Feed = () => {
  const { Posts, loadFeed, LoadedAll } = useContext(FeedContext);
  const [isLoading, setIsLoading] = useState(false);

  const loadPosts = () => {
    if (isLoading || LoadedAll) return;

    setIsLoading(true);

    // load more post
    loadFeed(() => {
      setIsLoading(false);
    });
  };

  return (
    <Fragment>
      <ComposePost />
      <InfiniteScroll
        pageStart={0}
        loadMore={() => {
          !isLoading && loadPosts();
        }}
        threshold={500}
        hasMore={true}
        loader={<Loader key="feed-post-loader" />}
      >
        {isIterableArray(Posts) &&
          Posts.map((Post, Idx) => {
            const PostTypeId = parseInt(Post.PostTypeId);
            return (
              <PostProvider Post={Post} key={`feed-post-${Post.PostId}`}>
                {PostTypeId === 1 && <PostNormal />}
                {PostTypeId === 21 && <PostNewPet />}
                {isMobile && Idx === 9 && <HashtagTrending />}
                {/* {isMobile && Idx === 15 && <AsideLeaderBoard />} */}
                {isMobile && Idx === 19 && <PeopleToFollow />}
              </PostProvider>
            );
          })}
      </InfiniteScroll>
    </Fragment>
  );
};

export default Feed;
