import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import TabBoard from './TabBoard';

const ModalFullBoard = ({ IsModalOpen, setIsModalOpen }) => {
  return (
    <Modal isOpen={IsModalOpen} toggle={() => setIsModalOpen(!IsModalOpen)}>
      <ModalHeader className="py-2 bg-light" toggle={() => setIsModalOpen(!IsModalOpen)}>
        Bảng Xếp Hạng
      </ModalHeader>
      <ModalBody style={{ height: '500px', overflow: 'auto' }} className="p-1 pb-3">
        <TabBoard LoadMore={true} TabId="modal" />
      </ModalBody>
    </Modal>
  );
};

export default ModalFullBoard;
