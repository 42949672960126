import React, { Fragment, useEffect } from 'react';
import NavbarLanding from './NavbarLanding';
import Hero from './Hero';
import Dots from './Dots';
import AboutPety from './AboutPety';
import Features from './Features';
import Boards from './Boards';
import Blog from './Blog';
import Footer from './Footer';
import Videos from './Videos';
import AppReviews from './AppReviews';
import PhotoGrid from './PhotoGrid';

const LandingWrapper = () => {
  useEffect(() => {
    document.body.classList.add('bg-white');
  }, []);
  return (
    <Fragment>
      <NavbarLanding />
      <Hero />
      <Dots />
      <AboutPety />
      <Features />
      <PhotoGrid />
      <AppReviews />
      <Videos />
      <Boards />
      <Blog />
      <Footer />
    </Fragment>
  );
};

export default LandingWrapper;
