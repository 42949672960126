import React, { Fragment } from 'react';
import PropTypes, { string, object } from 'prop-types';
import { Link } from 'react-router-dom';
import Avatar from './Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Chip = ({ size, color, dark, className, icon, avatar, to, selected, disabled, children, onClick, onClose }) => {
  const classNames = [`chip-${size}`, className].join(' ');

  return (
    <ChipWrapper to={to} disabled={disabled} className={classNames} onClick={onClick}>
      <div style={!!color ? { backgroundColor: color, borderColor: color } : null}>
        <div className="chip-media-wrap mr-1">
          {!!avatar && <Avatar className="chip-avatar" src={avatar} />}
          {!avatar && !!icon && <FontAwesomeIcon icon={icon} />}
          {!!selected && (
            <div className="selected">
              <FontAwesomeIcon icon="check" className="text-success" />
            </div>
          )}
        </div>
        <span className={`pr-1 ${!!dark ? 'text-white' : 'text-700'}`}>{children}</span>
        {typeof onClose !== 'undefined' && (
          <div className="chip-close-icon text-danger">
            <FontAwesomeIcon icon="times" onClick={() => onClose()} />
          </div>
        )}
      </div>
    </ChipWrapper>
  );
};

const ChipWrapper = ({ to, disabled, className, onClick, children }) => {
  return (
    <Fragment>
      {!!to && (
        <Link
          to={to}
          disabled={disabled}
          className={`chip ${className}`}
          onClick={() => typeof onClick !== 'undefined' && !to && onClick()}
        >
          {children}
        </Link>
      )}
      {!to && (
        <a
          href="#!"
          disabled={disabled}
          className={`chip ${className}`}
          onClick={() => typeof onClick !== 'undefined' && !to && onClick()}
        >
          {children}
        </a>
      )}
    </Fragment>
  );
};

Chip.propTypes = {
  size: PropTypes.oneOf(['sm', 'lg', 'md']),
  icon: PropTypes.oneOfType([object, string]),
  avatar: PropTypes.string,
  color: PropTypes.string,
  dark: PropTypes.bool,
  className: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  to: PropTypes.any,
  onClick: PropTypes.func,
  onClose: PropTypes.func
};

Chip.defaultProps = {
  size: 'md'
};

export default Chip;
