import React, { useState, useContext, useEffect } from 'react';
import lo from 'lodash';
import AppContext, { PostContext } from '../context/Context';
import { getSticker } from '../components/post/Stickers';
import { Config, apis } from '../lib/Config';
import J from '../lib/Jalio';
import _ from '../lib/Var';
import m from '../lib/Moment';

const PostProvider = ({ children, Post }) => {
  // user from app context
  const [Favo, setFavo] = useState(parseInt(Post.Favo));
  const [Like, setLike] = useState(parseInt(Post.Like));
  const [Love, setLove] = useState(parseInt(Post.Love));
  const [Haha, setHaha] = useState(parseInt(Post.Haha));
  const [Wow, setWow] = useState(parseInt(Post.Wow));
  const [Sad, setSad] = useState(parseInt(Post.Sad));
  const [Angry, setAngry] = useState(parseInt(Post.Angry));
  const [Reacted, setReacted] = useState(Post.Reacted);
  const [PreReacted, setPreReacted] = useState(Post.Reacted);

  const [Favod, setFavod] = useState(parseInt(Post.Favod));
  const [FavoPeople, setFavoPeople] = useState([]);
  const [FavoPeopleLmstart, setFavoPeopleLmstart] = useState(0);
  const [FavoPeopleLoadedAll, setFavoPeopleLoadedAll] = useState(false);
  const [Bookmarked, setBookmarked] = useState(Post.Bookmarked);

  // post comment
  const [LastComment, setLastComment] = useState(Post.LastComment);
  const [NewComments, setNewComments] = useState([]);
  const [CommentPeople, setCommentPeople] = useState([]);
  const [CommentPeopleLmstart, setCommentPeopleLmstart] = useState(0);
  const [CommentPeopleLoadedAll, setCommentPeopleLoadedAll] = useState(false);
  const [CommentPeopleCount, setCommentPeopleCount] = useState(0);
  const [Comments, setComments] = useState([]);
  const [CommentLmstart, setCommentLmstart] = useState(0);
  const [CommentLoadedAll, setCommentLoadedAll] = useState(
    (parseInt(Post.Comment) === 1 && typeof Post.LastComment === 'object') || parseInt(Post.Comment) === 0
  );

  const { User } = useContext(AppContext);

  // function for loading & loading more people did favorite a post
  const loadFavoPeople = callback => {
    const { PostId } = Post;
    if (parseInt(PostId) > 0 && !FavoPeopleLoadedAll) {
      J.post(apis.postFavorite, {
        PostId,
        lmstart: FavoPeopleLmstart,
        limit: Config.listLimit
      }).then(res => {
        // append the result to state
        const People = lo.union(FavoPeople, _.get(res, 'module.Favorites', []));

        // update the provider state
        setFavoPeople(People);

        if (_.get(res, 'module.Favorites', []).length < Config.listLimit) {
          setFavoPeopleLoadedAll(true);
        } else {
          setFavoPeopleLmstart(People.length);
        }

        // exec the callback
        typeof callback !== 'undefined' && callback(People);
      });
    }
  };

  // function for loading & loading more people did comment a post
  const loadCommentPeople = callback => {
    const { PostId } = Post;
    if (parseInt(PostId) > 0 && !CommentPeopleLoadedAll) {
      J.post(apis.postCommentors, {
        PostId,
        lmstart: CommentPeopleLmstart,
        limit: Config.listLimit
      }).then(res => {
        // comment people count
        setCommentPeopleCount(_.get(res, 'module.CountCommentors', 0));

        // append the result to state
        const People = lo.union(CommentPeople, _.get(res, 'module.Commentors', []));

        // update the provider state
        setCommentPeople(People);

        if (People.length >= CommentPeopleCount) {
          setCommentPeopleLoadedAll(true);
        } else setCommentPeopleLmstart(People.length);

        // exec the callback
        typeof callback !== 'undefined' && callback(People, _.get(res, 'module.CountCommentors', 0));
      });
    } else {
      typeof callback !== 'undefined' && callback(CommentPeople);
    }
  };

  const toggleFavo = (Type, callback) => {
    // number of all reacted
    setFavo(parseInt(Favo) + (Reacted === Type ? -1 : parseInt(Favod) === 0 ? 1 : 0));

    // switch Favod flag
    setFavod(Reacted === Type ? 0 : 1);

    // change reacted type
    setReacted(Reacted === Type ? '' : Type);

    // update FavoPeople array
    if (FavoPeople.length > 0) {
      setFavoPeople([
        ...lo.each(FavoPeople, Item => {
          if (Item.UserId === User.UserId) {
            Item.Type = Type;
            return true;
          }
        })
      ]);
    }

    const { PostId } = Post;
    // send the request api
    J.post(apis.favo, { PostId, Type }).then(() => {
      // do the callback
      if (typeof callback !== 'undefined') callback({ Favo, Favod });
    });
  };

  const toggleBookmark = callback => {
    setBookmarked((parseInt(Bookmarked) + 1) % 2);

    const { PostId } = Post;
    // send the request api
    J.post(apis.bookmark, { PostId }).then(res => {
      // do the callback
      if (typeof callback !== 'undefined') callback(res);
    });
  };

  const postComment = (PostData, callback) => {
    // append post to the comment array
    const PostComment = {
      Avatar: User.Avatar,
      Comment: PostData.Comment,
      CommentedAt: m().unix(),
      CommentedBy: User.UserId,
      Commentor: User.Name,
      Favo: 0,
      Favod: 0,
      Name: User.Name,
      PostId: PostData.PostId,
      PostCommentId: 0,
      ReplyTo: PostData.ReplyTo,
      ReplyToName: PostData.ReplyToName,
      UserId: User.UserId,
      ProfileUrl: User.ProfileUrl,
      Sticker: null,
      Photo: null
    };

    // photo or not
    if (PostData.UploadPhoto !== null && PostData.UploadPhoto !== '') {
      PostComment.Photo = _.get(JSON.parse(_.get(PostData, 'UploadPhoto')), 'uri', null);
    }

    // sticker or not
    if (PostData.Sticker !== null && PostData.Sticker !== '') {
      PostComment.Sticker = _.get(getSticker(JSON.parse(_.get(PostData, 'Sticker', ''))), 'File', '');
    }

    NewComments.push(PostComment);
    setNewComments(NewComments);

    J.post(apis.postComment, PostData).then(res => {
      // remove last item and add new item
      PostComment.PostCommentId = _.getInt(res, 'module.Result');
      NewComments.pop();
      NewComments.push(PostComment);
      setNewComments(NewComments);

      typeof callback !== 'undefined' && callback(PostComment);
    });
  };

  const delComment = (PostCommentId, callback) => {
    if (parseInt(PostCommentId) > 0) {
      // remove if it's last comments
      if (
        LastComment !== null &&
        typeof LastComment.PostCommentId !== 'undefined' &&
        parseInt(LastComment.PostCommentId) === parseInt(PostCommentId)
      ) {
        setLastComment(null);
      }

      // remove if it's in comments
      if (Array.isArray(Comments)) {
        setComments(lo.filter(Comments, el => parseInt(el.PostCommentId) !== parseInt(PostCommentId)));
      }

      // remove if it's in the NewComments
      if (Array.isArray(NewComments)) {
        setNewComments(lo.filter(NewComments, el => parseInt(el.PostCommentId) !== parseInt(PostCommentId)));
      }

      // query server to delete
      J.post(apis.delComment, { PostCommentId }).then(res => {
        typeof callback !== 'undefined' && callback(_.get(res, 'module.Result'));
      });
    } else {
      typeof callback !== 'undefined' && callback();
    }
  };

  const loadComment = callback => {
    const { PostId } = Post;
    if (parseInt(PostId) > 0 && !CommentLoadedAll) {
      J.post(apis.comment, { PostId, lmstart: CommentLmstart }).then(res => {
        let NewComments = _.get(res, 'module.Comments', []);

        if (Array.isArray(NewComments) && NewComments.length > 0) {
          setComments(lo.union(NewComments, Comments));
          if (NewComments.length < Config.listLimit) setCommentLoadedAll(true);
          else setCommentLmstart(CommentLmstart + Config.listLimit);
        } else setCommentLoadedAll(true);

        typeof callback !== 'undefined' && callback(Comments);
      });
    } else typeof callback !== 'undefined' && callback(Comments);
  };

  const toggleReacted = (Type, ChangeValue) => {
    switch (Type) {
      case 'Like':
        setLike(Like + ChangeValue);
        break;
      case 'Haha':
        setHaha(Haha + ChangeValue);
        break;
      case 'Wow':
        setWow(Wow + ChangeValue);
        break;
      case 'Sad':
        setSad(Sad + ChangeValue);
        break;
      case 'Angry':
        setAngry(Angry + ChangeValue);
        break;
      default:
        setLove(Love + ChangeValue);
    }
  };

  // change the reacted number
  useEffect(() => {
    if (Reacted !== PreReacted) {
      // toggle reacted quantity
      toggleReacted(Reacted, 1);
      toggleReacted(PreReacted, -1);
    }

    // change pre-reacted
    setPreReacted(Reacted);

    // eslint-disable-next-line
  }, [Reacted]);

  // un-changed Post properties
  const {
    PostedAt,
    PostedBy,
    PostedByAvatar,
    PostedByName,
    ProfileUrl,
    ShareWith,
    Content,
    Photos,
    PostTypeId,
    Bg,
    Comment,
    LastFavorite,
    Pets,
    Followed,
    PostId,
    PostUrl
  } = Post;

  const Values = {
    // favo state
    FavoPeople,
    FavoPeopleLmstart,
    FavoPeopleLoadedAll,
    loadFavoPeople,
    toggleFavo,
    Favo,
    Favod,

    // new reacted quantity
    Like,
    Love,
    Haha,
    Wow,
    Sad,
    Angry,
    Reacted,
    setReacted,

    // comment state
    CommentPeople,
    CommentPeopleLmstart,
    CommentPeopleCount,
    loadCommentPeople,
    CommentLoadedAll,
    postComment,
    delComment,
    Comments,
    loadComment,

    // bookmark state
    Bookmarked,
    toggleBookmark,

    // post properties
    PostedAt,
    PostedBy,
    PostedByAvatar,
    PostedByName,
    ProfileUrl,
    ShareWith,
    Content,
    Photos,
    PostTypeId,
    Bg,
    Comment,
    LastComment,
    NewComments,
    setNewComments,
    LastFavorite,
    Pets,
    Followed,
    PostId,
    PostUrl
  };

  return <PostContext.Provider value={Values}>{children}</PostContext.Provider>;
};

export default PostProvider;
