import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const PreviewSticker = ({ Item, size, onClose }) => {
  return (
    <div
      className="shadow-sm p-0 m-0 rounded"
      style={{
        maxWidth: size === 'lg' ? '16rem' : size === 'sm' ? '4rem' : '8rem',
        position: 'relative',
        backgroundColor: '#fff5ef'
      }}
    >
      {typeof onClose !== 'undefined' && (
        <Button
          style={{ position: 'absolute', top: '0.25rem', right: '0.25rem', width: '1.6rem' }}
          color="link"
          className="rounded-capsule text-white bg-danger p-0 text-center"
          onClick={() => onClose()}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      )}
      <img className="w-100" src={Item.File} alt={Item.Key} />
    </div>
  );
};

export default PreviewSticker;
