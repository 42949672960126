import React, { useState, useEffect } from 'react';
import lo from 'lodash';
import { FeedContext } from '../context/Context';
import { apis, Config } from '../lib/Config';
import J from '../lib/Jalio';
import _ from '../lib/Var';

const FeedProvider = ({ children }) => {
  // states for feed
  const [Posts, setPosts] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [Lmstart, setLmstart] = useState(0);
  const [LoadedAll, setLoadedAll] = useState(false);
  const [Offers, setOffers] = useState({});

  const loadOffers = () => {
    J.get(apis.user.offers).then(res => setOffers(_.get(res, 'module')));
  };

  const loadFeed = callback => {
    if (Loading || LoadedAll) return;

    setLoading(true);
    J.post(apis.newfeed, { lmstart: Lmstart }).then(res => {
      setLoading(false);

      const NewPosts = _.get(res, 'module.Posts', []);

      if (Array.isArray(NewPosts)) {
        // dispatch posts
        setPosts(lo.union(Posts, NewPosts));
        setLmstart(Lmstart + NewPosts.length);

        // check and update the limit-start
        if (NewPosts.length < Config.listLimit) {
          setLoadedAll(true);
        }
      } else setLoadedAll(true);

      typeof callback !== 'undefined' && callback(Posts);
    });
  };

  useEffect(() => {
    loadOffers();
  }, []);

  const Values = {
    Posts,
    Offers,
    Loading,
    Lmstart,
    LoadedAll,
    loadFeed,
    setPosts
  };

  return <FeedContext.Provider value={Values}>{children}</FeedContext.Provider>;
};

export default FeedProvider;
