import React from 'react';
import { Col, Row } from 'reactstrap';

const Footer = () => (
  <footer>
    <Row noGutters className="justify-content-between text-center fs--1 mt-4 mb-3">
      <Col />
      <Col lg="7">
        <p className="m-3 mx-1 text-500">
          © Pety 2019. Toàn bộ bản quyền thuộc về Pety Technology JSC.
          <br />
          Mã số doanh nghiệp: 0316144897, do Sở Kế hoạch và Đầu tư TP. Hồ Chí Minh cấp ngày 14/02/2020.
        </p>
      </Col>
      <Col />
    </Row>
  </footer>
);

export default Footer;
