import React from 'react';
import { Row, Col } from 'reactstrap';

const Videos = () => {
  return (
    <div className="container pt-8" id="about-pety">
      <div className="w-lg-50 text-center mx-auto mb-5">
        <span className="d-block font-size-1 font-weight-medium text-uppercase">Pet Biz...</span>
        <h2 className="font-size-md-down-3 font-weight-semi-bold">
          Gia đình các bé thú cưng nổi tiếng đã tham gia Pety
        </h2>
        <p className="text-muted font-weight-medium">
          MC Hank • Khế Thúi • Bé Xíu Bé Xiu • Moca Chó Điên • Katie Không Ngáo • Nguyễn Văn Dúi • Sashimeomeo • Butin
          siêu lười • Anh Mèo Tên Sâu...
        </p>
      </div>
      <Row>
        <Col md="4" sm="6" className="mb-4">
          <div className="card p-0 shadow-sm">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                loading="lazy"
                title="0HRLMREOiK0"
                className="embed-responsive-item rounded"
                src="https://www.youtube.com/embed/0HRLMREOiK0?rel=0"
                allowFullScreen={true}
              />
            </div>
          </div>
        </Col>
        <Col md="4" sm="6" className="mb-4">
          <div className="card p-0 shadow-sm">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                loading="lazy"
                title="VxOEo0WL-ak"
                className="embed-responsive-item rounded"
                src="https://www.youtube.com/embed/VxOEo0WL-ak?rel=0"
                allowFullScreen={true}
              />
            </div>
          </div>
        </Col>
        <Col md="4" sm="6" className="mb-4">
          <div className="card p-0 shadow-sm">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                loading="lazy"
                title="a_d-o8NszNo"
                className="embed-responsive-item rounded"
                src="https://www.youtube.com/embed/a_d-o8NszNo?rel=0"
                allowFullScreen={true}
              />
            </div>
          </div>
        </Col>
        <Col md="4" sm="6" className="mb-4">
          <div className="card p-0 shadow-sm">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                title="us2XuWm_nPY"
                loading="lazy"
                className="embed-responsive-item rounded"
                src="https://www.youtube.com/embed/us2XuWm_nPY?rel=0"
                allowFullScreen={true}
              />
            </div>
          </div>
        </Col>
        <Col md="4" sm="6" className="mb-4">
          <div className="card p-0 shadow-sm">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                title="sPsN7Aesl5k"
                loading="lazy"
                className="embed-responsive-item rounded"
                src="https://www.youtube.com/embed/sPsN7Aesl5k?rel=0"
                allowFullScreen={true}
              />
            </div>
          </div>
        </Col>
        <Col md="4" sm="6" className="mb-4">
          <div className="card p-0 shadow-sm">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                title="CMou8FSzVxo"
                loading="lazy"
                className="embed-responsive-item rounded"
                src="https://www.youtube.com/embed/CMou8FSzVxo?rel=0"
                allowFullScreen={true}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Videos;
