import React, { useContext, Fragment } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

import createMarkup from '../../helpers/createMarkup';

import PostHeader from './PostHeader';
import PostFooter from './PostFooter';
import Avatar from '../common/Avatar';
import TagPets from './Pets';

import { PostContext } from '../../context/Context';
import bg from '../../assets/img/generic/new_pet_bg.png';

const PostNewPet = () => {
  const {
    PostedAt,
    PostedBy,
    PostedByAvatar,
    PostedByName,
    ProfileUrl,
    ShareWith,
    Content,
    Comment,
    Favo,
    PostId,
    Pets
  } = useContext(PostContext);
  return (
    <Card className="p-0 mb-3 shadow-sm">
      {!!PostedBy && <PostHeader {...{ PostedBy, PostedByAvatar, PostedByName, PostedAt, ProfileUrl, ShareWith }} />}
      <CardBody className="overflow-hidden p-0">
        <div className="p-3">
          {!!Content && (
            <Fragment>
              <span className="my-0" dangerouslySetInnerHTML={createMarkup(Content)} />
              <TagPets />
            </Fragment>
          )}
        </div>
        <div className="new-pet hover-scale" style={{ backgroundImage: `url(${bg})` }}>
          <Link
            to={`/zoo/${Pets[0].ProfileUrl}`}
            style={{ textDecoration: 'none' }}
            className="text-center p-3 pt-7 pb-5 d-block"
          >
            <Avatar size="4xl" src={Pets[0].Avatar} className="avatar-border shadow mt-3 mb-2" />
            <h4 className="text-white font-weight-semi-bold">Welcome {Pets[0].Name}</h4>
          </Link>
        </div>
      </CardBody>
      <PostFooter PostId={PostId} {...{ Favo, Comment }} />
    </Card>
  );
};

export default PostNewPet;
