import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { Collapse, Container, Media, Nav, Navbar, NavbarToggler, NavItem } from 'reactstrap';

import classNames from 'classnames';
import { topNavbarBreakpoint } from '../../config';
import Logo from '../../widget/navbar/Logo';

import { ReactComponent as ChevronDown } from '../../assets/img/landing/chevron-down.svg';
import { ReactComponent as StoreIcon } from '../../assets/img/landing/store.svg';
import { ReactComponent as MarketingIcon } from '../../assets/img/landing/marketing.svg';
import { ReactComponent as AdvsIcon } from '../../assets/img/landing/advertisement.svg';
import { ReactComponent as BookIcon } from '../../assets/img/landing/book.svg';
import { ReactComponent as StorkIcon } from '../../assets/img/landing/stork.svg';

const NavbarLanding = () => {
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);
  const [showCooperate, setShowCooperate] = useState(false);
  const [showResource, setShowResource] = useState(false);
  const [showApps, setShowApps] = useState(false);
  const refCooperate = useRef(null);
  const refResource = useRef(null);
  const refApps = useRef(null);

  const handleClickOutside = event => {
    if (refCooperate.current && !refCooperate.current.contains(event.target)) {
      setShowCooperate(false);
    }
    if (refResource.current && !refResource.current.contains(event.target)) {
      setShowResource(false);
    }
    if (refApps.current && !refApps.current.contains(event.target)) {
      setShowApps(false);
    }
  };

  useEffect(() => {
    console.log('hehe giangdn');
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const toggleCooperate = () => {
    setShowCooperate(!showCooperate);
  };

  const toggleResource = () => {
    setShowResource(!showResource);
  };

  const toggleApps = () => {
    setShowApps(!showApps);
  };

  return (
    <Navbar
      dark
      fixed="top"
      id="navbar-landing"
      expand={topNavbarBreakpoint}
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      className={classNames('navbar-standard navbar-theme', {
        'bg-navbar-dark': !navbarCollapsed
      })}
    >
      <Container>
        <Logo
          Branch="Pety"
          Slogan={<span className="text-100">Ứng dụng của người yêu thú cưng</span>}
          at="navbar-top"
          width={60}
          id="topLogo"
        />
        <NavbarToggler onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
        <Collapse isOpen={!navbarCollapsed} navbar>
          <Nav
            navbar
            className={classNames('ml-auto font-weight-medium position-relative nav-perspective', {
              'mt-4 mb-4 mb-lg-0': !navbarCollapsed
            })}
          >
            <NavItem className="mx-lg-2 py-2 py-lg-0">
              <a className="nav-link text-100" href="#about-pety">
                Giới thiệu
              </a>
            </NavItem>
            <NavItem className="mx-lg-2 py-2 py-lg-0 position-relative">
              <span className="nav-link text-100 cursor-pointer" onClick={toggleCooperate}>
                Hợp tác
                <ChevronDown className="ml-1" style={{ width: '12px', height: '12px' }} />
              </span>
              <Cooperate isShow={showCooperate} ref={refCooperate} />
            </NavItem>
            <NavItem className="mx-lg-2 py-2 py-lg-0">
              <span className="nav-link text-100 cursor-pointer" onClick={toggleResource}>
                Tài nguyên
                <ChevronDown className="ml-1" style={{ width: '12px', height: '12px' }} />
              </span>
              <Resources isShow={showResource} ref={refResource} />
            </NavItem>
            <NavItem className="mx-lg-2 py-2 py-lg-0">
              <span className="nav-link text-100 cursor-pointer" onClick={toggleApps}>
                Thông tin
                <ChevronDown className="ml-1" style={{ width: '12px', height: '12px' }} />
              </span>
              <Apps isShow={showApps} ref={refApps} />
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

// eslint-disable-next-line react/display-name
const Cooperate = forwardRef((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { isShow } = props;
  return (
    <div
      ref={ref}
      className={`visibility-transition visibility-wide rounded bg-white p-lg-3 mt-lg-2 l-0 ${isShow ? '' : 'hidden'}`}
    >
      <a className="btn link p-3 d-block" href="./for-partner.html">
        <Media>
          <AdvsIcon className="mt-2 icon" style={{ width: '2.65rem', height: '2.65rem' }} />
          <Media body className="pl-4 text-left">
            <p className="font-weight-bold m-0 link-label">Quảng bá sản phẩm dịch vụ</p>
            <p className="link-desc m-0">Cho công ty, tổ chức và cá nhân kinh doanh lĩnh vực thú cưng</p>
          </Media>
        </Media>
      </a>
      <a className="btn link p-3 d-block" href="./expert-talk.html">
        <Media>
          <MarketingIcon className="mt-2 icon" style={{ width: '2.65rem', height: '2.65rem' }} />
          <Media body className="pl-4 text-left">
            <p className="font-weight-bold m-0 link-label">Góc chuyên gia</p>
            <p className="text-muted m-0 link-desc">Cho bác sĩ thú y, chuyên gia và kĩ thuật viên chăm sóc thú cưng</p>
          </Media>
        </Media>
      </a>
      {/* <a className="btn link p-3 d-block" href="./service-vendor.html">
        <Media>
          <StoreIcon className="mt-2 icon" />
          <Media body className="pl-4 text-left">
            <p className="font-weight-bold m-0 link-label">Đối tác cung cấp dịch vụ</p>
            <p className="text-muted m-0 link-desc">
              Cho Bệnh viện, Phòng khám, Spa và Kĩ thuật viên chăm sóc thú cưng
            </p>
          </Media>
        </Media>
      </a> */}
    </div>
  );
});

// eslint-disable-next-line react/display-name
const Resources = forwardRef((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { isShow } = props;
  return (
    <div ref={ref} className={`visibility-transition rounded bg-white p-lg-3 mt-lg-2 r-0 ${isShow ? '' : 'hidden'}`}>
      <a className="btn link p-3 d-block" href="./blog">
        <Media>
          <BookIcon className="mt-2 icon" style={{ width: '2.65rem', height: '2.65rem' }} />
          <Media body className="pl-4 text-left">
            <p className="font-weight-bold m-0 link-label">Kiến thức</p>
            <p className="text-muted m-0 link-desc">
              Hướng dẫn chăm sóc và nuôi dưỡng thú cưng, các bài viết về bệnh và cách điều trị hữu ích
            </p>
          </Media>
        </Media>
      </a>
      <a className="btn link p-3 d-block" href="./blog/breed/list.html">
        <Media>
          <StorkIcon className="mt-2 icon" style={{ width: '2.65rem', height: '2.65rem' }} />
          <Media body className="pl-4 text-left">
            <p className="font-weight-bold m-0 link-label">Các giống thú cưng</p>
            <p className="text-muted m-0 link-desc">
              Bộ sưu tập thông tin về các giống chó, mèo, chim, cá cảnh, bò sát đầy đủ nhất
            </p>
          </Media>
        </Media>
      </a>
    </div>
  );
});

// eslint-disable-next-line react/display-name
const Apps = forwardRef((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { isShow } = props;
  return (
    <div ref={ref} className={`visibility-transition bg-white rounded p-lg-3 mt-lg-2 r-0 ${isShow ? '' : 'hidden'}`}>
      <a className="btn link p-3 d-block text-left text-dark" href="./faq.html">
        Câu hỏi thường gặp
      </a>
      <a className="btn link p-3 d-block text-left text-dark" href="./privacy.html">
        Chính sách &amp; Quyền riêng tư
      </a>
      <a className="btn link p-3 d-block text-left text-dark" href="./term.html">
        Thoảng thuận sử dụng
      </a>
      <a className="btn link p-3 d-block text-left text-dark" href="./contact.html">
        Liên hệ
      </a>
    </div>
  );
});

export default NavbarLanding;
