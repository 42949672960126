import React from 'react';
import LazyLoad from 'react-lazyload';
import Avatar from '../../components/common/Avatar';
import award from '../../assets/img/landing/award2012.png';

const Blog = () => {
  return (
    <div id="caseStudies" className="container mt-6">
      <LazyLoad>
        <div className="card shadow-none bg-soft-primary text-inherit transition-3d-hover p-4 p-md-7">
          <div className="row">
            <div className="col-lg-4 order-lg-2 mb-5 mb-lg-0">
              <div className="d-flex flex-column h-100">
                <div className="mb-7">
                  <h2 className="h1 font-weight-semi-bold mb-3">
                    <a className="text-dark" href="blog/">
                      Blog kiến thức
                    </a>
                  </h2>
                  <p className="text-body h5">
                    Được Pety biên tập tỉ mỉ, hữu ích cho cả các bạn mới nuôi và những bạn đã nuôi thú cưng từ rất
                    lâu...
                  </p>
                </div>

                <div className="card shadow-none p-4 text-right">
                  <div className="mb-3">
                    <img style={{ width: '80px', marginTop: '-60px' }} src={award} alt="Award" />
                  </div>

                  <div className="mb-3 text-left">
                    <blockquote className="text-dark">
                      "Tôi nhận ra rằng mỗi khi tôi mất một chú chó, nó mang đi theo một mảnh tim tôi, và mỗi chú chó
                      mới đến với tôi lại tặng tôi một mảnh tim mình. Nếu tôi sống đủ lâu, tất cả tim tôi sẽ trở thành
                      chúng, và tôi sẽ trở nên hào phóng và đầy yêu thương như chúng." – Khuyết danh
                    </blockquote>
                  </div>
                  <hr />
                  <div className="media">
                    <div className="avatar avatar-circle mr-3">
                      <a href="blog/tac-gia/yeucho.html">
                        <Avatar
                          size="2xl"
                          src="https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av-9aedcf9c4a438d21bf8574b9ba734ba4.jpeg"
                        />
                      </a>
                    </div>
                    <div className="media-body text-left">
                      <a className="d-block text-dark font-weight-semi-bold mb-0" href="blog/tac-gia/yeucho.html">
                        Yêu Chó
                      </a>
                      <small className="d-block text-muted">Bác sĩ Thú y ở tp.HCM</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8 order-lg-1">
              <div className="mb-5">
                <img
                  alt="cat cover"
                  className="rounded w-100"
                  src="https://d3u2xd68tejot5.cloudfront.net/images/article/cover.5e5953fb5c608.jpg"
                />
              </div>

              <div className="row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                  <h3 className="h4">
                    <a
                      className="text-dark font-weight-bold"
                      href="blog/benh-viem-duong-ruot-o-cho-meo-ban-nen-biet.html"
                    >
                      Bệnh Viêm Đường Ruột Ở Chó Mèo Bạn Nên Biết!
                    </a>
                  </h3>
                  <p className="text-body">
                    <a
                      className="text-600 font-weight-medium text-decoration-none"
                      href="blog/benh-viem-duong-ruot-o-cho-meo-ban-nen-biet.html"
                    >
                      Nếu hôm nay bạn thấy người bạn thân của mình “uống nước thay cơm”, đi liêu xiêu hoặc nằm bẹp một
                      chỗ thì khả năng cao bé đang bị vấn đề tiêu hóa rồi đấy!
                    </a>
                  </p>
                </div>

                <div className="col-sm-6">
                  <h3 className="h4">
                    <a className="text-dark font-weight-bold" href="blog/cuu-tro/">
                      Trạm Cứu Hộ Chó Mèo
                    </a>
                  </h3>
                  <p className="text-body">
                    <a className="text-600 font-weight-medium text-decoration-none" href="blog/cuu-tro/">
                      Mỗi ngày có rất nhiều động vật, đặc biệt là chó mèo bị bỏ rơi, bạo hành. Và cũng có rất nhiều
                      những tấm lòng nhân ái từ các cá nhân, các nhóm, các trạm cứu trợ động vật đang hoạt động tích cực
                      để trợ giúp trên cả nước.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
    </div>
  );
};

export default Blog;
