import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Background from '../components/common/Background';
import Flex from '../components/common/Flex';
import Section from '../components/common/Section';

import halfCircle from '../assets/img/illustrations/half-circle.png';
import logo from '../assets/pety/pety-logo-200.jpg';

const AuthLayout = ({ leftSideContent, children }) => {
  useEffect(() => {
    document.body.classList.remove('bg-white');
  }, []);

  return (
    <Section fluid className="py-0">
      <Row noGutters className="min-vh-100 flex-center">
        <Col lg={8} className="col-xxl-6 col-xl-8 py-3">
          <Card className="z-index-1">
            <CardBody className="p-0">
              <Row noGutters className="h-100">
                <Col md={5} className="text-white text-center bg-pety-gradient">
                  <div className="position-relative p-3 pt-md-5 pb-md-7">
                    <Background image={halfCircle} className="bg-auth-card-shape" />
                    <div className="z-index-1 position-relative">
                      <Link className="mb-4 d-inline-block" to="/">
                        <img className="rounded-lg" src={logo} alt="Logo" width={100} />
                      </Link>
                      <p className="text-200 font-weight-semi-bold">Ứng dụng của người yêu thú cưng</p>
                    </div>
                  </div>
                  <div className="mt-3 mb-4 mt-md-4 mb-md-5">{leftSideContent}</div>
                </Col>
                <Col md={7} tag={Flex} align="center" justify="center">
                  <div className="p-4 p-md-5 flex-grow-1">{children}</div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

AuthLayout.propTypes = {
  leftSideContent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

export default AuthLayout;
