import React, { useContext, useState, useEffect } from 'react';

import { livelyOpacityColors } from '../../helpers/colors';
import AppContext from '../../context/Context';
import Chip from '../../components/common/Chip';
import { isIterableArray } from '../../helpers/utils';
import _ from '../../lib/Var';
import Flex from '../../components/common/Flex';

const OwnPets = () => {
  const { OwnPets: Pets, loadOwnPets, OwnPetsLoaded, User } = useContext(AppContext);
  const [IsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (IsLoading || OwnPetsLoaded) return;

    setIsLoading(true);
    if (_.getInt(User, 'UserId') > 0) {
      loadOwnPets(() => setIsLoading(false));
    }

    // eslint-disable-next-line
  }, [User]);

  return (
    isIterableArray(Pets) &&
    Pets.length > 0 && (
      <Flex className="mt-3">
        <span style={{ whiteSpace: 'nowrap' }} className="text-muted mr-2">
          cùng với
        </span>
        <Flex wrap>
          {Pets.map((Pet, Idx) => (
            <Chip
              to={`/zoo/${Pet.ProfileUrl}`}
              title={Pet.Name}
              color={livelyOpacityColors[Idx % livelyOpacityColors.length]}
              dark={true}
              key={`compose-pet-${Pet.PetId}`}
              avatar={Pet.Avatar}
              className="mr-1 mb-1"
            >
              {Pet.Name}
            </Chip>
          ))}
        </Flex>
      </Flex>
    )
  );
};

export default OwnPets;
