import { createContext } from 'react';

const AppContext = createContext({
  isFluid: false,
  isRTL: false,
  isDark: false,
  showBurgerMenu: false, // controls showing vertical nav on mobile
  isNavbarVerticalCollapsed: false,
  User: {},
  isGuest: true
});

// export const EmailContext = createContext({ emails: [] });

// export const ProductContext = createContext({ products: [] });

// export const AuthWizardContext = createContext({ User: {} });

// export const ChatContext = createContext();

export const AuthContext = createContext({});
export const FeedContext = createContext({});
export const HashtagContext = createContext({});
export const VideoContext = createContext({});
export const PostContext = createContext({});
export const CommentContext = createContext({});
export const ZooContext = createContext({});
export const PlaceContext = createContext({});
export const PetContext = createContext({});
export const ProfileContext = createContext({});
export const SearchContext = createContext({});

export default AppContext;
