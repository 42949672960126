import React, { useContext } from 'react';
import { Card } from 'reactstrap';
import PostHeader from './PostHeader';
import PostFooter from './PostFooter';
import PostContent from './PostContent';

import { PostContext } from '../../context/Context';

const PostNormal = () => {
  const {
    PostedAt,
    PostedBy,
    PostedByAvatar,
    PostedByName,
    ProfileUrl,
    ShareWith,
    Content,
    Photos,
    PostTypeId,
    Bg,
    Comment,
    Favo,
    PostId,
    Pets
  } = useContext(PostContext);

  return (
    <Card className="mb-3 shadow-sm">
      {!!PostedBy && <PostHeader {...{ PostedBy, PostedByAvatar, PostedByName, PostedAt, ProfileUrl, ShareWith }} />}
      {!!Content && <PostContent {...{ Content, Photos, Pets, PostTypeId, Bg }} />}
      <PostFooter PostId={PostId} {...{ Favo, Comment }} />
    </Card>
  );
};

export default PostNormal;
