import React from 'react';
import { Link } from 'react-router-dom';
import Flex from '../../components/common/Flex';

const NavbarLogin = () => (
  <div>
    <Flex align="right">
      <Link to="/auth/login" style={{ whiteSpace: 'nowrap' }} className="btn btn-primary mr-2">
        Đăng Nhập
      </Link>
      <Link to="/auth/signup" style={{ whiteSpace: 'nowrap' }} className="btn btn-primary">
        Đăng Ký
      </Link>
    </Flex>
  </div>
);

export default NavbarLogin;
