/**
@param input: string, output: a string(join by a array)
*/
const regex = /#[\wÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀẾỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềếểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+/g;
// const regex = /#\w+/g;
const getHashTags = (text, pos) => {
  let listHashtag = [];
  let value;
  while ((value = regex.exec(text))) {
    listHashtag.push(typeof pos !== 'undefined' && !!pos ? value[0].replace('#', '') : value[0]);
  }
  return listHashtag;
};

const getFirstHashTags = (text, pos) => {
  let listHashtag = getHashTags(text, pos);
  return listHashtag.length > 0 ? listHashtag[0] : '';
};

const getHashTagsToPushServer = (text: string) => {
  let listHashtag = [];
  let value;
  while ((value = regex.exec(text))) listHashtag.push(value);
  return listHashtag.join();
};

export default {
  getHashTags,
  getFirstHashTags,
  getHashTagsToPushServer
};
