import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Loader from '../../components/common/Loader';
import { isIterableArray } from '../../helpers/utils';
import PersonFollow from './PersonFollow';
import J from '../../lib/Jalio';
import _ from '../../lib/Var';
import { apis, Config } from '../../lib/Config';
import corner2 from '../../assets/img/illustrations/corner-2.png';
import Background from '../../components/common/Background';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PeopleToFollow = () => {
  const [People, setPeople] = useState([]);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    J.post(apis.feed.member, { limit: Config.shortListLimit }).then(res => {
      setPeople(_.get(res, 'module.Members', []));
      setLoading(false);
    });
  }, []);

  return (
    <Card className="mb-3 shadow-sm">
      <CardHeader className="position-relative" style={{ borderBottom: 'solid 1px rgba(0,0,0,.1)' }}>
        <Background image={corner2} className="p-card bg-card" style={{ zIndex: 0 }} />
        <h5 className="m-0 font-weight-semi-bold text-500">
          <FontAwesomeIcon icon={faUser} className="mr-3" size="lg" />
          Theo Dõi
        </h5>
      </CardHeader>
      <CardBody>
        {Loading ? (
          <Loader />
        ) : (
          isIterableArray(People) &&
          People.map((Person, Idx) => {
            const { UserId, Name, Avatar, RegistedAt, NumFollower, ProfileUrl } = Person;
            return Name !== null && Name.length > 2 ? (
              <PersonFollow
                {...{ UserId, Name, UserAvatar: Avatar, RegistedAt, NumFollower, ProfileUrl }}
                key={`person-${UserId}`}
                divider={People.length === Idx + 1}
              />
            ) : (
              ''
            );
          })
        )}
      </CardBody>
    </Card>
  );
};

export default PeopleToFollow;
