import React, { useState, useEffect } from 'react';
import lo from 'lodash';
import { createBrowserHistory } from 'history';

import AppContext from '../context/Context';
import toggleStylesheet from '../helpers/toggleStylesheet';
import { getItemFromStore, setItemToStore, themeColors, isIterableArray } from '../helpers/utils';
import U from '../lib/User';
import J from '../lib/Jalio';
import { apis, Config } from '../lib/Config';
import _ from '../lib/Var';
import m from '../lib/Moment';

const AppProvider = ({ children }) => {
  const [isFluid, setIsFluid] = useState(getItemFromStore('isFluid', false));
  const [isRTL, setIsRTL] = useState(getItemFromStore('isRTL', false));
  const [isDark, setIsDark] = useState(getItemFromStore('isDark', false));
  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(
    getItemFromStore('isNavbarVerticalCollapsed', false)
  );
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [User, setUser] = useState(U.live());
  const [OwnPets, setOwnPets] = useState([]);
  const [ComposeTagPet, setComposeTagPet] = useState([]);
  const [OwnPetsLoaded, setOwnPetsLoaded] = useState(false);
  const [isGuest, setIsGuest] = useState(User.isGuest());
  const [Notification, setNotification] = useState([]);
  const [UnreadNotificationQuantity, setUnreadNotificationQuantity] = useState(0);
  const [UnreadNotification, setUnreadNotification] = useState([]);
  const [NotiLmstart, setNotiLmstart] = useState(0);
  const [NotiLoadedAll, setNotiLoadedAll] = useState(false);

  /**
   * phương thức thực hiện follow/un-follow một người
   *
   * @param {int} TargetId
   * @param {function} callback
   */
  const doFollowPeople = (TargetId, callback) => {
    if (!isGuest && parseInt(User.UserId) > 0 && parseInt(TargetId) > 0) {
      J.post(apis.doFollow, { UserId: User.UserId, TargetId }).then(res => {
        typeof callback !== 'undefined' && callback(_.get(res, 'module.Result', false));
      });
    } else {
      typeof callback !== 'undefined' && callback();
    }
  };

  /**
   * phương thức thực hiện follow/un-follow một thú cưng
   *
   * @param {int} PetId
   * @param {function} callback
   */
  const doFollowPet = (PetId, callback) => {
    if (!isGuest && parseInt(PetId) > 0) {
      J.post(apis.pet.follow, { PetId }).then(res => {
        typeof callback !== 'undefined' && callback(_.get(res, 'module.Result', false));
      });
    } else {
      typeof callback !== 'undefined' && callback();
    }
  };

  const loadOwnPets = callback => {
    if (OwnPetsLoaded) return;

    J.post(apis.pet.info, { UserId: User.UserId })
      .then(res => {
        setOwnPets(_.get(res, 'module.Pets', []));
        typeof callback !== 'undefined' && callback(_.get(res, 'module.Pets', []));
      })
      .finally(() => {
        setOwnPetsLoaded(true);
      });
  };

  useEffect(() => {
    setComposeTagPet(OwnPets);
  }, [OwnPets]);

  const readNotification = NotificationId => {
    if (parseInt(NotificationId) > 0) {
      // update current notification read time
      setNotification(
        Notification.map(El => {
          if (El.NotificationId === NotificationId) El.ReadAt = Math.round(m().valueOf() / 1000);
          setUnreadNotificationQuantity(UnreadNotificationQuantity - 1);
          return El;
        })
      );

      // query the server
      J.post(apis.noti.read, { NotificationId }).then(res => {});
    }
  };

  const loadNotification = callback => {
    if (NotiLoadedAll) return;

    J.post(apis.noti.all, { lmstart: NotiLmstart }).then(res => {
      const NewLoadedNotis = _.get(res, 'module.Notifications', []);
      setNotification(lo.union(Notification, NewLoadedNotis));

      // loaded all checked
      if (!isIterableArray(NewLoadedNotis) || NewLoadedNotis.length < Config.listLimit) {
        setNotiLoadedAll(true);
      }

      // update lmstart
      if (isIterableArray(NewLoadedNotis)) setNotiLmstart(NotiLmstart + NewLoadedNotis.length);

      // fire the callback
      typeof callback !== 'undefined' && callback(NewLoadedNotis);
    });
  };

  const loadUnreadNotification = callback => {
    J.post(apis.noti.all, { lmstart: 0, Unread: 1 }).then(res => {
      setUnreadNotification(_.get(res, 'module.Notifications', []));

      // fire the callback
      typeof callback !== 'undefined' && callback(_.get(res, 'module.Notifications', []));
    });
  };

  const loadPetIntro = () => {};

  const loadPeopleIntro = () => {};

  // prepare values for app-context
  const value = {
    isFluid,
    setIsFluid,
    isRTL,
    setIsRTL,
    isDark,
    setIsDark,
    showBurgerMenu,
    setShowBurgerMenu,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed,
    isGuest,
    setIsGuest,
    User,
    setUser,
    doFollowPeople,
    doFollowPet,

    // pets of current user
    setComposeTagPet,
    ComposeTagPet,
    OwnPets,
    loadOwnPets,
    OwnPetsLoaded,

    // notification
    Notification,
    setNotification,
    NotiLoadedAll,
    readNotification,
    UnreadNotificationQuantity,
    UnreadNotification,
    setUnreadNotificationQuantity,
    loadUnreadNotification,
    loadNotification,
    loadPetIntro,
    loadPeopleIntro,

    // history
    history: createBrowserHistory()
  };

  const setStylesheetMode = mode => {
    setIsLoaded(false);
    setItemToStore(mode, value[mode]);
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));
  };

  useEffect(() => {
    setStylesheetMode('isFluid');
    // eslint-disable-next-line
  }, [isFluid]);

  useEffect(() => {
    setStylesheetMode('isRTL');
    // eslint-disable-next-line
  }, [isRTL]);

  useEffect(() => {
    setStylesheetMode('isDark');
    // eslint-disable-next-line
  }, [isDark]);

  useEffect(() => {
    setItemToStore('isNavbarVerticalCollapsed', isNavbarVerticalCollapsed);
    // eslint-disable-next-line
  }, [isNavbarVerticalCollapsed]);

  // xử lý hiển thị khi đang tải stylesheet mới lúc đổi dark/light mode
  if (!isLoaded) {
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: isDark ? themeColors.dark : themeColors.light
        }}
      />
    );
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
