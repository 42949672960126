/**
 * khai báo cấu trúc của ứng dụng server-side
 * giúp cho việc xử lý đồng bộ dữ liệu giữa client-side
 * và server-side được hiệu quả và dễ dàng hơn
 */
import _ from './Var';
import n from './Network';
import m from './Moment';
import U from './User';
import { Config } from './Config';
import c from 'cheerio';
import { array, object } from 'prop-types';

const Jalio = {
  modules: {
    // các module
    wall: {
      views: {
        // các view trong module
        wall: {
          layouts: {
            // các layout trong view
            dft: ['Post'], // layout tải về dữ liệu thì trả về các data-entry (table) nào
            favorite: ['PostFavorite'],
            comment: ['PostComment'],
            post: '',
            result: ''
          }
        }
      },
      actions: {
        // các action
        favo: ['PostFavorite'], // action đó ảnh hưởng tới data-entry (table) nào
        favoComment: ['PostComment'],
        delComment: ['PostComment']
      }
    }
  },

  data: {
    // các data-entry sử dụng trong ứng dụng cùng với các field định danh
    Post: ['PostId'], // các field định danh dùng làm khóa để xóa tự động khi trigger sự kiện
    PostComment: ['PostCommentId'],
    PostFavorite: ['PostId', 'UserId']
  },

  get: function(uri, params) {
    return this._get(uri, params);
  },

  post: function(uri, params, configs) {
    return this._post(uri, params, configs);
  },

  ogp: async (uri, params, method) => {
    // check linkify
    if ((uri = Jalio.linkify(uri))) {
      const _m = typeof method !== 'undefined' ? method : 'GET';
      if (_m === 'POST') {
        const token = await Jalio._token();
        if (typeof params !== 'undefined') {
          params[token] = 1;
        } else {
          params = {};
          params[token] = 1;
        }
      }

      const html = await n._axios(_m, uri, params);
      if (typeof html == 'string' && html.length > 0) {
        const $ = c.load(html);
        const Title = $('meta[property="og:title"]').attr('content') || $('head title').text();
        const Desc =
          $('meta[property="og:description"]').attr('content') || $('meta[name="description"]').attr('content');
        const Img =
          $('meta[property="og:image:url"]').attr('content') || $('meta[property="og:image"]').attr('content');
        const Kwd = $('meta[name="keywords"]').attr('content');
        return { Title, Desc, Img, Kwd };
      }
    }
    return false;
  },

  linkify: function(text) {
    if (text !== null) {
      const matches = text.match(/(https?:\/\/[^\s]+)/g);
      if (matches !== null && typeof matches[0] !== 'undefined') return matches[0];
    }
    return false;
  },

  _get: async (uri, params) => {
    const data = await n._axios('GET', Config.url + uri, params);

    return data;
  },

  _post: async (uri, params, configs) => {
    // init params object
    if (typeof params == 'undefined') params = {};

    const token = await Jalio._token();
    if (token) {
      params[token] = '1';
    }

    const vars = Jalio.parseRoute(uri);
    if (Object.keys(vars).length > 0) {
      params = Object.assign(params, vars);
    }

    const data = await n._axios('POST', Config.url, params, configs);

    return data;
  },

  _token: async () => {
    let token = localStorage.getItem('token');
    const tokenExpiredAt = parseInt(localStorage.getItem('tokenExpiredAt'));

    if (token === null || token.length === 0 || (!isNaN(tokenExpiredAt) && tokenExpiredAt < m().unix())) {
      // query the server for new token and user
      const json = await Jalio._get('user');
      token = _.get(json, 'module.token', false);

      if (token !== false && token !== null && token !== '' && token.length > 0) {
        U.save({
          ..._.get(json, 'module.user'),
          token: _.get(json, 'module.token'),
          tokenExpiredAt: parseInt(m().unix()) + _.getInt(json, 'module.tokenExpiredAfter')
        });
      }
    }

    return Promise.resolve(token);
  },

  buildRoute: function(vars) {
    let route = '';
    if (typeof vars === 'string') {
      // wall:favo --> wall?_act=favo
      route = vars.replace(':', '?_act=');
    } else if (typeof vars === array || typeof vars === object) {
      if (typeof vars['_mod'] !== 'undefined') {
        route += vars['_mod'];
        delete vars['_mod'];
      }

      if (typeof vars['_view'] !== 'undefined') {
        route += '/' + vars['_view'];
        delete vars['_view'];
      }
      if (typeof vars['_lay'] !== 'undefined') {
        route += '/' + vars['_lay'];
        delete vars['_lay'];
      }
      if (typeof vars['_act'] !== 'undefined') {
        route += '?_act=' + vars['_act'];
        delete vars['_act'];
      }

      //other maintaning variables
      let params = [];
      Object.keys(vars).forEach(key => {
        params.push(key + '=' + vars[key]);
      });

      if (params.length) {
        params = params.join('&');

        if (route.length) {
          if (route.indexOf('?') > -1) route += '&' + params;
          else route += '?' + params;
        }
      }
    }

    return route;
  },

  /**
   * route sẽ có dạng sau wall/wall/favorite ~ ?_mod=wall&_view=wall&_lay=favorite
   * hoặc dạng wall:favo ~ ?_mod=wall&_act=favo
   * hoặc dạng wall:admin.del ~ ?_mod=wall&_act=del (include controller = admin)
   */
  parseRoute: function(route) {
    // remove full url part
    route = route.replace(Config.url, '');

    const vars = {};
    // parse action
    route = route.split(':');
    if (route[1] !== undefined) {
      vars['_act'] = route[1];
    }

    // parse module, view, layout
    if (route[0] !== undefined || route[0].length > 0) {
      route = route[0].split('/');
      if (route[0] !== undefined) {
        vars['_mod'] = route[0];
      }

      if (route[1] !== undefined) {
        vars['_view'] = route[1];
      }

      if (route[2] !== undefined) {
        vars['_lay'] = route[2];
      }

      // binding default source
      if (vars['_act'] === undefined && vars['_mod'] !== undefined) {
        if (vars['_view'] === undefined) vars['_view'] = vars['_mod'];
        if (vars['_lay'] === undefined) vars['_lay'] = 'dft';
      }
    }

    return vars;
  },

  getDataMap: function(route) {
    const vars = Jalio.parseRoute(route);
    if (vars._mod !== undefined) {
      let dataObjectsAct = [];
      let dataObjectsLay = [];

      if (vars._act !== undefined) {
        const dataPath = 'modules.' + vars._mod + '.actions.' + vars._act;
        dataObjectsAct = _.get(Jalio, dataPath, []);
      }

      if (vars._lay !== undefined) {
        const dataPath = 'modules.' + vars._mod + '.views.' + vars._view + '.layouts.' + vars._lay;
        dataObjectsLay = _.get(Jalio, dataPath, []);
      }

      //merge and get only unique items
      const dataObjects = Array.from(new Set([...dataObjectsAct, ...dataObjectsLay]));

      const data = {};
      if (dataObjects.length > 0) {
        dataObjects.forEach(o => {
          data[o] = _.get(Jalio, 'data.' + o);
        });
      }
      return data;
    }
  }
};

export default Jalio;
