import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Row, Col, FormGroup, Input, CustomInput, Label, Spinner } from 'reactstrap';

import _ from '../../lib/Var';
import Message from '../../lib/Message';
import Divider from '../../components/common/Divider';
import SocialAuthButtons from './SocialAuthButtons';
import withRedirect from '../../hoc/withRedirect';
import AppContext from '../../context/Context';

const LoginForm = ({ setRedirect, hasLabel }) => {
  // State
  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const [Remember, setRemember] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoginin, setLogingIn] = useState(false);
  const { setIsGuest, User, setUser } = useContext(AppContext);

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    setLogingIn(true);
    await User.login(Email, Password).then(res => {
      if (_.get(res, 'messages', false) !== false) {
        Message.show(_.get(res, 'messages'));
      }
      setUser(User);
      setLogingIn(false);
    });
    setIsGuest(User.isGuest());
    setRedirect(!User.isGuest());
  };

  useEffect(() => {
    setIsDisabled(!Email || !Password);

    // eslint-disable-next-line
  }, [Email, Password]);

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Số Phone / Email</Label>}
        <Input
          placeholder={!hasLabel ? 'Số Phone / Email' : ''}
          value={Email}
          onChange={({ target }) => setEmail(target.value)}
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Mật khẩu</Label>}
        <Input
          placeholder={!hasLabel ? 'Mật khẩu' : ''}
          value={Password}
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <CustomInput
            id="customCheckRemember"
            label="Giữ trạng thái đăng nhập"
            checked={Remember}
            onChange={({ target }) => setRemember(target.checked)}
            type="checkbox"
          />
        </Col>
        {/* <Col xs="auto">
          <Link className="fs--1" to={`/auth/forget-password`}>
            Bạn quên mật khẩu?
          </Link>
        </Col> */}
      </Row>
      <FormGroup>
        <Button color="primary" block className="mt-3" disabled={isDisabled}>
          Đăng Nhập
          {isLoginin && <Spinner size="sm" as="span" variant="primary" className="float-right" />}
        </Button>
      </FormGroup>
      <Divider className="mt-4">hoặc đăng nhập bằng</Divider>
      <SocialAuthButtons setRedirect={setRedirect} />
      <p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold color-gray text-500 text-center">
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="text-underline text-600"
          href="https://pety.vn/term.html"
        >
          Thỏa thuận
        </a>{' '}
        và{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="text-underline text-600"
          href="https://pety.vn/privacy.html"
        >
          Chính sách Sử dụng
        </a>
      </p>
    </Form>
  );
};

LoginForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default withRedirect(LoginForm);
