import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import LoginForm from './LoginForm';

import AuthLayout from '../../layout/AuthLayout';

const Login = () => {
  return (
    <AuthLayout
      leftSideContent={
        <Fragment>
          <p className="text-300">Bạn chưa có tài khoản?</p>
          <Link className="btn btn-info" to={`/auth/signup${window.location.hash}`}>
            Đăng Ký Mới
          </Link>
        </Fragment>
      }
    >
      <h3>Đăng Nhập</h3>
      <LoginForm layout="card" hasLabel />
    </AuthLayout>
  );
};

export default Login;
