import React, { useContext, useState, useEffect } from 'react';
import PostAction from './PostAction';
import { CardFooter, Form, Input, ButtonGroup, Button, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Picker } from 'emoji-mart';
import Resizer from 'react-image-file-resizer';

import Flex from '../common/Flex';
import Avatar from '../common/Avatar';
import Comments from './Comments';
import AppContext, { PostContext } from '../../context/Context';
import { isIterableArray } from '../../helpers/utils';
import CountFavo from './CountFavo';
import PreviewPhoto from './PreviewPhoto';
import PreviewSticker from './PreviewSticker';
import CountComment from './CountComment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMeh } from '@fortawesome/free-regular-svg-icons';
import { faPaw, faCamera } from '@fortawesome/free-solid-svg-icons';
import Stickers, { parseForServerside } from './Stickers';
import { Config } from '../../lib/Config';

const PostFooter = ({ PostId, Comment }) => {
  const {
    Haha,
    Love,
    Like,
    Wow,
    Sad,
    Angry,
    Followed,
    LastComment,
    NewComments,
    PostedByName,
    PostedBy,
    postComment,
    Comments: ArrComments
  } = useContext(PostContext);

  const [ReplyTo, setReplyTo] = useState(0);
  const [RefInput, setRefInput] = useState(null);
  const [RefUpload, setRefUpload] = useState(null);
  const [CommentPhoto, setCommentPhoto] = useState(null);
  const [CommentSticker, setCommentSticker] = useState(null);
  const [ReplyToName, setReplyToName] = useState('');
  const [TextComment, setTextComment] = useState('');
  const { User, isDark } = useContext(AppContext);
  const [Rows, setRows] = useState(1);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [isStickerOpen, setIsStickerOpen] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);
  const { isGuest } = useContext(AppContext);

  useEffect(() => {
    setIsFollowed(parseInt(Followed) === 1 || parseInt(User.UserId) === parseInt(PostedBy));

    // eslint-disable-next-line
  }, [Followed, User, PostedBy]);

  const submitComment = () => {
    // prepare to submit comment to server-side
    const PostData = {
      PostId,
      Comment: TextComment,
      ReplyTo,
      ReplyToName,
      Sticker: CommentSticker !== null ? JSON.stringify(parseForServerside(CommentSticker)) : '',
      UploadPhoto:
        CommentPhoto !== null
          ? JSON.stringify({
              uri: CommentPhoto.Base64,
              name: CommentPhoto.Name,
              type: CommentPhoto.Type
            })
          : ''
    };

    // send data to server-side
    postComment(PostData);

    // clean input and preveiw
    setTextComment('');
    setCommentSticker(null);
    setCommentPhoto(null);
  };

  const onChoosePhoto = File => {
    // remove sticker if exist
    setCommentSticker(null);

    // first, attach the origional image
    const reader = new FileReader();
    reader.readAsDataURL(File);
    reader.onload = () => {
      setCommentPhoto({
        Name: File.name,
        Type: File.type,
        Base64: reader.result,
        File
      });
    };

    setIsResizing(true);
    // resize the choosen image before upload
    Resizer.imageFileResizer(
      File,
      Config.photoUploadMaxSize,
      Config.photoUploadMaxSize,
      'JPEG',
      100,
      0,
      Result => {
        setCommentPhoto({
          Name: File.name,
          Type: File.type,
          Base64: Result,
          File
        });
        setIsResizing(false);
      },
      'base64'
    );
  };

  useEffect(() => {
    if (isFollowed) {
      let Length = ReplyToName.length;
      if (ReplyToName.length > 0) {
        const NewTextComment = TextComment === '' ? `@${ReplyToName} ` : `${TextComment} @${ReplyToName} `;
        setTextComment(NewTextComment);
        Length = NewTextComment.length;
      }

      if (RefInput !== null) {
        RefInput.focus();

        RefInput.selectionStart = Length - 1;
        RefInput.selectionEnd = Length;
      }
    }
    // eslint-disable-next-line
  }, [ReplyTo, ReplyToName]);

  const handleInputKeyDown = e => {
    if (e.keyCode === 13 && !e.shiftKey) {
      submitComment();
      e.preventDefault();
    } else {
      const Lines = e.target.scrollHeight / 16;
      setRows(Lines);
    }
  };

  return (
    <CardFooter className="bg-light pt-0">
      {/* số lượng thích và bình luận */}

      {parseInt(Like) +
        parseInt(Love) +
        parseInt(Wow) +
        parseInt(Haha) +
        parseInt(Sad) +
        parseInt(Angry) +
        parseInt(Comment) >
        0 && (
        <div className="border-bottom border-200 fs--1 py-3 d-flex flex-wrap">
          <CountFavo />
          <CountComment />
        </div>
      )}

      {/* các nút thao tác thích, bình luận, chia sẻ */}
      <div className="border-bottom border-200">
        <PostAction PostId={PostId} />
      </div>

      {/* danh sách các comment */}

      {((LastComment !== null && typeof LastComment !== 'undefined') || isIterableArray(NewComments)) && (
        <Comments
          setReplyTo={setReplyTo}
          setReplyToName={setReplyToName}
          nowShowing={
            isIterableArray(ArrComments)
              ? ArrComments.length
              : 0 + isIterableArray(LastComment)
              ? LastComment.length
              : LastComment !== null && typeof LastComment !== 'undefined'
              ? 1
              : 0
          }
          totalComment={Comment}
          moreComment={
            !isNaN(Comment) && parseInt(Comment) > 1
              ? `${
                  isIterableArray(ArrComments)
                    ? ArrComments.length
                    : 0 + isIterableArray(LastComment)
                    ? LastComment.length
                    : LastComment !== null && typeof LastComment !== 'undefined'
                    ? 1
                    : 0
                }/${Comment}`
              : ''
          }
        />
      )}

      {/* ô textbox để bình luận */}
      {!isGuest && (
        <Flex tag={Form} align="center" className="pt-3">
          <Avatar src={User.Avatar} size="xl" />
          <div className="fs--1 w-100">
            <div className="position-relative" align="center">
              <Input
                innerRef={Ref => setRefInput(Ref)}
                className="ml-2 pr-7 fs--1 rounded-soft"
                type="textarea"
                style={{ resize: 'none', lineHeight: '16px', overflow: 'hidden' }}
                rows={Rows}
                onKeyDown={e => handleInputKeyDown(e)}
                placeholder={isFollowed ? 'Viết bình luận...' : `Theo dõi ${PostedByName} để bình luận`}
                disabled={!isFollowed}
                value={TextComment}
                onChange={({ target }) => setTextComment(target.value)}
              />
              <ButtonGroup className="position-absolute" style={{ right: 0, bottom: '0.1rem' }}>
                <Button
                  color="default"
                  disabled={!isFollowed}
                  onClick={() => RefUpload !== null && RefUpload.click()}
                  className="text-500 p-0 mx-1 btn-default hover-scale"
                >
                  <FontAwesomeIcon icon={faCamera} size="1x" />
                  <input
                    ref={Ref => setRefUpload(Ref)}
                    multiple={false}
                    onChange={({ target }) => onChoosePhoto(target.files[0])}
                    type="file"
                    accept=".gif,.jpg,.jpeg,.png"
                    style={{ display: 'none' }}
                  />
                </Button>
                <Dropdown isOpen={isStickerOpen} toggle={() => setIsStickerOpen(!isStickerOpen)}>
                  <DropdownToggle
                    disabled={!isFollowed}
                    color="default"
                    className="nav-link p-0 mx-1 text-500 hover-scale"
                  >
                    <FontAwesomeIcon icon={faPaw} size="1x" />
                  </DropdownToggle>
                  <DropdownMenu right className="m-0 p-0 r2rem" style={{ zIndex: 2001 }}>
                    {isStickerOpen && (
                      <Stickers
                        onSelect={(Pack, Item) => {
                          setIsStickerOpen(false);
                          setCommentPhoto(null);
                          setCommentSticker({ Pack, ...Item });
                        }}
                      />
                    )}
                  </DropdownMenu>
                </Dropdown>
                <Dropdown isOpen={isEmojiOpen} toggle={() => setIsEmojiOpen(!isEmojiOpen)}>
                  <DropdownToggle
                    disabled={!isFollowed}
                    color="default"
                    className="nav-link p-0 mx-1 text-500 hover-scale"
                  >
                    <FontAwesomeIcon icon={faMeh} size="1x" />
                  </DropdownToggle>
                  <DropdownMenu right className="m-0 p-0 r2rem" style={{ zIndex: 2000 }}>
                    {isEmojiOpen && (
                      <Picker
                        emoji=""
                        style={{ border: 'none', padding: 0 }}
                        showPreview={false}
                        showSkinTones={false}
                        title=""
                        set="facebook"
                        theme={isDark ? 'dark' : 'light'}
                        onSelect={emoji => {
                          setTextComment(TextComment + emoji.native);
                        }}
                      />
                    )}
                  </DropdownMenu>
                </Dropdown>
              </ButtonGroup>
            </div>
          </div>
        </Flex>
      )}
      <Flex tag="div">
        <div style={{ width: '2rem' }} />
        {CommentSticker !== null && (
          <div className="m-2">
            <PreviewSticker Item={CommentSticker} onClose={() => setCommentSticker(null)} />
          </div>
        )}
        {(CommentPhoto !== null || !!isResizing) && (
          <div className="m-2">
            <PreviewPhoto isResizing={isResizing} Photo={CommentPhoto} onClose={() => setCommentPhoto(null)} />
          </div>
        )}
      </Flex>
    </CardFooter>
  );
};

export default PostFooter;
