import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';
import Avatar from '../common/Avatar';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import PostDropDown from './PostDropDown';
import FalconCardHeader from '../common/FalconCardHeader';
import classNames from 'classnames';
import m from '../../lib/Moment';

const PostHeader = ({ PostedByName, PostedByAvatar, PostedAt, ProfileUrl, ShareWith }) => {
  return (
    <FalconCardHeader
      className="py-2"
      title={
        <Fragment>
          <Avatar src={PostedByAvatar} size="2xl" className="rounded-circle border" />
          <Media body className="align-self-center ml-2">
            <p className="mb-1 line-height-1">
              <Link className="font-weight-semi-bold" to={`/bud/${ProfileUrl}`}>
                {PostedByName}
              </Link>
              {/* {!!share && (
                <span className="ml-1">
                  shared {/^[aeiou]/g.test(share.toLowerCase()) ? 'an' : 'a'} <a href="#!">{share}</a>
                </span>
              )} */}
            </p>
            <p className="mb-0 fs--1">
              {m.unix(PostedAt).fromNow()} •{' '}
              <FontAwesomeIcon
                icon={classNames({
                  // users: privacy === 'friends',
                  lock: ShareWith === '1',
                  'globe-americas': ShareWith === '0'
                })}
              />
            </p>
          </Media>
        </Fragment>
      }
      titleTag={Media}
    >
      {/**<PostDropDown /> */}
    </FalconCardHeader>
  );
};

PostHeader.propsType = {
  PostedByName: PropTypes.string.isRequired,
  PostedByAvatar: PropTypes.string.isRequired,
  PostedAt: PropTypes.string.isRequired,
  ShareWith: PropTypes.string.isRequired
};

export default PostHeader;
