import React, { useState, useEffect } from 'react';
import lo from 'lodash';
import { CommentContext } from '../context/Context';
import { Config, apis } from '../lib/Config';
import J from '../lib/Jalio';
import _ from '../lib/Var';

const CommentProvider = ({ children, CommentRecord }) => {
  // user from app context
  const [Favo, setFavo] = useState(parseInt(CommentRecord.Favo));
  const [Favod, setFavod] = useState(CommentRecord.Favod === null ? 0 : parseInt(CommentRecord.Favod));
  const [FavoPeople, setFavoPeople] = useState([]);
  const [FavoPeopleLmstart, setFavoPeopleLmstart] = useState(0);
  const [FavoPeopleLoadedAll, setFavoPeopleLoadedAll] = useState(false);

  // function for loading & loading more people did favorite a post
  const loadFavoPeople = callback => {
    const { PostCommentId } = CommentRecord;
    if (parseInt(PostCommentId) > 0 && !FavoPeopleLoadedAll) {
      J.post(apis.commentFavorite, {
        PostCommentId,
        lmstart: FavoPeopleLmstart,
        limit: Config.listLimit
      }).then(res => {
        // append the result to state
        const People = lo.union(FavoPeople, _.get(res, 'module.Favorites', []));

        // update the provider state
        setFavoPeople(People);

        if (_.get(res, 'module.Favorites', []).length < Config.listLimit) {
          setFavoPeopleLoadedAll(true);
        } else {
          setFavoPeopleLmstart(People.length);
        }

        // exec the callback
        typeof callback !== 'undefined' && callback(People);
      });
    }
  };

  const toggleFavo = callback => {
    if (parseInt(Favod) === 1) {
    }

    setFavo(parseInt(Favod) === 1 ? Favo - 1 : Favo + 1);
    setFavod((parseInt(Favod) + 1) % 2);

    const { PostCommentId } = CommentRecord;
    // send the request api
    J.post(apis.favoComment, { PostCommentId });

    // do the callback
    if (typeof callback !== 'undefined') callback({ Favo, Favod });
  };

  useEffect(() => {
    // reset favo loader
    setFavoPeople([]);
    setFavoPeopleLmstart(0);
    setFavoPeopleLoadedAll(false);
  }, [Favo, Favod]);

  // un-changed Post properties
  const {
    PostCommentId,
    CommentedBy,
    Avatar,
    Avatar1,
    Commentor,
    Comment,
    CommentedAt,
    Sticker,
    Photo,
    PhotoUrl,
    ReplyTo,
    ReplyToName,
    ProfileUrl
  } = CommentRecord;

  const Values = {
    // favo state
    FavoPeople,
    FavoPeopleLmstart,
    FavoPeopleLoadedAll,
    loadFavoPeople,
    toggleFavo,
    Favo,
    Favod,

    // post properties
    PostCommentId,
    CommentedBy,
    Avatar,
    Avatar1,
    Commentor,
    Comment,
    CommentedAt,
    ReplyTo,
    ReplyToName,
    Sticker,
    Photo,
    PhotoUrl,
    ProfileUrl
  };

  return <CommentContext.Provider value={Values}>{children}</CommentContext.Provider>;
};

export default CommentProvider;
