import Formatter from '../lib/Formatter';
import J from '../lib/Jalio';
import { isIterableArray } from './utils';

export default html => {
  let result = { __html: '' };
  if (html !== null && typeof html === 'string') {
    // break line
    html = html.replace(/\n/g, '<br />');

    // share link
    const Url = J.linkify(html.trim());
    if (!!Url) {
      const Regex = new RegExp(Url, 'g');
      html = html.replace(Regex, `<a href="${Url}" target="_blank" rel="noopener noreferrer">${Url}</a>`);
    }

    // hashtag
    const Hashtags = Formatter.getHashTags(html);
    if (isIterableArray(Hashtags)) {
      Hashtags.map(El => {
        const Regex = new RegExp(El, 'g');
        return (html = html.replace(Regex, `<a class="text-link" href="/hashtag/${El.replace('#', '')}">${El}</a>`));
      });
    }

    result = {
      __html: html
    };
  }

  return result;
};
