import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from '../../assets/pety/pety-logo-200.jpg';

const Logo = ({ Branch, Slogan, at, width, className, ...rest }) => {
  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth'
          },
          className
        )}
      >
        <img className="mr-2 bg-white rounded shadow-sm rounded-soft pety-logo" src={logo} alt="Logo" width={width} />
        <div className="ml-1 d-block">
          <h3 className="m-0 font-weight-semi-bold text-primary" style={{ lineHeight: 1 }}>
            {Branch}
          </h3>
          <span className={`d-none d-lg-block m-0 font-weight-normal`} style={{ fontSize: '1rem' }}>
            {Slogan}
          </span>
        </div>
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  Branch: PropTypes.string,
  Slogan: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
