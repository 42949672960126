import { useState } from 'react';

const useStorage = (Key, InitValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [StoredValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by Key
      const Item = window.localStorage.getItem(Key);
      // Parse stored json or if none return InitValue
      return Item ? JSON.parse(Item) : InitValue;
    } catch (Error) {
      // If error also return InitValue
      console.warn(Error, Key);
      return InitValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = Value => {
    try {
      // Allow value to be a function so we have same API as useState
      const ValueToStore = Value instanceof Function ? Value(StoredValue) : Value;
      // Save state
      setStoredValue(ValueToStore);
      // Save to local storage
      window.localStorage.setItem(Key, JSON.stringify(ValueToStore));
    } catch (Error) {
      // A more advanced implementation would handle the error case
      console.log(Error);
    }
  };

  return [StoredValue, setValue];
};

export default useStorage;
