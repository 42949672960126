const troll = {
  Name: 'Troll',
  Key: 'troll',
  Icon: require('./troll-face-22.png'),
  Tag: '',
  Items: [
    { Key: 'are-you-idiot-78.png', File: require('./are-you-idiot-78.png') },
    { Key: 'are-you-kidding-me-160.png', File: require('./are-you-kidding-me-160.png') },
    { Key: 'cereal-guy-161.png', File: require('./cereal-guy-161.png') },
    { Key: 'derpina-156.png', File: require('./derpina-156.png') },
    { Key: 'excited-80.png', File: require('./excited-80.png') },
    { Key: 'forever-alone-69.png', File: require('./forever-alone-69.png') },
    { Key: 'fuuuu-23.png', File: require('./fuuuu-23.png') },
    { Key: 'grinning-73.png', File: require('./grinning-73.png') },
    { Key: 'if-you-know-what-i-mean-127.png', File: require('./if-you-know-what-i-mean-127.png') },
    { Key: 'loooool-77.png', File: require('./loooool-77.png') },
    { Key: 'me-gusta-153.png', File: require('./me-gusta-153.png') },
    { Key: 'mexican-troll-face-72.png', File: require('./mexican-troll-face-72.png') },
    { Key: 'monsieur-74.png', File: require('./monsieur-74.png') },
    { Key: 'no-68.png', File: require('./no-68.png') },
    { Key: 'not-bad-154.png', File: require('./not-bad-154.png') },
    { Key: 'poker-face-24.png', File: require('./poker-face-24.png') },
    { Key: 'sad-face-76.png', File: require('./sad-face-76.png') },
    { Key: 'the-rage-face-82.png', File: require('./the-rage-face-82.png') },
    { Key: 'thumbs-up-157.png', File: require('./thumbs-up-157.png') },
    { Key: 'troll-dad-83.png', File: require('./troll-dad-83.png') },
    { Key: 'troll-dance-75.png', File: require('./troll-dance-75.png') },
    { Key: 'troll-face-22.png', File: require('./troll-face-22.png') },
    { Key: 'troll-face-81.png', File: require('./troll-face-81.png') },
    { Key: 'troll-face-kitty-129.png', File: require('./troll-face-kitty-129.png') },
    { Key: 'whyyyyy-70.png', File: require('./whyyyyy-70.png') },
    { Key: 'wtf-155.png', File: require('./wtf-155.png') },
    { Key: 'you-are-gay-79.png', File: require('./you-are-gay-79.png') },
    { Key: 'you-dont-say-126.png', File: require('./you-dont-say-126.png') }
  ]
};

export default troll;
