import React, { useContext, Fragment } from 'react';

import { livelyOpacityColors } from '../../helpers/colors';
import { PostContext } from '../../context/Context';
import Chip from '../common/Chip';

const Pets = () => {
  const { Pets, PostId } = useContext(PostContext);
  return (
    Array.isArray(Pets) &&
    Pets.length > 0 && (
      <Fragment>
        <span className="text-muted"> cùng với </span>
        {Pets.map((Pet, Idx) => (
          <Chip
            to={`/zoo/${Pet.ProfileUrl}`}
            title={Pet.Name}
            color={livelyOpacityColors[Idx % livelyOpacityColors.length]}
            dark={true}
            key={`post-${PostId}-pet-${Pet.PetId}`}
            avatar={Pet.Avatar}
            className="mr-2 mb-1"
          >
            {Pet.Name}
          </Chip>
        ))}
      </Fragment>
    )
  );
};

export default Pets;
