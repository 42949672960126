import React, { useContext } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faPaw } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';

import Avatar from '../common/Avatar';
import createMarkup from '../../helpers/createMarkup';
import m from '../../lib/Moment';
import _ from '../../lib/Var';
import AppContext from '../../context/Context';

const BgColors = [
  '',
  '#48c774', // success
  '#48c774', // success
  '#f14668', // pink
  '#ffdd57', // pri
  '#3298dc', // link
  '#f14668', // pink
  '#3298dc', // link
  '#3298dc' // link
];
const Icons = [
  '',
  faUserCheck, // <Scon color={colors.white} name="user-following" size={sizes.fontMd} />,
  faPaw, //<Fcon color={colors.white} name="paw" size={sizes.fontMd} />,
  'heart', //<Acon color={colors.white} name="heart" size={sizes.fontMd} />,
  'share', //<Acon color={colors.white} name="sharealt" size={sizes.fontMd} />,
  'comments', //<Acon color={colors.white} name="message1" size={sizes.fontMd} />,
  'heart', //<Acon color={colors.white} name="heart" size={sizes.fontMd} />,
  'comments', //<Fcon color={colors.white} name="comments" size={sizes.fontMd} />,
  'comments' //<Acon color={colors.white} name="message1" size={sizes.fontMd} />
];

const Notification = ({
  Label,
  Message,
  NotificationId,
  NotificationTypeId,
  Params,
  Quantity,
  ReadAt,
  ReceivedAt,
  RefId,
  Route,
  Section,
  State,
  TriggeredAt,
  TriggeredBy,
  TriggeredByAvatar,
  AvatarFile,
  TriggeredByName,
  ProfileUrl,
  Type,
  UserId,
  className,
  StripLength,
  children,
  history
}) => {
  // function to build the link of notification
  const getLink = (Route, Params) => {
    Params = JSON.parse(Params);
    switch (Route) {
      case 'page.Post':
        return '/post/' + _.getInt(Params, 'PostId');
      case 'page.Pet':
        return '/zoo/' + _.getInt(Params, 'PetId');
      default:
        return '/bud/' + _.getInt(Params, 'UserId');
    }
  };

  const { readNotification } = useContext(AppContext);

  const goToTargetPage = (Route, NotificationId, ReadAt) => {
    history.push(Route);
    (ReadAt === null || parseInt(ReadAt) === 0) && readNotification(NotificationId);
  };

  let StripMessage = (Message === null ? '' : Message).replace('\n', ' ');
  let MaxLength = typeof StripLength !== 'undefined' ? parseInt(StripLength) : 64;
  if (StripMessage.length > MaxLength) StripMessage = StripMessage.substring(0, MaxLength) + '...';

  const NotificationText = `<span><span class="font-weight-semi-bold text-primary">${TriggeredByName}</span> ${Label.replace(
    Type,
    `<span class="font-weight-semi-bold text-700"> ${StripMessage} </span> `
  )}
  </span>`;

  return (
    <Link
      className={classNames('notification', { 'bg-200': ReadAt === null || parseInt(ReadAt) === 0 }, className)}
      onClick={() => goToTargetPage(getLink(Route, Params), NotificationId, ReadAt)}
    >
      <div className="notification-avatar position-relative">
        <Link to={`/bud/${ProfileUrl}`}>
          <Avatar src={TriggeredByAvatar} className="mr-3" />
        </Link>
        <div
          className="rounded-capsule position-absolute bordered"
          style={{
            top: '22px',
            left: '16px',
            width: '1.4rem',
            height: '1.4rem',
            textAlign: 'center',
            lineHeight: '1.4rem',
            backgroundColor: BgColors[parseInt(NotificationTypeId)]
          }}
        >
          <FontAwesomeIcon size="1x" icon={Icons[parseInt(NotificationTypeId)]} color="white" />
        </div>
      </div>

      <div className="notification-body">
        <p className="mb-0" dangerouslySetInnerHTML={createMarkup(NotificationText)} />
        <span className="notification-time">{m.unix(TriggeredAt).fromNow()}</span>
      </div>
    </Link>
  );
};

export default withRouter(Notification);
