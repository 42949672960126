import { toast } from 'react-toastify';

const Message = {
  show: Mes => {
    if (Array.isArray(Mes)) {
      Mes.map(Item => typeof Item == 'object' && Message._toast(Item));
    } else if (typeof Mes == 'object') {
      Message._toast(Mes);
    }
  },

  _toast: Mes => {
    switch (Mes.type) {
      case 0:
        toast.error(Mes.contentRaw);
        break;
      case 1:
        toast.warn(Mes.contentRaw);
        break;
      case 3:
        toast.success(Mes.contentRaw);
        break;
      default:
        toast.info(Mes.contentRaw);
    }
  }
};

export default Message;
