import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import J from '../../lib/Jalio';
import Loader from '../common/Loader';
import { apis, Config } from '../../lib/Config';
import Base64 from '../../lib/Base64';

const LinkPreview = ({ Url }) => {
  const [IsLoading, setIsLoading] = useState(true);
  const [Loaded, setLoaded] = useState(false);
  const [Ogp, setOgp] = useState(false);

  useEffect(() => {
    if (Loaded) return;
    const Params = { link: Base64.encode(Url) };

    J.ogp(Config.url + apis.bridge.ogp, Params, 'POST').then(Ogp => {
      setIsLoading(false);
      setLoaded(true);
      typeof Ogp === 'object' && setOgp(Ogp);
    });

    // eslint-disable-next-line
  }, [Url]);

  return (
    <Fragment>
      {IsLoading && <Loader />}
      {!IsLoading && (
        <a href={Url} title={Ogp.Title} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          {!!Ogp.Img && <img className="img-fluid rounded" src={Ogp.Img} alt={Ogp.Title} />}
          {/* {!!Ogp.Kwd && <small className="text-500">{Ogp.Kwd}</small>} */}
          {!!Ogp.Title && <h5 className="mt-3">{Ogp.Title}</h5>}
          {!!Ogp.Desc && <p className="fs--1 mb-0 text-700">{Ogp.Desc}</p>}
        </a>
      )}
    </Fragment>
  );
};

LinkPreview.propTypes = {
  Url: PropTypes.string.isRequired
};
export default LinkPreview;
