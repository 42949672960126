import React, { useContext, useEffect, Fragment, useRef } from 'react';
import { Form, Input, Button, Media } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isBrowser } from 'react-device-detect';
import lo from 'lodash';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { SearchContext } from '../../context/Context';
import { isIterableArray } from '../../helpers/utils';
import Loader from '../../components/common/Loader';
import AvatarComponent from '../../components/common/Avatar';
import { Config } from '../../lib/Config';

/**
 * display search form for browse, but search icon for mobile
 */
const SearchBox = () => {
  return isBrowser && <SearchForm />;
};

const SearchForm = () => {
  const {
    setIsShowPanel,
    isShowPanel,
    Searching,
    Searched,
    Keyword,
    setKeyword,
    setType,
    setSearching,
    Recents,
    setRecents,
    Buds,
    Pets
  } = useContext(SearchContext);

  const isShowPageSearch = useLocation().pathname.includes('/search/');

  useEffect(() => {
    !isShowPageSearch && setType('SearchBox') && setSearching(false);

    // eslint-disable-next-line
  }, [isShowPageSearch]);

  // ref của search-wrapper
  const searchWrapperRef = useRef(null);

  // ref của form
  const refForm = useRef(null);

  // save the recent search
  const handleSubmit = e => {
    setRecents([...lo.union([Keyword], Recents).slice(0, 10)]);
    return true;
  };

  /**
   * bắt sự kiện click ra ngoài của search-wrapper
   * để đóng wrapper lại
   */
  useEffect(() => {
    document.addEventListener('mousedown', e => {
      searchWrapperRef.current && !searchWrapperRef.current.contains(e.target) && setIsShowPanel(false);
    });

    // eslint-disable-next-line
  }, [searchWrapperRef]);

  return (
    <div
      style={{ overflow: 'hidden' }}
      ref={searchWrapperRef}
      className={classNames(`mt-1 rounded`, { 'bg-white shadow-sm': isShowPanel })}
    >
      <Form
        inline
        className="search-box"
        onSubmit={handleSubmit}
        ref={refForm}
        action={`/search/${Keyword}`}
        style={{ boxShadow: '0 1px 3px 0 rgba(0,0,0,.04), 0 4px 16px 0 rgba(44,50,65,.08)' }}
      >
        <Input
          onFocus={() => setIsShowPanel(true)}
          type="search"
          placeholder="Tìm kiếm trên Pety"
          aria-label="Search"
          className="search-input border-0 w-100 d-block"
          style={{ boxShadow: 'none', height: 40 }}
          defaultValue={Keyword}
          onChange={e => setKeyword(e.target.value.trim())}
        />
        <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
      </Form>
      <div className={classNames('p-2 pt-3 pb-0', { 'd-none': !isShowPanel || isShowPageSearch })}>
        {Searching && <Loader size="sm" />}
        {(typeof Keyword === 'undefined' || Keyword.length) === 0 &&
          !Searching &&
          !isIterableArray(Pets) &&
          !isIterableArray(Buds) && <SearchRecents />}
        {Searched && !Searching && <SearchResults />}
      </div>
    </div>
  );
};

const SearchResults = () => {
  const { Buds, Pets, Keyword } = useContext(SearchContext);
  return (
    <Fragment>
      {!isIterableArray(Buds) && !isIterableArray(Pets) && Keyword.length > 0 && (
        <div className="text-muted font-weight-normal text-center">
          Không có người dùng hoặc thú cưng có tên chứa <b>{Keyword}</b>
        </div>
      )}
      {(isIterableArray(Buds) || isIterableArray(Pets)) && (
        <Fragment>
          {isIterableArray(Buds) && (
            <Fragment>
              <div style={{ fontSize: '1.1rem' }} className="font-weight-semi-bold text-muted">
                Thành viên
              </div>
              {Buds.slice(0, Config.shortListLimit).map((Bud, Idx) => {
                return (
                  <div key={`searching-bud-${Idx}`} className="p-2 hover-bg mb-2 rounded">
                    <Link style={{ textDecoration: 'none' }} to={`/bud/${Bud.ProfileUrl}`}>
                      <Media>
                        <AvatarComponent className="hover-scale" size="2xl" src={Bud.Avatar} />
                        <Media body className="ml-2">
                          <div style={{ maxWidth: '248px' }} className="text-primary font-weight-semi-bold">
                            {Bud.Name}
                          </div>
                          <p
                            title={Bud.ProfileStatus}
                            className="text-500 m-0 font-weight-normal"
                            style={{
                              fontSize: '0.85rem',
                              width: '248px',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {Bud.ProfileStatus}
                          </p>
                        </Media>
                      </Media>
                    </Link>
                  </div>
                );
              })}
            </Fragment>
          )}
          {isIterableArray(Pets) && (
            <Fragment>
              <div style={{ fontSize: '1.1rem' }} className="font-weight-semi-bold text-muted">
                Thú cưng
              </div>
              {Pets.slice(0, Config.shortListLimit).map((Pet, Idx) => {
                return (
                  <div key={`searching-pet-${Idx}`} className="p-2 hover-bg mb-2 rounded">
                    <Link style={{ textDecoration: 'none' }} to={`/zoo/${Pet.ProfileUrl}`}>
                      <Media>
                        <AvatarComponent className="hover-scale" size="2xl" src={Pet.Avatar} />
                        <Media body className="ml-2">
                          <div style={{ maxWidth: '248px' }} className="text-primary font-weight-semi-bold">
                            {Pet.Name}
                          </div>
                          <p
                            title={Pet.Description}
                            className="text-500 m-0 font-weight-normal"
                            style={{
                              fontSize: '0.85rem',
                              width: '248px',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {Pet.Description}
                          </p>
                        </Media>
                      </Media>
                    </Link>
                  </div>
                );
              })}
            </Fragment>
          )}
          <div className="text-center border-top-1">
            <Link className="btn btn-info btn-sm btn-block" to={`/search/${Keyword}`}>
              Trang Tìm kiếm Chi tiết
            </Link>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

const SearchRecents = () => {
  const { Recents, setRecents, setKeyword } = useContext(SearchContext);

  const removeItem = Item => {
    setRecents([...lo.filter(Recents, El => El !== Item)]);
  };

  return isIterableArray(Recents) ? (
    <Fragment>
      <div style={{ fontSize: '1.1rem' }} className="font-weight-semi-bold">
        Tìm kiếm gần đây
      </div>
      {isIterableArray(Recents) &&
        Recents.map((Item, Idx) => {
          return (
            <div className="d-block p-2 mb-1 hover-bg rounded" key={`search-recent-${Idx}`}>
              <Button
                onClick={() => removeItem(Item)}
                className="float-right btn-default rounded-capsule p-0 px-1"
                size="sm"
                color="white"
              >
                <FontAwesomeIcon icon="times" color="gray" size="sm" />
              </Button>
              <a href="#!" onClick={() => setKeyword(Item)} className="m-0 py-2 font-weight-semi-bold">
                {Item}
              </a>
            </div>
          );
        })}
    </Fragment>
  ) : (
    <Fragment>
      <p className="text-muted text-center m-0">Không có tìm kiếm gần đây</p>
    </Fragment>
  );
};

export default SearchBox;
