import React, { Fragment, useState, useContext, useEffect } from 'react';
import AppContext, { PostContext } from '../../context/Context';
import { isMobile } from 'react-device-detect';
import { FacebookCounter } from 'react-reactions';

import PopoverFavoPeople from './PopoverFavoPeople';
import ModalFavoPeople from './ModalFavoPeople';

const CountFavo = () => {
  const { Like, Love, Haha, Wow, Angry, Sad, Favo, Reacted, PostId, LastFavorite } = useContext(PostContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Counters, setCounters] = useState([]);
  const [Important, setImportant] = useState([]);

  const { User, isDark } = useContext(AppContext);

  useEffect(() => {
    const ArrReacted = { Like, Love, Haha, Wow, Angry, Sad };
    const Temp = [];
    Object.keys(ArrReacted).forEach(Key => {
      if (parseInt(ArrReacted[Key]) > 0) {
        Temp.push({
          emoji: Key.toLowerCase(),
          by:
            typeof LastFavorite !== 'undefined' && LastFavorite['Type'] === Key && LastFavorite.Name !== User.Name
              ? LastFavorite.Name
              : ''
        });
      }
    });
    setCounters(Temp);

    if (typeof LastFavorite !== 'undefined' && LastFavorite['Name'] && LastFavorite['Name'] !== User.Name) {
      setImportant([LastFavorite['Name']]);
    }
  }, [Like, Love, Haha, Wow, Angry, Sad, LastFavorite, User, Reacted]);

  return (
    <Fragment>
      {Counters.length > 0 && (
        <a id={`post-favo-${PostId}`} href="#!" className="text-700">
          <FacebookCounter
            important={Important}
            counters={Counters}
            Favo={Favo}
            Reacted={Reacted}
            bg={isDark ? '#09111b' : '#fff'}
            styleName={{ lineHeight: '16px', paddingRight: '4px' }}
            onClick={() => setIsModalOpen(true)}
          />
        </a>
      )}
      {/* <CountText onClick={() => setIsModalOpen(true)} PostId={PostId} Count={Favo} LastFavorite={LastFavorite} /> */}
      {Counters.length > 0 && (
        <Fragment>
          <ModalFavoPeople isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
          {!isMobile && <PopoverFavoPeople Target={`post-favo-${PostId}`} />}
        </Fragment>
      )}
    </Fragment>
  );
};

export default CountFavo;
