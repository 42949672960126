import React, { useState, useContext } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { PostContext } from '../../context/Context';
import { Config } from '../../lib/Config';
import Loader from '../common/Loader';
import Member from './MemberSimple';
import _ from '../../lib/Var';

const PopoverFavoPeople = ({ Target }) => {
  const { PostId, Favo, FavoPeople, loadFavoPeople } = useContext(PostContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [People, setPeople] = useState(FavoPeople);

  const toggle = () => {
    !isOpen && People.length === 0 && loadPeople();
    setIsOpen(!isOpen);
  };

  const loadPeople = () => {
    setIsLoading(true);
    loadFavoPeople(People => {
      setPeople(People.slice(0, Config.shortListLimit));
      setIsLoading(false);
    });
  };

  return (
    <Popover
      delay={{ show: 400, hide: 100 }}
      isOpen={isOpen}
      innerClassName="shadow"
      placement="bottom"
      trigger="hover"
      target={Target}
      toggle={toggle}
    >
      <PopoverBody className="pt-3 px-3">
        {!isLoading &&
          People.length > 0 &&
          People.map((Person, Idx) => (
            <Member {...{ ...Person, At: Person.FavoAt }} key={`comment-post-${PostId}-people-${Idx}`} />
          ))}
        {!isLoading && Favo > People.length && (
          <div className="ml-n2 mr-n2">
            <hr className="mt-0 mb-1" />
            <div className="text-500 d-block text-center">{`và ${_.round(Favo - People.length)} người khác`}</div>
          </div>
        )}
        {isLoading && <Loader className="mx-6" />}
      </PopoverBody>
    </Popover>
  );
};

export default PopoverFavoPeople;
