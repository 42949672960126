import React, { useState, useContext, useEffect, Fragment } from 'react';
import {
  Card,
  CardBody,
  Media,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Dropdown,
  Fade
} from 'reactstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faTh, faPhotoVideo } from '@fortawesome/free-solid-svg-icons';
import { Picker } from 'emoji-mart';
import { UncontrolledTooltip } from 'reactstrap';
import { TransitionGroup } from 'react-transition-group';
import FbImageLibrary from 'react-fb-image-grid';
import LazyLoad from 'react-lazyload';
import { toast } from 'react-toastify';

import Avatar from '../../components/common/Avatar';
import Flex from '../../components/common/Flex';
import AppContext, { FeedContext } from '../../context/Context';
import Background from '../../components/common/Background';
import corner1 from '../../assets/img/illustrations/corner-1.png';
import _ from '../../lib/Var';
import OwnPets from './OwnPets';
import bgs from '../../assets/pety/bg/bg';
import { isIterableArray } from '../../helpers/utils';
import { livelyOpacityColors } from '../../helpers/colors';
import Chip from '../../components/common/Chip';
import Jalio from '../../lib/Jalio';
import { apis } from '../../lib/Config';
import Formatter from '../../lib/Formatter';
import LinkPreview from '../../components/post/LinkPreview';

const CreatePostForm = () => {
  const { User, setComposeTagPet, OwnPets: Pets } = useContext(AppContext);
  const [IsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setComposeTagPet(Pets);

    // eslint-disable-next-line
  }, [IsOpen]);

  return (
    <Fragment>
      <Card className="mb-3 pt-2 shadow-sm">
        <CardBody className="p-3">
          <Media>
            <Link to={`/bud/${_.get(User, 'ProfileUrl')}`}>
              <Avatar src={User.Avatar} size="2xl" />
            </Link>
            <Media className="position-relative d-block mb-2 ml-2" body>
              {/* input để bấm mở soạn bài viết */}
              <Input
                className="resize-none rounded py-3"
                placeholder={`${_.get(User, 'Name')}, bạn muốn chia sẻ?`}
                type="text"
                style={{ lineHeight: '2.5rem', height: '3.5rem' }}
                onClick={e => {
                  setIsOpen(true);
                  e.target.blur();
                }}
              />

              {/* các icon liên quan */}
              <div className="text-400 d-none d-sm-block position-absolute r-0 t-0 b-0 p-2">
                <FontAwesomeIcon className="mx-1" icon={faTh} style={{ fontSize: '1.2rem' }} />
                <FontAwesomeIcon className="mx-1" icon={faPhotoVideo} style={{ fontSize: '1.2rem' }} />
                <FontAwesomeIcon className="mx-1" icon={faSmile} style={{ fontSize: '1.2rem' }} />
              </div>
            </Media>
          </Media>
          <hr />
          <OwnPets />
        </CardBody>
      </Card>
      <ModalCompose {...{ IsOpen, setIsOpen }} />
    </Fragment>
  );
};

const ModalCompose = ({ IsOpen, setIsOpen }) => {
  // context
  const { Posts, setPosts } = useContext(FeedContext);
  const { User, isDark, ComposeTagPet } = useContext(AppContext);

  const [Status, setStatus] = useState('');
  const [isOpenPicker, setIsOpenPicker] = useState(false);
  const [RefUpload, setRefUpload] = useState(null);
  const [Rows, setRows] = useState(4);
  const [Bg, setBg] = useState(null);
  const [BgNums, setBgNums] = useState(5);
  const [TagPets, setTagPets] = useState([]);
  const [Photos, setPhotos] = useState([]);
  const [LbPhotos, setLbPhotos] = useState([]);
  const [RowHeight, setRowHeight] = useState(36);
  const [TagPeople] = useState([]);
  const [ShareWith, setShareWith] = useState(0);
  const [PhotoToPost, setPhotoToPost] = useState([]);
  const [ShareLink, setShareLink] = useState(false);

  const handleInputKeyDown = target => {
    const Lines = target.scrollHeight / RowHeight;
    Lines > 2 && Lines <= 8 && setRows(Math.round(Lines));
  };

  // POST TO SERVER
  async function _post() {
    let params = {
      TagPeople: JSON.stringify(TagPeople),
      Hastags: Formatter.getHashTagsToPushServer(Status),
      ShareWith,
      Content: Status,
      TagPets: JSON.stringify(ComposeTagPet)
    };

    if (Bg !== null && !ShareLink) params = { ...params, Bg: Bg.name };

    if (PhotoToPost.length > 0 && !ShareLink) {
      params['NumPhoto'] = Photos.length;
      params = { ...params, NumPhoto: PhotoToPost.length, Photos: JSON.stringify(PhotoToPost) };
    }

    // begin post
    Jalio.post(apis.postStatus, params, Photos.length > 0 ? { 'content-type': 'multipart/form-data' } : null).then(
      res => {
        // thông báo trạng thái
        if (_.get(res, 'module.Result') === true) {
          _loadLastPostOnWall();

          setIsOpen(false);
        } else {
          toast.error('Vui lòng thử lại');
        }
      }
    );
  }

  function _loadLastPostOnWall() {
    const { UserId } = User;

    Jalio.post(apis.wall, { limit: 1, lmstart: 0, UserId }).then(res => {
      let newPosts = [];
      if ((newPosts = _.get(res, 'module.Posts', false))) {
        // insert new post for Post
        setPosts(newPosts.concat(Posts.filter(post => post.PostId !== newPosts[0].PostId)));
        toast.success(`Đã cập nhật thành công`);
      }
    });
  }

  const onChoosePhoto = Photos => {
    const Files = [];
    const Locals = [];

    if (typeof Photos !== 'undefined' && Photos.length > 0) {
      for (let i = 0; i < Photos.length; i++) {
        Files.push(Photos[i]);
        const LocalUrl = URL.createObjectURL(Photos[i]);
        Locals.push({ source: LocalUrl, url: LocalUrl });
      }
    }

    // photo to post
    const stringFiles = [];
    Files.map(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        stringFiles.push({
          uri: reader.result,
          size: file.size,
          name: file.name,
          type: file.type
        });
        setPhotoToPost([...stringFiles]);
      };
      return true;
    });

    // upload files and lightbox photos
    setLbPhotos(Locals);
    setPhotos(Files);

    setRowHeight(16);
    if (Status.length === 0) setRows(1);

    // empty the choosen bg
    setBg(null);
  };

  useEffect(() => {
    if (!isIterableArray(Photos)) {
      if (Rows < 4) setRows(4);
    }

    setBgNums(5);

    // eslint-disable-next-line
  }, [Photos]);

  return (
    <Fragment>
      <Modal
        modalClassName="no-slide compose"
        backdropClassName="compose"
        isOpen={IsOpen}
        fade={true}
        scrollable={false}
        toggle={() => setIsOpen(!IsOpen)}
        backdropTransition={{ timeout: 0 }}
        modalTransition={{ timeout: 0 }}
      >
        <ModalHeader toggle={() => setIsOpen(!IsOpen)} className="position-relative">
          <Background image={corner1} className="p-card bg-card" style={{ zIndex: 0 }} />
          <h4 className="m-0 font-weight-semi-bold text-500">Tạo Bài Đăng</h4>
        </ModalHeader>
        <ModalBody>
          <Media className="mb-2">
            <Link to={`/bud/${_.get(User, 'ProfileUrl')}`}>
              <Avatar src={_.get(User, 'Avatar')} size="3xl" />
            </Link>
            <Media body className="ml-2">
              <Link to={`/bud/${_.get(User, 'ProfileUrl')}`} className="mb-0 fs-0 text-primary font-weight-semi-bold">
                {_.get(User, 'Name')}
              </Link>
              <UncontrolledDropdown className="d-block mr-1">
                <DropdownToggle color="Secondary" size="sm" className="p-0 px-1 bg-light">
                  <FontAwesomeIcon
                    icon={classNames({
                      users: ShareWith === 1,
                      'globe-americas': ShareWith === 0
                    })}
                  />
                  <span className="mx-2">{ShareWith === 0 ? 'mọi người' : 'người theo dõi'}</span>
                  <FontAwesomeIcon icon="caret-down" className="d-inline-block ml-1" />
                </DropdownToggle>
                <DropdownMenu left style={{ width: '26rem' }} className="p-2">
                  <DropdownItem onClick={() => setShareWith(0)} className="p-2 rounded mb-2">
                    <Media>
                      <Media body>
                        <p className="font-weight-semi-bold mb-1">
                          <FontAwesomeIcon icon="globe-americas" className="mr-2" />
                          Chia sẻ với mọi người
                        </p>
                        <p className="text-muted text-wrap m-0" style={{ fontSize: '80%' }}>
                          Mọi người truy cập Pety thấy bài đăng này. Trừ những người đã bị bạn chặn.
                        </p>
                      </Media>
                    </Media>
                  </DropdownItem>
                  <DropdownItem onClick={() => setShareWith(1)} className="p-2 rounded mb-2">
                    <Media>
                      <Media body>
                        <p className="font-weight-semi-bold mb-1">
                          <FontAwesomeIcon icon="users" className="mr-2" />
                          Chỉ những người theo dõi
                        </p>
                        <p className="text-muted text-wrap m-0" style={{ fontSize: '80%' }}>
                          Người đã đăng nhập Pety và theo dõi bạn mới thấy bài đăng này.
                        </p>
                      </Media>
                    </Media>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Media>
          </Media>
          <div className="position-relative">
            <div
              className="mx-n3 mb-3 px-3 position-relative"
              style={{
                ...{
                  backgroundPosition: 'center bottom',
                  backgroundSize: 'cover'
                },
                ...(Bg !== null
                  ? {
                      backgroundImage: `url(${Bg.file})`,
                      textAlign: 'center',
                      paddingTop: '6rem',
                      paddingBottom: '2rem',
                      color: 'white',
                      fontWeight: 'bold'
                    }
                  : {})
              }}
            >
              <Input
                type="textarea"
                rows={Rows}
                value={Status}
                spellCheck="false"
                placeholder="Nhập nội dung chia sẻ"
                className={`border-0 my-3 p-0 resize-none ${!isIterableArray(Photos) && Rows <= 8 && 'placeholder-lg'}`}
                style={{
                  boxShadow: 'none',
                  backgroundColor: 'transparent',
                  textAlign: 'inherit',
                  color: 'inherit',
                  fontWeight: 'inherit'
                }}
                onChange={({ target }) => {
                  let link = Jalio.linkify(target.value.trim());
                  if (link !== false) {
                    setShareLink(link);
                    setBg(null);
                  } else setShareLink(false);

                  setStatus(target.value);
                }}
                onKeyDown={e => handleInputKeyDown(e.target)}
                onFocus={() => setBgNums(Bg !== null ? 4 : 5)}
              />

              {/* hiển thị những hình ảnh đã được chọn trong grid facebook */}
              {isIterableArray(Photos) && !ShareLink && (
                <ImageThumbnailGrid {...{ Photos: LbPhotos, setPhotos, setLbPhotos, RefUpload }} />
              )}

              {/* Show link priver */}
              {ShareLink && <LinkPreview Url={ShareLink} />}

              <Flex className="position-absolute" style={{ right: '1rem', bottom: 0 }}>
                {!isIterableArray(Photos) && (
                  <div
                    onClick={() => {
                      setBgNums(Bg !== null ? bgs.length - 1 : bgs.length);
                    }}
                    className="nav-link p-0 mr-3 text-400 cursor-pointer hover-scale"
                  >
                    <FontAwesomeIcon
                      color={Bg !== null ? 'white' : ''}
                      id="icon-choose-bg"
                      icon={faTh}
                      style={{ fontSize: '1.4rem' }}
                    />
                    <UncontrolledTooltip placement="top" target="icon-choose-bg">
                      Hiển thị toàn bộ hình nền
                    </UncontrolledTooltip>
                  </div>
                )}
                <div onClick={() => {}} className="nav-link p-0 mr-3 text-400 cursor-pointer hover-scale">
                  <FontAwesomeIcon
                    color={Bg !== null ? 'white' : ''}
                    id="icon-choose-image"
                    icon={faPhotoVideo}
                    style={{ fontSize: '1.3rem' }}
                    onClick={() => RefUpload !== null && RefUpload.click()}
                  />
                  <UncontrolledTooltip placement="top" target="icon-choose-image">
                    Chọn hình ảnh để tải lên
                  </UncontrolledTooltip>
                  <input
                    ref={Ref => setRefUpload(Ref)}
                    multiple={true}
                    onChange={({ target }) => onChoosePhoto(target.files)}
                    type="file"
                    accept=".gif,.jpg,.jpeg,.png"
                    className="d-none"
                    style={{ display: 'none' }}
                  />
                </div>
                <Dropdown isOpen={isOpenPicker} toggle={() => setIsOpenPicker(!isOpenPicker)}>
                  <DropdownToggle color="default" className="nav-link p-0 text-400 hover-scale">
                    <FontAwesomeIcon
                      id="icon-emoji"
                      color={Bg !== null ? 'white' : ''}
                      icon={faSmile}
                      style={{ fontSize: '1.4rem' }}
                    />
                    <UncontrolledTooltip placement="top" target="icon-emoji">
                      Chọn biểu tượng cảm xúc
                    </UncontrolledTooltip>
                  </DropdownToggle>
                  <DropdownMenu right className="m-0 p-0" style={{ zIndex: 2000, right: 0 }}>
                    {isOpenPicker && (
                      <Picker
                        emoji=""
                        style={{ border: 'none', padding: 0 }}
                        showPreview={false}
                        showSkinTones={false}
                        title=""
                        set="facebook"
                        theme={isDark ? 'dark' : 'light'}
                        onSelect={emoji => {
                          setStatus(Status + emoji.native);
                        }}
                      />
                    )}
                  </DropdownMenu>
                </Dropdown>
              </Flex>
            </div>

            {/* hiển thị các hình nền cho phép chọn để tạo bài viết dùng bg */}
            {!isIterableArray(Photos) && !ShareLink && (
              <Flex>
                <Bgs
                  {...{ Bg, BgNums, setBgNums }}
                  chooseBg={Bg => {
                    setBg(Bg);
                    setBgNums(4);
                  }}
                />
              </Flex>
            )}
          </div>
          <TagPetsComponent TagPets={TagPets} setTagPets={setTagPets} />
          <button disabled={Status.length === 0} className="btn-block btn bg-primary mt-3" onClick={_post}>
            Đăng Lên
          </button>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const Bgs = ({ chooseBg, setBgNums, BgNums, Bg }) => {
  return (
    <TransitionGroup>
      <div className="d-block text-center">
        {Bg !== null && (
          <div
            key={`compose-bg--1`}
            style={{
              width: '5.3rem',
              height: '3.5rem'
            }}
            className="d-inline-block mx-1"
          >
            <Fade>
              <a
                href="#!"
                style={{
                  width: '5.3rem',
                  height: '3.5rem',
                  overflow: 'hidden',
                  backgroundColor: 'white'
                }}
                className="rounded shadow-sm hover-scale d-block cursor-pointer position-relative"
                onClick={() => {
                  chooseBg(null);
                  setBgNums(5);
                }}
              >
                <div
                  style={{
                    top: 0,
                    left: '0.5rem',
                    right: '0.5rem',
                    bottom: 0,
                    lineHeight: '3.2rem',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    fontSize: '0.8rem'
                  }}
                  className="position-absolute text-center font-weight-semi-bold text-white"
                />
              </a>
            </Fade>
          </div>
        )}
        {bgs.slice(0, BgNums).map((El, Idx) => {
          return (
            <div
              key={`compose-bg-${Idx}`}
              style={{
                width: '5.3rem',
                height: '3.5rem'
              }}
              className="d-inline-block mx-1"
            >
              <Fade>
                <a
                  href="#!"
                  style={{
                    width: '5.3rem',
                    height: '3.5rem',
                    overflow: 'hidden',
                    backgroundSize: 'cover',
                    backgroundImage: `url(${El.file})`,
                    backgroundPosition: 'center'
                  }}
                  className="rounded shadow-sm hover-scale d-block cursor-pointer position-relative"
                  onClick={() => chooseBg(El)}
                >
                  <div
                    style={{
                      top: 0,
                      left: '0.5rem',
                      right: '0.5rem',
                      bottom: 0,
                      lineHeight: '3.2rem',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      fontSize: '0.8rem'
                    }}
                    className="position-absolute text-center font-weight-semi-bold text-white"
                  >
                    {El.tag}
                  </div>
                </a>
              </Fade>
            </div>
          );
        })}
      </div>
    </TransitionGroup>
  );
};

const ImageThumbnailGrid = ({ Photos, setLbPhotos, setPhotos, RefUpload }) => {
  return (
    <div className="position-relative pb-5">
      <span
        onClick={() => {
          setLbPhotos([]);
          setPhotos([]);
          RefUpload.value = null;
        }}
        className="nav-item-circle cursor-pointer position-absolute"
        style={{ zIndex: 905, top: '1rem', right: '1rem' }}
      >
        <FontAwesomeIcon icon="times" />
      </span>
      <LazyLoad>
        <FbImageLibrary images={Photos} hideOverlay={true} />
      </LazyLoad>
    </div>
  );
};

const TagPetsComponent = props => {
  const { setComposeTagPet, ComposeTagPet: TagPets } = useContext(AppContext);

  const removeTagPet = Pet => {
    const Pets = TagPets.filter(El => El.PetId !== Pet.PetId);
    setComposeTagPet(Pets);
  };

  return (
    isIterableArray(TagPets) &&
    TagPets.length > 0 && (
      <Flex className="mt-3">
        <span style={{ whiteSpace: 'nowrap' }} className="text-muted mr-2">
          cùng với
        </span>
        <Flex wrap>
          {TagPets.map((Pet, Idx) => (
            <Chip
              // to={`/zoo/${Pet.ProfileUrl}`}
              title={Pet.Name}
              color={livelyOpacityColors[Idx % livelyOpacityColors.length]}
              dark={true}
              key={`compose-pet-${Pet.PetId}`}
              avatar={Pet.Avatar}
              className="mr-1 mb-1"
              onClose={() => removeTagPet(Pet)}
            >
              {Pet.Name}
            </Chip>
          ))}
        </Flex>
      </Flex>
    )
  );
};

export default CreatePostForm;
