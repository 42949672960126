import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Media } from 'reactstrap';
import LazyLoad from 'react-lazyload';

import qrCode from '../../assets/pety/qr-code-download-app.jpg';
import { ReactComponent as AppleIcon } from '../../assets/img/landing/apple.svg';
import { ReactComponent as GooglePlayIcon } from '../../assets/img/landing/google-play.svg';

const Footer = () => {
  return (
    <footer className="pt-10 font-weight-medium">
      <div className="border-bottom pb-1">
        <div className="container space-2">
          <div className="row justify-content-md-between">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-sm-5 col-lg-5 mb-4 mb-lg-0">
                  <h4 className="pl-3 font-weight-semi-bold">Tìm hiểu thêm</h4>
                  <ul className="list-group list-group-flush list-group-borderless mb-0">
                    <li style={{ listStyle: 'none' }}>
                      <a
                        className="list-group-item py-2 bg-transparent border-0 list-group-item-action"
                        href="for-partner.html"
                      >
                        Dành cho đối tác
                      </a>
                    </li>
                    <li style={{ listStyle: 'none' }}>
                      <a
                        className="list-group-item py-2 bg-transparent border-0 list-group-item-action"
                        href="faq.html"
                      >
                        Câu hỏi thường gặp
                      </a>
                    </li>
                    <li style={{ listStyle: 'none' }}>
                      <a
                        className="list-group-item py-2 bg-transparent border-0 list-group-item-action"
                        href="privacy.html"
                      >
                        Chính sách & Quyền riêng tư
                      </a>
                    </li>
                    <li style={{ listStyle: 'none' }}>
                      <a
                        className="list-group-item py-2 bg-transparent border-0 list-group-item-action"
                        href="term.html"
                      >
                        Thỏa thuận Sử dụng
                      </a>
                    </li>
                    <li style={{ listStyle: 'none' }}>
                      <a
                        className="list-group-item py-2 bg-transparent border-0 list-group-item-action"
                        href="contact.html"
                      >
                        Liên hệ
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-sm-7 col-lg-7 mb-4 mb-lg-0">
                  <h4 className="pl-3 font-weight-semi-bold">Các tài nguyên</h4>
                  <ul className="list-group list-group-flush list-group-borderless mb-0">
                    <li style={{ listStyle: 'none' }}>
                      <a
                        className="list-group-item py-2 bg-transparent border-0 list-group-item-action"
                        href="https://drive.google.com/file/d/1wEiI4J5Me7mcB_y2qvn_dG6b8lqiRJiv/view?usp=sharing"
                      >
                        Hợp tác truyền thông{' '}
                        <sup>
                          <span className="badge badge-info">White paper</span>
                        </sup>
                      </a>
                    </li>
                    <li style={{ listStyle: 'none' }}>
                      <a
                        className="list-group-item py-2 bg-transparent border-0 list-group-item-action"
                        href="https://drive.google.com/file/d/1GvOZC88M3VEnzCyKjUIPBJ62u_GRBCtM/view?usp=sharing"
                      >
                        Ngành thú cưng Việt Nam 2020{' '}
                        <sup>
                          <span className="badge badge-info">Report</span>
                        </sup>
                      </a>
                    </li>
                    <li style={{ listStyle: 'none' }}>
                      <a
                        className="list-group-item py-2 bg-transparent border-0 list-group-item-action"
                        href="https://open.spotify.com/show/6g7GlbUlbMZBd9VFBEDBJy"
                      >
                        Câu chuyện thú cưng{' '}
                        <sup>
                          <span className="badge badge-info">Podcast</span>
                        </sup>
                      </a>
                    </li>
                    <li style={{ listStyle: 'none' }}>
                      <a className="list-group-item py-2 bg-transparent border-0 list-group-item-action" href="blog">
                        Kiến thức & Chia sẻ{' '}
                        <sup>
                          <span className="badge badge-info">Blog</span>
                        </sup>
                      </a>
                    </li>
                    <li style={{ listStyle: 'none' }}>
                      <a
                        className="list-group-item py-2 bg-transparent border-0 list-group-item-action"
                        href="https://pety.vn/blog/nganh-cong-nghiep-thu-cung-viet-nam-voi-xu-huong-va-cac-chi-so-tich-cuc.html"
                      >
                        Tiềm năng phát triển ngành thú cưng ở Việt Nam
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mb-6 text-center text-md-left">
                <h4 className="pl-3 mt-5 mb-3 font-weight-semi-bold">Theo dõi Pety tại</h4>
                <ul className="list-inline mb-0 pl-1">
                  <li className="list-inline-item mx-2">
                    <a
                      className="btn py-2 btn-sm bg-light border text-1000"
                      href="https://www.facebook.com/PetyApp.Co"
                      aria-label="Facebook"
                    >
                      <FontAwesomeIcon icon={faFacebook} size="lg" title="Facebook" />
                    </a>
                  </li>
                  <li className="list-inline-item mx-2">
                    <a
                      className="btn py-2 btn-sm bg-light border text-1000"
                      href="https://www.instagram.com/petyapp.co"
                      aria-label="Instagram"
                    >
                      <FontAwesomeIcon icon={faInstagram} size="lg" title="Instagram" />
                    </a>
                  </li>
                  <li className="list-inline-item mx-2">
                    <a
                      className="btn py-2 btn-sm bg-light border text-1000"
                      href="https://www.tiktok.com/@petyapp.co"
                      aria-label="TikTok"
                    >
                      <FontAwesomeIcon icon={faTiktok} size="lg" title="TikTok" />
                    </a>
                  </li>
                  <li className="list-inline-item mx-2">
                    <a
                      className="btn py-2 btn-sm bg-light border text-1000"
                      href="https://www.youtube.com/channel/UCDQaV-o1c-bEAcpYJWv-f1g"
                      aria-label="Youtube"
                    >
                      <FontAwesomeIcon icon={faYoutube} size="lg" title="Youtube" />
                    </a>
                  </li>
                  <li className="list-inline-item mx-2">
                    <a
                      className="btn py-2 btn-sm bg-light border text-1000"
                      href="https://www.linkedin.com/company/petyjsc"
                      aria-label="LinkedIn"
                    >
                      <FontAwesomeIcon icon={faLinkedin} size="lg" title="LinkedIn" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <div className="text-center text-md-left">
                <h4 className="h4 font-weight-semi-bold">Pety có làm bạn tò mò?</h4>
                <p className="mt-2 mb-3 text-muted">Bấm để tải ngay!</p>
                <a
                  className="btn w-75 w-md-auto py-2 px-3 btn-light border shadow-sm btn-wide transition-3d-hover m-1"
                  href="https://apps.apple.com/vn/app/pety/id1506375124"
                >
                  <Media style={{ lineHeight: 1 }}>
                    <AppleIcon style={{ width: '36px', height: '36px' }} className="mr-2 my-1" />
                    <Media body className="text-left">
                      <p className="small m-0">Tải miễn phí</p>
                      <p className="h4 m-0 font-weight-bold">App Store</p>
                    </Media>
                  </Media>
                </a>
                <a
                  className="btn w-75 w-md-auto py-2 px-3 btn-light border shadow-sm btn-wide m-1"
                  href="https://play.google.com/store/apps/details?id=com.pety.moon"
                >
                  <Media style={{ lineHeight: 1 }}>
                    <GooglePlayIcon style={{ width: '36px', height: '36px' }} className="mr-2 my-1" />
                    <Media body className="text-left">
                      <p className="small m-0">Tải miễn phí</p>
                      <p className="h4 m-0 font-weight-bold">Google Play</p>
                    </Media>
                  </Media>
                </a>
                <p className="mt-3 mb-2 text-muted">Hoặc quét mã QR</p>
                <div style={{ width: '138px' }} className="border d-inline-block">
                  <LazyLoad>
                    <img
                      style={{ width: '136px', height: '136px' }}
                      src={qrCode}
                      alt="Scan QR Code to download Pety"
                      title="Scan QR Code to download Pety"
                    />
                  </LazyLoad>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center mt-5 text-muted">
          <p className="m-1">
            Phiên bản website đang trong giai đoạn thử nghiệm và chờ phê duyệt giấy phép Mạng Xã Hội
          </p>
        </div>
      </div>

      <div className="container text-center mt-5 pb-8">
        <a className="d-inline-flex align-items-center mb-3 text-decoration-none" href="#about-pety" aria-label="Pety">
          <img
            alt="Pety Logo"
            width="50"
            height="50"
            src="https://d6cpoqt24b96v.cloudfront.net/pety/pety-120.png"
            style={{ width: '50px', borderRadius: '6px' }}
          />
          <span className="text-primary h4 mb-0 ml-3">Pety</span>
        </a>

        <p className="text-muted font-weight-medium">
          &copy; Pety 2019. Toàn bộ bản quyền thuộc về Pety Technology JSC.
          <br />
          Mã số doanh nghiệp: 0316144897, do Sở Kế hoạch và Đầu tư TP. Hồ Chí Minh cấp ngày 14/02/2020.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
