import React from 'react';

import { Card, CardBody } from 'reactstrap';

const MobileOffer = () => {
  return (
    <Card className="mb-3 bg-soft-success ">
      <CardBody>
        <p>
          Hãy tải phiên bản mobile của Pety trên{' '}
          <a className="font-weight-bold" href="https://apps.apple.com/vn/app/pety/id1506375124">
            Apple Appstore
          </a>{' '}
          và{' '}
          <a className="font-weight-bold" href="https://play.google.com/store/apps/details?id=com.pety.moon">
            Google Play
          </a>{' '}
          để trải nghiệm các tính năng mới nhất.
        </p>
      </CardBody>
    </Card>
  );
};

export default MobileOffer;
