import React, { Fragment, useContext, useState } from 'react';
import { Spinner, Row, Col } from 'reactstrap';
import { TransitionGroup } from 'react-transition-group';
import Crypt from '../../lib/Crypt';
import _ from '../../lib/Var';
import Fade from '../../anim/Fade';
import Comment from './Comment';
import { PostContext } from '../../context/Context';
import CommentProvider from '../../provider/CommentProvider';
import { isIterableArray } from '../../helpers/utils';

const Comments = ({ moreComment, nowShowing, totalComment, setReplyTo, setReplyToName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    loadComment,
    Comments: PostComments,
    CommentLoadedAll,
    LastComment,
    NewComments,
    setNewComments
  } = useContext(PostContext);

  return (
    <Fragment>
      {!!moreComment &&
        (!CommentLoadedAll && (
          <Row noGutters>
            <Col className="col-atuo">
              <a
                className="fs--1 text-700 d-block mt-2 text-left"
                href="#!"
                onClick={() => {
                  if (isLoading) return;

                  // free new comments
                  setNewComments([]);

                  setIsLoading(true);
                  loadComment(() => {
                    setIsLoading(false);
                  });
                }}
              >
                Xem thêm {_.round(parseInt(totalComment) - parseInt(nowShowing))} bình luận
                {isLoading && (
                  <Spinner style={{ width: '1rem', height: '1rem' }} type="grow" color="primary" className="ml-1" />
                )}
              </a>
            </Col>
            <Col className="text-right">
              <span className="fs--1 text-500">{moreComment}</span>
            </Col>
          </Row>
        ))}
      <TransitionGroup>
        {Array.isArray(PostComments) &&
          PostComments.length > 0 &&
          PostComments.map(Data => (
            <Fade key={`comment-${Data.PostCommentId}`}>
              <CommentProvider CommentRecord={Data}>
                <Comment setReplyTo={setReplyTo} setReplyToName={setReplyToName} />
              </CommentProvider>
            </Fade>
          ))}

        {!isIterableArray(PostComments) && LastComment !== null && typeof LastComment !== 'undefined' && (
          <Fade key={`comment-${_.get(LastComment, 'PostCommentId')}`}>
            <CommentProvider CommentRecord={LastComment}>
              <Comment setReplyTo={setReplyTo} setReplyToName={setReplyToName} />
            </CommentProvider>
          </Fade>
        )}

        {Array.isArray(NewComments) &&
          NewComments.length > 0 &&
          NewComments.map(Data => (
            <Fade key={`comment-${Crypt.sha1(Data.Comment)}`}>
              <CommentProvider CommentRecord={Data}>
                <Comment setReplyTo={setReplyTo} setReplyToName={setReplyToName} />
              </CommentProvider>
            </Fade>
          ))}
      </TransitionGroup>
    </Fragment>
  );
};

export default Comments;
