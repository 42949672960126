import React from 'react';
import { Collapse, Navbar, Nav, Container } from 'reactstrap';
import Logo from './Logo';
import NavbarLogin from './NavbarLogin';

const NavbarTopGuest = () => {
  return (
    <Navbar id="navbar" light className="bg-white font-weight-semi-bold row navbar-top sticky-kit shadown-md" expand>
      <Container className="p-1">
        <Logo Branch="Pety" Slogan="Mạng xã hội của người yêu thú cưng" at="navbar-top" width={60} id="topLogo" />
        <Collapse navbar>
          <Nav navbar className="align-items-center ml-auto">
            <NavbarLogin />
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarTopGuest;
