import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import NavbarTop from '../widget/navbar/NavbarTop';
import NavbarVertical from '../widget/navbar/NavbarVertical';
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import FeedWrapper from '../pages/feed/FeedWrapper';
import withRedirect from '../hoc/withRedirect';
import Login from '../pages/auth/Login';
import SearchProvider from '../provider/SearchProvider';

const DashboardRoutes = loadable(() => import('../route/DashboardRoutes'));

const DashboardLayout = ({ location }) => {
  const { isFluid, isGuest } = useContext(AppContext);

  useEffect(() => {
    document.body.classList.remove('bg-white');
  }, []);

  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return isGuest ? (
    <Login />
  ) : (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <NavbarVertical />
      <div className="content">
        <SearchProvider>
          <NavbarTop />
          <Switch>
            <Route path="/" exact component={FeedWrapper} />
            <DashboardRoutes />
          </Switch>
        </SearchProvider>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default withRedirect(DashboardLayout);
