import React from 'react';
import { Card, CardBody } from 'reactstrap';

const ContestInfo = () => {
  return (
    <Card className="mb-3 shadow-sm">
      <CardBody>
        <h5 className="font-weight-semi-bold mb-3">Giải Thưởng</h5>
        <p className="mb-2">
          <b>1 Giải nhất</b>: Voucher 5 triệu đồng
        </p>
        <p className="mb-2">
          <b>1 Giải nhì</b>: Voucher 1 triệu đồng
        </p>
        <p className="mb-2">
          <b>1 Giải ba</b>: Voucher 500 ngàn đồng
        </p>

        <h5 className="font-weight-semi-bold mt-5 mb-3">Thời Gian</h5>
        <p className="mb-2">Tham dự: từ ngày 5/6/2020 – ngày 15/06/2020</p>
        <p className="mb-2">Công bố giải thưởng: 20h ngày 16/06/2020</p>

        <a
          className="btn btn-light btn-block mt-3"
          target="_blank"
          rel="noopener noreferrer"
          href="https://pety.vn/blog/khoe-pet-xinh-rinh-qua-xin.html"
        >
          Xem Chi Tiết
        </a>
      </CardBody>
    </Card>
  );
};

export default ContestInfo;
