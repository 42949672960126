// rgb colors
export const livelyColors = [
  '#007BFF',
  '#6F42C1',
  '#E83E8C',
  '#6610F2',
  '#DC3545',
  '#FD7E14',
  '#20C997',
  '#17A2B8',
  '#28A745',
  '#2196F3'
];

// rgba colors
export const livelyOpacityColors = [
  'rgba(0, 123, 255, 0.5)',
  'rgba(111, 66, 193, 0.5)',
  'rgba(232, 62, 140, 0.5)',
  'rgba(102, 16, 242, 0.5)',
  'rgba(220, 53, 69, 0.5)',
  'rgba(253, 126, 20, 0.5)',
  'rgba(32, 201, 151, 0.5)',
  'rgba(23, 162, 184, 0.5)',
  'rgba(40, 167, 69, 0.5)',
  'rgba(33, 150, 243, 0.5)'
];
