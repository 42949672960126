const dog = {
  Name: 'Dog',
  Key: 'dog',
  Icon: require('./0-0.png'),
  Tag: '',
  Items: [
    { Key: '0-0.png', File: require('./0-0.png') },
    { Key: '0-1.png', File: require('./0-1.png') },
    { Key: '0-2.png', File: require('./0-2.png') },
    { Key: '0-3.png', File: require('./0-3.png') },
    { Key: '1-0.png', File: require('./1-0.png') },
    { Key: '1-1.png', File: require('./1-1.png') },
    { Key: '1-2.png', File: require('./1-2.png') },
    { Key: '1-3.png', File: require('./1-3.png') },
    { Key: '2-0.png', File: require('./2-0.png') },
    { Key: '2-1.png', File: require('./2-1.png') },
    { Key: '2-2.png', File: require('./2-2.png') },
    { Key: '2-3.png', File: require('./2-3.png') },
    { Key: '3-0.png', File: require('./3-0.png') },
    { Key: '3-1.png', File: require('./3-1.png') },
    { Key: '3-2.png', File: require('./3-2.png') },
    { Key: '3-3.png', File: require('./3-3.png') },
    { Key: '4-0.png', File: require('./4-0.png') },
    { Key: '4-1.png', File: require('./4-1.png') },
    { Key: '4-2.png', File: require('./4-2.png') },
    { Key: '4-3.png', File: require('./4-3.png') },
    { Key: '5-0.png', File: require('./5-0.png') },
    { Key: '5-1.png', File: require('./5-1.png') },
    { Key: '5-2.png', File: require('./5-2.png') },
    { Key: '5-3.png', File: require('./5-3.png') },
    { Key: '6-0.png', File: require('./6-0.png') },
    { Key: '6-1.png', File: require('./6-1.png') },
    { Key: '6-2.png', File: require('./6-2.png') },
    { Key: '6-3.png', File: require('./6-3.png') },
    { Key: '7-0.png', File: require('./7-0.png') },
    { Key: '7-1.png', File: require('./7-1.png') },
    { Key: '7-2.png', File: require('./7-2.png') },
    { Key: '7-3.png', File: require('./7-3.png') },
    { Key: '8-0.png', File: require('./8-0.png') },
    { Key: '8-1.png', File: require('./8-1.png') },
    { Key: '8-2.png', File: require('./8-2.png') },
    { Key: '8-3.png', File: require('./8-3.png') },
    { Key: '9-0.png', File: require('./9-0.png') },
    { Key: '9-1.png', File: require('./9-1.png') },
    { Key: '9-2.png', File: require('./9-2.png') },
    { Key: '9-3.png', File: require('./9-3.png') }
  ]
};
export default dog;
