import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { isMobile } from 'react-device-detect';
import LazyLoad from 'react-lazyload';
import Avatar from '../../components/common/Avatar';
import Flex from '../../components/common/Flex';
import { Row, Col, Media } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as Icon45 } from '../../assets/img/landing/reward.svg';
import { ReactComponent as Icon4 } from '../../assets/img/landing/worldwide.svg';
import { ReactComponent as IconGuarantee } from '../../assets/img/landing/guarantee.svg';
import { ReactComponent as Icon7 } from '../../assets/img/landing/chat.svg';

import market from '../../assets/img/landing/market-place.webp';
import health from '../../assets/img/landing/health-profile.webp';

import u1 from '../../assets/img/landing/13602.1620279873.2c5c8a34-10ae-4018-805f-cbb43642a771.webp';
import u2 from '../../assets/img/landing/6012.1608432504.e85b7950-8f50-4344-83dd-1795737b05dc.webp';
import u3 from '../../assets/img/landing/9564.1617619490.dae293b2-ddc3-4445-89b7-476753e5f389.webp';

import av1 from '../../assets/img/landing/av.u1.jpg';
import av2 from '../../assets/img/landing/av.u2.jpg';
import av3 from '../../assets/img/landing/av.u3.jpg';

const Features = () => {
  return (
    <div className="container">
      <Row>
        <Col md="6">
          {isMobile ? (
            <FeatureSticky />
          ) : (
            <Sticky top="#navbar-landing" bottomBoundary="#feature-imgs">
              <FeatureSticky />
            </Sticky>
          )}
        </Col>
        <Col md="6">
          <div id="feature-imgs">
            <div className="position-relative mb-4">
              <img
                alt="Minh Nhựt"
                width="582"
                height="286"
                className="w-100 shadow border rounded"
                src={u1}
                style={{ height: 'auto' }}
              />
              <Flex
                align="center"
                className="position-absolute"
                style={{
                  bottom: 1,
                  left: 1,
                  right: 1,
                  padding: 8,
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  borderBottomLeftRadius: 6,
                  borderBottomRightRadius: 6
                }}
              >
                <a href="https://pety.vn/bud/minh-nhut-3" alt="Minh Nhựt" aria-label="Minh Nhựt">
                  <Avatar src={av1} alt="Minh Nhựt" className="mr-2 border rounded-pill" size="2xl" />
                </a>
                <a href="https://pety.vn/bud/minh-nhut-3" alt="Minh Nhựt" style={{ lineHeight: '1.2' }}>
                  <p className="text-white m-0 font-weight-bold">Minh Nhựt</p>
                  <p className="text-white m-0">
                    và bạn chó Golden Retriever tên <b>SAM</b>
                  </p>
                </a>
              </Flex>
            </div>
            <div className="position-relative mb-4">
              <a href="https://pety.vn/bud/van-khanh-3" alt="Van Khanh">
                <img
                  alt="Vân Khánh"
                  className="w-100 shadow border rounded"
                  src={u2}
                  width="498"
                  height="368"
                  style={{ minHeight: '200px', height: 'auto' }}
                />
              </a>
              <Flex
                align="center"
                className="position-absolute"
                style={{
                  bottom: 1,
                  left: 1,
                  right: 1,
                  padding: 8,
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  borderBottomLeftRadius: 6,
                  borderBottomRightRadius: 6
                }}
              >
                <a href="https://pety.vn/bud/van-khanh-3" alt="Van Khanh" aria-label="Van Khanh">
                  <Avatar src={av2} alt="Van Khanh" className="mr-2 border rounded-pill" size="2xl" />
                </a>
                <a href="https://pety.vn/bud/van-khanh-3" alt="Van Khanh" style={{ lineHeight: '1.2' }}>
                  <p className="text-white m-0 font-weight-bold">Van Khanh</p>
                  <p className="text-white m-0">
                    và bạn mèo Ba-Tư tên <b>Gấu</b>
                  </p>
                </a>
              </Flex>
            </div>
            <div className="position-relative">
              <img
                alt="Quang Trường"
                width="540"
                height="720"
                className="w-100 shadow border rounded"
                src={u3}
                style={{ minHeight: '400px', height: 'auto' }}
              />
              <Flex
                align="center"
                className="position-absolute"
                style={{
                  bottom: 1,
                  left: 1,
                  right: 1,
                  padding: 8,
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  borderBottomLeftRadius: 6,
                  borderBottomRightRadius: 6
                }}
              >
                <a href="https://pety.vn/bud/quang-truong-2" alt="Quang Trường" aria-label="Quang Trường">
                  <Avatar src={av3} alt="Quang Trường" className="mr-2 border rounded-pill" size="2xl" />
                </a>
                <a href="https://pety.vn/bud/quang-truong-2" alt="Quang Trường" style={{ lineHeight: '1.2' }}>
                  <p className="text-white m-0 font-weight-bold">Quang Trường</p>
                  <p className="text-white m-0">
                    và bạn chó Alaska Malamute tên <b>Moca</b>
                  </p>
                </a>
              </Flex>
            </div>
          </div>
        </Col>
      </Row>
      <div className="pt-10">
        <div className="w-75 mx-auto text-center mb-8">
          <span className="alert py-2 small btn-light font-weight-semi-bold rounded-capsule">Không chỉ có thế</span>
          <h2 className="font-weight-semi-bold mb-0 mt-3">
            Pety dùng công nghệ <br />
            mang lại nhiều tiện ích cho việc chăm sóc thú cưng
          </h2>
        </div>
        <div className="row mb-8">
          <div className="col-lg-7">
            <div className="px-0 pt-6 rounded bg-soft-info text-center">
              <h5 className="text-900 mb-4 px-3">Mua sắm sản phẩm dịch vụ chăm sóc thú cưng</h5>
              <LazyLoad>
                <div className="ml-n2 mr-n2 ml-lg-n4 mr-lg-n4">
                  <img src={market} className="w-100 rounded" alt="Pety Retail" />
                </div>
              </LazyLoad>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="mt-4 mt-lg-8 pl-lg-6">
              <h4 className="text-900 mb-3">Cam kết 100% sản phẩm chính hãng</h4>
              <p className="text-500 mb-4">
                Tất cả sản phẩm có giấy phép nhập khẩu chính ngạch và được đảm bảo bởi các đại lý phân phối chính hãng
              </p>
              <Media className="mb-3">
                <span className="d-inline-block px-1 py-0 bg-soft-info rounded-pill mr-3">
                  <FontAwesomeIcon size="sm" icon={faCheck} />
                </span>
                <Media body>
                  <span className="text-700">Nhiều ưu đãi hấp dẫn</span>
                </Media>
              </Media>
              <Media className="mb-3">
                <span className="d-inline-block px-1 py-0 bg-soft-info rounded-pill mr-3">
                  <FontAwesomeIcon size="sm" icon={faCheck} />
                </span>
                <Media body>
                  <span className="text-700">Miễn phí giao hàng cho đơn hàng trên 300K</span>
                </Media>
              </Media>
              <Media className="mb-3">
                <span className="d-inline-block px-1 py-0 bg-soft-info rounded-pill mr-3">
                  <FontAwesomeIcon size="sm" icon={faCheck} />
                </span>
                <Media body>
                  <span className="text-700">Cộng điểm thưởng Pety Reward siêu xịn</span>
                </Media>
              </Media>
            </div>
          </div>
        </div>
        <div className="row mb-8">
          <div className="col-lg-5">
            <div className="mt-4 mt-lg-8 mb-4 pr-lg-6">
              <h4 className="text-900 mb-3">Hồ sơ sức khỏe điện tử cho thú cưng của bạn</h4>
              <p className="text-500 mb-4">
                Pety dùng công nghệ để thay đổi trải nghiệm chăm sóc thú cưng của bạn theo hướng tiện lợi và hiện đại
              </p>

              <Media className="mb-3">
                <span className="d-inline-block px-1 py-0 bg-soft-info rounded-pill mr-3">
                  <FontAwesomeIcon size="sm" icon={faCheck} />
                </span>
                <Media body>
                  <span className="text-700">Chế độ dinh dưỡng, tiêm chủng, tẩy giun, dị ứng, tính cách</span>
                </Media>
              </Media>

              <Media className="mb-3">
                <span className="d-inline-block px-1 py-0 bg-soft-info rounded-pill mr-3">
                  <FontAwesomeIcon size="sm" icon={faCheck} />
                </span>
                <Media body>
                  <span className="text-700">Biểu đồ theo dõi quá trình phát triển cân nặng</span>
                </Media>
              </Media>

              <Media className="mb-3">
                <span className="d-inline-block px-1 py-0 bg-soft-info rounded-pill mr-3">
                  <FontAwesomeIcon size="sm" icon={faCheck} />
                </span>
                <Media body>
                  <span className="text-700">
                    Trợ lý thú cưng thông minh <b>Pety AI Assistant</b>
                  </span>
                </Media>
              </Media>
              <Media className="mb-3">
                <span className="d-inline-block px-1 py-0 bg-soft-info rounded-pill mr-3">
                  <FontAwesomeIcon size="sm" icon={faCheck} />
                </span>
                <Media body>
                  <span className="text-700">Tự động nhắc lịch tẩy giun, tiêm chủng</span>
                </Media>
              </Media>
            </div>
          </div>

          <div className="col-lg-7">
            <div className="px-0 pt-6 rounded bg-soft-primary text-center">
              <h5 className="text-900 mb-4 px-3">Chúng tôi quan tâm sức khỏe thú cưng của bạn</h5>
              <LazyLoad>
                <img src={health} className="w-100" alt="Health Profile on Pety" />
              </LazyLoad>
            </div>
          </div>
        </div>
        <div className="rounded w-100 w-lg-75 px-3 px-lg-8 pt-5 pb-4 m-0 mx-lg-auto shadow mb-6">
          <p className="text-900 text-center mb-4">
            Và nhiều tính năng hữu ích khác như <b>tìm phòng khám</b>, <b>đặt dịch vụ</b>, <b>tra cứu</b> thông tin, hỏi
            đáp với <b>chuyên gia và bác sĩ thú y</b>...
          </p>
        </div>
      </div>
    </div>
  );
};

const FeatureSticky = () => {
  return (
    <Fragment>
      <h3 className="font-weight-semi-bold mb-6">Bạn sẽ có những trải nghiệm cực thú vị cùng thú cưng</h3>
      <Media className="mb-5">
        <Icon4 className="mr-5 svg" style={{ width: '68px', height: '68px' }} />
        <Media body className="pr-4">
          <div className="b h5 font-weight-semi-bold">Mạng xã hội</div>
          <p className="font-weight-medium text-justify">
            Đăng lên những hình ảnh, video ghi lại những khoảnh khắc vui vẻ cùng nhau.
          </p>
          <p className="font-weight-medium text-justify">
            Kết giao với những người đầy thú vị, tham gia vào các club theo sở thích để xem các nội dung hay. Hoặc nhận
            những tư vấn giá trị từ các bác sĩ thú y giỏi chuyên môn.
          </p>
        </Media>
      </Media>
      <Media className="mb-5">
        <Icon45 className="mr-5 svg" style={{ width: '68px', height: '68px' }} />
        <Media body className="pr-4">
          <div className="b h5 font-weight-semi-bold">Hữu ích</div>
          <p className="font-weight-medium text-justify">
            Tham gia thường xuyên nhận điểm để đổi hàng trăm phần quà cho bạn và thú cưng với Pety Rewards.
          </p>
          <p className="font-weight-medium text-justify">
            Hồ sơ sức khỏe thú cưng trên Pety giúp trải nghiệm chăm sóc thú cưng của bạn trở nên thuận tiện hơn nhiều.
          </p>
        </Media>
      </Media>
      <Media className="mb-5">
        <Icon7 className="mr-5 svg" style={{ width: '68px', height: '68px' }} />
        <Media body className="pr-4">
          <div className="b h5 font-weight-semi-bold">Lan tỏa</div>
          <p className="font-weight-medium text-justify">
            Pety là cộng đồng những người cực kỳ yêu động vật. Hãy chuẩn bị trước việc thú cưng của bạn làm cho mọi
            người xỉu up xỉu down vì độ cute chữ e kéo dài.
          </p>
          <p className="font-weight-medium text-justify">
            Pety còn hỗ trợ tìm kiếm thú cưng thất lạc. Câu lạc bộ hiệp sĩ cứu hộ thú cưng trên Pety sẽ không bao giờ
            làm bạn thất vọng.
          </p>
        </Media>
      </Media>
      <Media className="mb-5 pb-5">
        <IconGuarantee className="mr-5 svg" style={{ width: '68px', height: '68px' }} />
        <Media body className="pr-4">
          <div className="b h5 font-weight-semi-bold">Cá nhân hóa</div>
          <p className="font-weight-medium text-justify">
            Bên cạnh profile của bạn là profile xủa tất cả thú cưng của bạn.
          </p>
          <p className="font-weight-medium text-justify">
            Với Pety, bạn dễ dàng tìm kiếm sản phẩm/dịch vụ cho thú cưng quanh khu vực mình sinh sống. Và chỉ bằng một
            lần chạm là bạn có được thứ mình cần.
          </p>
        </Media>
      </Media>
    </Fragment>
  );
};

export default Features;
