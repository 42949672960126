import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Media } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Config } from '../../lib/Config';
import { isIterableArray } from '../../helpers/utils';

const PetBirthdayNotice = ({ Pets }) => (
  <Card className="mb-3 mt-3 mt-lg-0 shadow-sm">
    <CardBody className="fs--1">
      <Media>
        <FontAwesomeIcon icon="gift" className="fs-0 text-warning" />
        <Media body className="ml-2">
          {isIterableArray(Pets) &&
            Pets.slice(0, Config.shortListLimit).map((El, Idx) => {
              return (
                <Link
                  key={`pet-birthday-notice-${Idx}`}
                  className="font-weight-semi-bold mr-2"
                  to={`/zoo/${El.ProfileUrl}`}
                >
                  {El.Name}
                </Link>
              );
            })}
          {` sinh nhật hôm nay`}
        </Media>
      </Media>
    </CardBody>
  </Card>
);

PetBirthdayNotice.propTypes = {
  Pets: PropTypes.array.isRequired
};

export default PetBirthdayNotice;
