import React, { useContext } from 'react';
import { Collapse, Navbar, NavItem, Nav } from 'reactstrap';
import AppContext from '../../context/Context';
import Logo from './Logo';
import SearchBox from './SearchBox';
import ProfileDropdown from './ProfileDropdown';
import NotificationDropdown from './NotificationDropdown';
import SunIcon from './SunIcon';

const NavbarTop = () => {
  const { showBurgerMenu, setShowBurgerMenu } = useContext(AppContext);

  return (
    <Navbar id="navbar" light className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit" expand>
      <div className="toggle-icon-wrapper d-xl-none">
        <button
          className="navbar-vertical-toggle btn btn-link d-flex align-item-center justify-content-center "
          onClick={() => {
            setShowBurgerMenu(!showBurgerMenu);
          }}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </div>
      <Logo Branch="Pety" Slogan="" at="navbar-top" width={40} id="topLogo" />
      <Collapse navbar className="position-relative">
        <Nav navbar className="position-absolute col-xl-8 col-lg-7 col-md-7 pr-2" style={{ top: 0, left: 0 }}>
          <NavItem className="d-none d-md-block w-100">
            <SearchBox />
          </NavItem>
        </Nav>

        <Nav navbar className="align-items-center ml-auto">
          <NotificationDropdown />
          <ProfileDropdown />
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarTop;
