import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Media, Badge, Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import lo from 'lodash';

import { isIterableArray } from '../../helpers/utils';
import Avatar from '../../components/common/Avatar';
import _ from '../../lib/Var';
import J from '../../lib/Jalio';
import { apis, Config } from '../../lib/Config';
// import Loader from '../../components/common/Loader';

const TabBoard = ({ LoadMore, TabId }) => {
  const [Leaders, setLeaders] = useState([]);
  const [ContestId, setContestId] = useState(0);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [indicatorLeft, setIndicatorLeft] = useState(null);
  const [indicatorRight, setIndicatorRight] = useState(null);
  const [isReverse, setIsReverse] = useState(false);

  const [Lmstart, setLmstart] = useState(0);
  const [LoadedAll, setLoadedAll] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);

  const Ranks = [apis.contest.rank.all, apis.contest.rank.today, apis.contest.rank.thisweek];

  function offset(el) {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  const updateIndicator = id => {
    const navbar = document.getElementById(`fancy-tab-contest-ranking-${TabId}`);
    const tabnavCurrentItem = document.getElementById(id);
    const navbarLeft = offset(navbar).left;
    const left = offset(tabnavCurrentItem).left - navbarLeft;
    const right = navbar.offsetWidth - (left + tabnavCurrentItem.offsetWidth);
    setIndicatorLeft(left);
    setIndicatorRight(right);
  };

  const handleActiveTab = ({ target }) => {
    const { id, tabIndex } = target;
    updateIndicator(id);
    setIsReverse(currentTabIndex > tabIndex);
    setCurrentTabIndex(tabIndex);
  };

  // query the running contest
  useEffect(() => {
    handleActiveTab({ target: { id: `ranking-all-${TabId}`, tabIndex: 0 } });

    J.get(apis.contest.info).then(res => {
      setContestId(_.getInt(res, 'module.Contest.ContestId', 0));
    });

    // eslint-disable-next-line
  }, []);

  const loadPets = () => {
    if (ContestId > 0) {
      if (LoadedAll || IsLoading) return;

      setIsLoading(true);
      J.post(Ranks[currentTabIndex], { ContestId, lmstart: Lmstart })
        .then(res => {
          const Pets = _.get(res, 'module.Pets', []);

          if (isIterableArray(Pets)) {
            if (LoadMore) {
              setLeaders(lo.union(Leaders, Pets));
            } else {
              setLeaders(lo.union(Leaders, Pets).slice(0, Config.shortListLimit));
            }

            setLmstart(Lmstart + Pets.length);

            if (Pets.length < Config.listLimit) {
              setLoadedAll(true);
            }
          } else {
            setLoadedAll(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!isIterableArray(Leaders) && Lmstart === 0 && !LoadedAll) loadPets();

    // eslint-disable-next-line
  }, [Leaders]);

  // query the ranking
  useEffect(() => {
    setLeaders([]);
    setLmstart(0);
    setLoadedAll(false);

    // eslint-disable-next-line
  }, [currentTabIndex, ContestId]);

  return (
    <div className="fancy-tab" id={`fancy-tab-contest-ranking-${TabId}`}>
      <div className="nav-bar">
        <div key="ranking-tab-handle-0">
          <div
            tabIndex={0}
            id={`ranking-all-${TabId}`}
            onClick={() => {
              handleActiveTab({ target: { id: `ranking-all-${TabId}`, tabIndex: 0 } });
            }}
            className="text-center p-2"
          >
            Tổng Sắp
          </div>
        </div>
        <div key="ranking-tab-handle-1">
          <div
            tabIndex={1}
            id={`ranking-today-${TabId}`}
            onClick={() => {
              handleActiveTab({ target: { id: `ranking-today-${TabId}`, tabIndex: 1 } });
            }}
            className="text-center p-2"
          >
            Hôm Nay
          </div>
        </div>
        <div key="ranking-tab-handle-2">
          <div
            tabIndex={2}
            id={`ranking-thisweek-${TabId}`}
            onClick={() => {
              handleActiveTab({ target: { id: `ranking-thisweek-${TabId}`, tabIndex: 2 } });
            }}
            className="text-center p-2"
          >
            Tuần Này
          </div>
        </div>
        <div
          className={classNames('tab-indicator', {
            'transition-reverse': isReverse
          })}
          style={{ left: indicatorLeft, right: indicatorRight }}
        />
      </div>
      <div className="tab-contents mt-0">
        <div className="tab-content p-2 active">
          {isIterableArray(Leaders) &&
            Leaders.map((Item, Idx) => (
              <Link key={`pet-leader-board-${Idx}`} to={`/zoo/${Item.ProfileUrl}`} style={{ textDecoration: 'none' }}>
                <Media className="mb-1 p-2 hover-bg rounded">
                  <Media tag="h5" className="mr-3 pt-1 font-weight-bold">
                    {currentTabIndex === 0 && Idx === 0 && (
                      <span role="img" aria-label="Emoji">
                        👑
                      </span>
                    )}
                    {currentTabIndex === 0 && Idx === 1 && (
                      <span role="img" aria-label="Emoji">
                        🌟
                      </span>
                    )}
                    {currentTabIndex === 0 && Idx === 2 && (
                      <span role="img" aria-label="Emoji">
                        🌼
                      </span>
                    )}
                    {(currentTabIndex !== 0 || Idx > 2) && <span>{Idx + 1}</span>}
                  </Media>
                  <Avatar src={Item.Avatar} size="xl" className="status-online" />
                  <Media body className="ml-2 pt-0">
                    <div>
                      <b>{Item.Name}</b>
                      {currentTabIndex === 0 && Idx === 0 && (
                        <div className="small font-weight-semi-bold text-400">Vạn người mê</div>
                      )}
                      {currentTabIndex === 0 && Idx === 1 && (
                        <div className="small font-weight-semi-bold text-400">Gây thương nhớ</div>
                      )}
                      {currentTabIndex === 0 && Idx === 2 && (
                        <div className="small font-weight-semi-bold text-400">Chuyên đốn tim</div>
                      )}
                    </div>
                  </Media>
                  <Badge color="danger" className="rounded-capsule float-right">
                    <FontAwesomeIcon icon="heart" className="mr-1" />
                    {_.format(Item.NumFavorite)}
                  </Badge>
                </Media>
              </Link>
            ))}
        </div>
        <div className="px-2">
          {LoadMore && isIterableArray(Leaders) && (
            <Button onClick={() => loadPets()} disabled={LoadedAll || IsLoading} className="btn btn-light btn-block">
              {LoadedAll ? 'Đã Tải Hết' : 'Tải Thêm'}
              {IsLoading && <Spinner size="sm" className="float-right" />}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TabBoard;
