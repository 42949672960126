import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

const Error404 = () => (
  <Card className="text-center">
    <CardBody className="p-5">
      <div className="display-1 text-200 fs-error">404</div>
      <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">Không tìm thấy nội dung bạn truy cập</p>
      <hr />
      <p>
        Điều này xảy ra khi bạn truy cập vào một nội dung không tồn tại hoặc đã bị xóa. Nếu không phải, hãy
        <a href="https://pety.vn/contact.html" className="ml-1">
          liên hệ
        </a>{' '}
        với chúng tôi để được trợ giúp.
      </p>
      <Link className="btn btn-primary btn-sm mt-3" to="/">
        <FontAwesomeIcon icon="home" className="mr-2" />
        Về trang chủ
      </Link>
    </CardBody>
  </Card>
);

export default Error404;
