import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import NavbarTop from '../widget/navbar/NavbarTop';
import Footer from '../components/footer/Footer';
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import PostWrapper from '../pages/post/PostWrapper';
import NavbarTopGuest from '../widget/navbar/NavbarTopGuest';
import EventWrapper from '../pages/event/EventWrapper';
import SearchProvider from '../provider/SearchProvider';

const PublicRoutes = loadable(() => import('../route/PublicRoutes'));

const AtomLayout = ({ location }) => {
  const { isFluid, isGuest } = useContext(AppContext);

  useEffect(() => {
    PublicRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isGuest ? 'container-fluid' : isFluid ? 'container-fluid' : 'container'}>
      <div className="content">
        <SearchProvider>
          {isGuest ? <NavbarTopGuest /> : <NavbarTop />}

          {!isGuest && (
            <Switch>
              <Route path="/post/:id" exact component={PostWrapper} />
              <PublicRoutes />
            </Switch>
          )}

          {isGuest && (
            <div className="container">
              <Switch>
                <Route path="/post/:id" exact component={PostWrapper} />
                <Route path="/events" exact component={EventWrapper} />
                <PublicRoutes />
              </Switch>
            </div>
          )}
        </SearchProvider>
        <Footer />
      </div>
    </div>
  );
};

AtomLayout.propTypes = { location: PropTypes.object.isRequired };

export default AtomLayout;
