import _ from './Var';
import J from './Jalio';
import { apis } from './Config';

const User = {
  isGuest: () => {
    return User.UserId === null || parseInt(User.UserId) === 0;
  },
  // lưu dữ liệu truy vấn từ server vào local storage
  save: u => {
    User.bind(u);
    User.store();
    return User;
  },

  login: async (username, password) => {
    const Params = { username, password };

    // refer and other param be stick to login/signup
    if (window.location.hash.length > 1) {
      const hash = new URLSearchParams(window.location.hash.substr(1));

      hash.forEach(function(Val, Key) {
        Params[Key] = Val;
      });
    }

    const res = await J.post(apis.login, Params);
    if (_.getInt(res, 'module.user.UserId') > 0) {
      User.save(_.get(res, 'module.user'));
    }

    return Promise.resolve(res);
  },

  logout: async () => {
    J._post(apis.logout).finally(() => {
      User.clear();
    });
  },

  // xóa dữ liệu loal storate đã lưu
  clear: async () => {
    localStorage.setItem('User.UserId', 0);
    localStorage.setItem('User.Name', '');
    localStorage.setItem('User.Avatar', '');
    localStorage.setItem('User.AvatarFile', '');
    localStorage.setItem('User.Cover', '');
    localStorage.setItem('User.DateOfBirth', '');
    localStorage.setItem('User.Mobile', '');
    localStorage.setItem('User.Gender', 0);
    localStorage.setItem('User.AuthenMethod', '');
    localStorage.setItem('User.ProfileStatus', '');
    localStorage.setItem('User.ProfileUrl', '');
    localStorage.setItem('token', '');
    localStorage.setItem('tokenExpiredAt', 0);
    localStorage.setItem('User.ActivatedAt', '');
    localStorage.setItem('User.fbAccessToken', '');
    localStorage.setItem('User.ggAccessToken', '');
    localStorage.setItem('User.appleAccessToken', '');
  },

  // lấy một field dữ liệu của user từ đối tượng hiện tại
  get: async (field, dft) => {
    return this.hasOwnProperty(field) ? this[field] : dft;
  },

  set: async (field, value) => {
    User[field] = value;
    localStorage.setItem(field, `${value}`);
  },

  bind: source => {
    if (Array.isArray(source) || typeof source == 'object') {
      for (let key in source) {
        if (User.hasOwnProperty(key)) User[key] = source[key];
      }
    }
    return User;
  },

  store: () => {
    localStorage.setItem('User.UserId', `${User.UserId}`);
    localStorage.setItem('User.Name', `${User.Name}`);
    localStorage.setItem('User.Avatar', `${User.Avatar}`);
    localStorage.setItem('User.AvatarFile', `${User.AvatarFile}`);
    localStorage.setItem('User.Cover', `${User.Cover}`);
    localStorage.setItem('User.DateOfBirth', `${User.DateOfBirth}`);
    localStorage.setItem('User.Mobile', `${User.Mobile}`);
    localStorage.setItem('User.Gender', `${User.Gender}`);
    localStorage.setItem('User.AuthenMethod', `${User.AuthenMethod}`);
    localStorage.setItem('User.ProfileStatus', `${User.ProfileStatus}`);
    localStorage.setItem('User.ProfileUrl', `${User.ProfileUrl}`);
    localStorage.setItem('token', `${User.token}`);
    localStorage.setItem('tokenExpiredAt', `${User.tokenExpiredAt}`);
    localStorage.setItem('User.ActivatedAt', `${User.ActivatedAt}`);
    localStorage.setItem('User.fbAccessToken', `${User.fbAccessToken}`);
    localStorage.setItem('User.ggAccessToken', `${User.ggAccessToken}`);
    localStorage.setItem('User.appleAccessToken', `${User.appleAccessToken}`);
  },

  _: (Key, Dft) => {
    return localStorage.getItem(Key) === null ? Dft : localStorage.getItem(Key);
  },

  // binding dữ liệu từ local storage vào đối tượng user
  live: () => {
    User.UserId = User._('User.UserId', 0);
    User.Name = User._('User.Name', '');
    User.Mobile = User._('User.Mobile', '');
    User.Avatar = User._('User.Avatar', '');
    User.AvatarFile = User._('User.AvatarFile', '');
    User.Cover = User._('User.Cover', '');
    User.DateOfBirth = User._('User.DateOfBirth', '');
    User.Gender = User._('User.Gender', 0);
    User.AuthenMethod = User._('User.AuthenMethod', 'Jalio');
    User.ProfileStatus = User._('User.ProfileStatus', '');
    User.ProfileUrl = User._('User.ProfileUrl', '');
    User.token = User._('token', '');
    User.tokenExpiredAt = User._('tokenExpiredAt', 0);
    User.ActivatedAt = User._('User.ActivatedAt', 0);
    User.fbAccessToken = User._('User.fbAccessToken', '');
    User.ggAccessToken = User._('User.ggAccessToken', '');
    User.appleAccessToken = User._('User.appleAccessToken', '');

    return User;
  },

  UserId: 0,
  Mobile: '',
  Gender: 0,
  AuthenMethod: 'Jalio',
  ProfileStatus: '',
  ProfileUrl: '',
  Name: '',
  DateOfBirth: '',
  Avatar: '',
  AvatarFile: '',
  Cover: '',
  token: '',
  tokenExpiredAt: 0,
  ActivatedAt: '',
  fbAccessToken: '',
  ggAccessToken: '',
  appleAccessToken: ''
};

export default User;
