const mars = {
  Name: 'Mars',
  Key: 'mars',
  Icon: require('./mars5.gif'),
  Tag: '',
  Items: [
    { Key: 'mars1.gif', File: require('./mars1.gif') },
    { Key: 'mars10.gif', File: require('./mars10.gif') },
    { Key: 'mars11.gif', File: require('./mars11.gif') },
    { Key: 'mars12.gif', File: require('./mars12.gif') },
    { Key: 'mars13.gif', File: require('./mars13.gif') },
    { Key: 'mars14.gif', File: require('./mars14.gif') },
    { Key: 'mars15.gif', File: require('./mars15.gif') },
    { Key: 'mars16.gif', File: require('./mars16.gif') },
    { Key: 'mars17.gif', File: require('./mars17.gif') },
    { Key: 'mars18.gif', File: require('./mars18.gif') },
    { Key: 'mars19.gif', File: require('./mars19.gif') },
    { Key: 'mars2.gif', File: require('./mars2.gif') },
    { Key: 'mars20.gif', File: require('./mars20.gif') },
    { Key: 'mars21.gif', File: require('./mars21.gif') },
    { Key: 'mars22.gif', File: require('./mars22.gif') },
    { Key: 'mars23.gif', File: require('./mars23.gif') },
    { Key: 'mars24.gif', File: require('./mars24.gif') },
    { Key: 'mars25.gif', File: require('./mars25.gif') },
    { Key: 'mars26.gif', File: require('./mars26.gif') },
    { Key: 'mars27.gif', File: require('./mars27.gif') },
    { Key: 'mars28.gif', File: require('./mars28.gif') },
    { Key: 'mars29.gif', File: require('./mars29.gif') },
    { Key: 'mars3.gif', File: require('./mars3.gif') },
    { Key: 'mars30.gif', File: require('./mars30.gif') },
    { Key: 'mars31.gif', File: require('./mars31.gif') },
    { Key: 'mars32.gif', File: require('./mars32.gif') },
    { Key: 'mars33.gif', File: require('./mars33.gif') },
    { Key: 'mars34.gif', File: require('./mars34.gif') },
    { Key: 'mars35.gif', File: require('./mars35.gif') },
    { Key: 'mars36.gif', File: require('./mars36.gif') },
    { Key: 'mars37.gif', File: require('./mars37.gif') },
    { Key: 'mars38.gif', File: require('./mars38.gif') },
    { Key: 'mars39.gif', File: require('./mars39.gif') },
    { Key: 'mars4.gif', File: require('./mars4.gif') },
    { Key: 'mars40.gif', File: require('./mars40.gif') },
    { Key: 'mars41.gif', File: require('./mars41.gif') },
    { Key: 'mars42.gif', File: require('./mars42.gif') },
    { Key: 'mars43.gif', File: require('./mars43.gif') },
    { Key: 'mars44.gif', File: require('./mars44.gif') },
    { Key: 'mars45.gif', File: require('./mars45.gif') },
    { Key: 'mars46.gif', File: require('./mars46.gif') },
    { Key: 'mars47.gif', File: require('./mars47.gif') },
    { Key: 'mars48.gif', File: require('./mars48.gif') },
    { Key: 'mars49.gif', File: require('./mars49.gif') },
    { Key: 'mars5.gif', File: require('./mars5.gif') },
    { Key: 'mars50.gif', File: require('./mars50.gif') },
    { Key: 'mars6.gif', File: require('./mars6.gif') },
    { Key: 'mars7.gif', File: require('./mars7.gif') },
    { Key: 'mars8.gif', File: require('./mars8.gif') },
    { Key: 'mars9.gif', File: require('./mars9.gif') }
  ]
};
export default mars;
