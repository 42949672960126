import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardImg, Col, Media, Row, Alert } from 'reactstrap';
import { isMobile } from 'react-device-detect';

import ContentWithAsideLayout from '../../layout/ContentWithAsideLayout';
import Calendar from '../../components/common/Calendar';
import pets from '../../assets/img/generic/khoe-pet-xinh.png';
import EventPost from './EventPost';
import ModalFullBoard from '../../widget/contest/ModalFullBoard';
import EventAside from './EventAside';

const EventDetailBanner = () => (
  <Card className="mb-3 shadow-sm">
    <a rel="noopener noreferrer" target="_blank" href="https://pety.vn/blog/khoe-pet-xinh-rinh-qua-xin.html">
      <CardImg top src={pets} alt="Khoe Pet xinh - Rinh quà xịn 🐶 🐱" />
    </a>
    <CardBody>
      <Row className="justify-content-between align-items-center">
        <Col>
          <Media>
            <Calendar day="05" month="June" />
            <Media body className="fs--1 ml-2">
              <h4 className="font-weight-semi-bold">
                Khoe Pet xinh - Rinh quà xịn{' '}
                <span aria-label="emoji" role="img">
                  🐶 🐱
                </span>
              </h4>
              <p className="mb-0">
                Tổ chức bởi{' '}
                <Link className="font-weight-semi-bold" to="#!">
                  Pety
                </Link>{' '}
                và{' '}
                <a
                  href="https://kingspet.vn/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="font-weight-semi-bold"
                >
                  King's Pet.
                </a>
              </p>
            </Media>
          </Media>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

const EventWrapper = () => {
  const [IsModalOpen, setIsModalOpen] = useState(false);

  return (
    <ContentWithAsideLayout banner={<EventDetailBanner />} isStickyAside={false} aside={!isMobile && <EventAside />}>
      {isMobile && (
        <div className="mb-3">
          <Button disabled onClick={() => setIsModalOpen(!IsModalOpen)} className="text-center btn btn-info btn-block">
            Bảng Xếp Hạng
          </Button>
          <ModalFullBoard {...{ IsModalOpen, setIsModalOpen }} />
        </div>
      )}
      <Alert color="info">
        Sự kiện <b>Khoe Pet xinh - Rinh quà xịn</b> đã kết thúc và giải thưởng đã được trao. Hãy cập nhật thường xuyên
        để không bỏ lỡ sự kiện tiếp theo trên Pety nhé.
      </Alert>
      <EventPost />
    </ContentWithAsideLayout>
  );
};

export default EventWrapper;
