import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Loader from '../common/Loader';

const PreviewPhoto = ({ Photo, size, isResizing, onClose }) => {
  return (
    <div
      className="shadow-sm p-0 m-0 bg-white rounded position-relative"
      style={{
        width: size === 'lg' ? '16rem' : size === 'sm' ? '4rem' : '8rem',
        position: 'relative',
        minHeight: '4rem'
      }}
    >
      {Photo !== null && <img className="w-100 rounded" src={Photo.Base64} alt={Photo.Name} />}
      {!!isResizing && (
        <div className="position-absolute" style={{ top: 0, left: 0, right: 0, bottom: 0 }}>
          <Loader />
        </div>
      )}
      {typeof onClose !== 'undefined' && (
        <Button
          style={{ position: 'absolute', top: '0.25rem', right: '0.25rem', width: '1.6rem' }}
          color="link"
          className="rounded-capsule text-white bg-danger p-0 text-center"
          onClick={() => onClose()}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      )}
    </div>
  );
};

export default PreviewPhoto;
