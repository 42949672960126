import React, { useContext, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Media, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faUserTimes, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import Avatar from '../../components/common/Avatar';
import ButtonIcon from '../../components/common/ButtonIcon';
import m from '../../lib/Moment';
import AppContext from '../../context/Context';

const PersonFollow = ({ UserId, UserAvatar, Name, NumFollower, RegistedAt, ProfileUrl, divider }) => {
  const { doFollowPeople } = useContext(AppContext);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isShowUnfollowConfirm, setIsShowUnfollowConfirm] = useState(false);

  const follow = isDoFollow => {
    setIsFollowing(!isFollowing);

    // query the server to update
    doFollowPeople(UserId, res => {
      if (!!res && isDoFollow) {
        toast(
          <Media>
            <FontAwesomeIcon className="text-success" icon={faCheckCircle} size="3x" />
            <Media body className="ml-3">
              Bạn đã theo dõi <span className="font-weight-semi-bold">{Name}</span> thành công. Hãy ghé qua trang cá
              nhân của <span className="font-weight-semi-bold">{Name}</span> để xem, chia sẻ và bình luận những bài viết
              mới nhé.
            </Media>
          </Media>
        );
      }
    });
  };

  return (
    <Fragment>
      <Media>
        <Avatar size="3xl" src={UserAvatar} className="border rounded-circle" />
        <Media body className="ml-2">
          <h6 className="mb-0 font-weight-semi-bold">
            <Link to={`/bud/${ProfileUrl}`}>{Name}</Link>
          </h6>
          <p className="fs--1 mb-0">
            Tham gia Pety {m.unix(RegistedAt).fromNow()}. Hiện có {NumFollower} người theo dõi.
          </p>
          <ButtonIcon
            color={isFollowing ? 'success' : 'light'}
            size="sm"
            icon={isFollowing ? 'check' : 'user-plus'}
            className="py-0 mt-1 border"
            transform="shrink-5"
            onClick={() => {
              isFollowing ? setIsShowUnfollowConfirm(true) : follow(true);
            }}
          >
            <span className="fs--1">{isFollowing ? 'Đang theo dõi' : 'Theo dõi'}</span>
          </ButtonIcon>

          {!divider && <hr className="border-bottom-0 border-dashed" />}
        </Media>
      </Media>
      {/* modal box confirm việc bỏ follow một người */}
      {isFollowing && (
        <Modal
          isOpen={isShowUnfollowConfirm}
          toggle={() => {
            setIsShowUnfollowConfirm(!isShowUnfollowConfirm);
          }}
        >
          <ModalBody className="text-center text-700 pt-4">
            <FontAwesomeIcon icon={faInfoCircle} size="3x" />
            <p className="mt-3">
              Bạn muốn bỏ theo dõi <span className="text-primary font-weight-semi-bold"> {Name}</span>?
            </p>
          </ModalBody>
          <ModalFooter className="pt-1 justify-content-center">
            <button
              onClick={() => {
                setIsShowUnfollowConfirm(false);
                follow(false);
              }}
              className="btn btn-primary"
            >
              <FontAwesomeIcon icon={faUserTimes} className="mr-2" />
              Bỏ theo dõi
            </button>
            <button onClick={() => setIsShowUnfollowConfirm(false)} className="btn btn-outline-dark">
              Không
            </button>
          </ModalFooter>
        </Modal>
      )}
    </Fragment>
  );
};

PersonFollow.propTypes = {
  UserId: PropTypes.string.isRequired,
  UserAvatar: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  ProfileStatus: PropTypes.string,
  divider: PropTypes.bool
};

PersonFollow.defaultProps = { divider: false };

export default PersonFollow;
