import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
// import LeaderBoard from '../../widget/contest/LeaderBoard';
import ContestInfo from '../../widget/contest/ContestInfo';

const EventAside = () => {
  return (
    <Fragment>
      <Sticky top="#navbar">
        <ContestInfo />
        {/* <LeaderBoard /> */}
      </Sticky>
    </Fragment>
  );
};

export default EventAside;
