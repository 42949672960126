const push = {
  Name: 'Push',
  Key: 'push',
  Icon: require('./push1.gif'),
  Tag: '',
  Items: [
    { Key: 'push1.gif', File: require('./push1.gif') },
    { Key: 'push10.gif', File: require('./push10.gif') },
    { Key: 'push11.gif', File: require('./push11.gif') },
    { Key: 'push12.gif', File: require('./push12.gif') },
    { Key: 'push13.gif', File: require('./push13.gif') },
    { Key: 'push14.gif', File: require('./push14.gif') },
    { Key: 'push15.gif', File: require('./push15.gif') },
    { Key: 'push16.gif', File: require('./push16.gif') },
    { Key: 'push17.gif', File: require('./push17.gif') },
    { Key: 'push18.gif', File: require('./push18.gif') },
    { Key: 'push19.gif', File: require('./push19.gif') },
    { Key: 'push2.gif', File: require('./push2.gif') },
    { Key: 'push20.gif', File: require('./push20.gif') },
    { Key: 'push21.gif', File: require('./push21.gif') },
    { Key: 'push22.gif', File: require('./push22.gif') },
    { Key: 'push23.gif', File: require('./push23.gif') },
    { Key: 'push24.gif', File: require('./push24.gif') },
    { Key: 'push25.gif', File: require('./push25.gif') },
    { Key: 'push26.gif', File: require('./push26.gif') },
    { Key: 'push27.gif', File: require('./push27.gif') },
    { Key: 'push28.gif', File: require('./push28.gif') },
    { Key: 'push29.gif', File: require('./push29.gif') },
    { Key: 'push3.gif', File: require('./push3.gif') },
    { Key: 'push30.gif', File: require('./push30.gif') },
    { Key: 'push31.gif', File: require('./push31.gif') },
    { Key: 'push32.gif', File: require('./push32.gif') },
    { Key: 'push33.gif', File: require('./push33.gif') },
    { Key: 'push34.gif', File: require('./push34.gif') },
    { Key: 'push35.gif', File: require('./push35.gif') },
    { Key: 'push36.gif', File: require('./push36.gif') },
    { Key: 'push37.gif', File: require('./push37.gif') },
    { Key: 'push38.gif', File: require('./push38.gif') },
    { Key: 'push39.gif', File: require('./push39.gif') },
    { Key: 'push4.gif', File: require('./push4.gif') },
    { Key: 'push40.gif', File: require('./push40.gif') },
    { Key: 'push41.gif', File: require('./push41.gif') },
    { Key: 'push42.gif', File: require('./push42.gif') },
    { Key: 'push43.gif', File: require('./push43.gif') },
    { Key: 'push44.gif', File: require('./push44.gif') },
    { Key: 'push45.gif', File: require('./push45.gif') },
    { Key: 'push46.gif', File: require('./push46.gif') },
    { Key: 'push47.gif', File: require('./push47.gif') },
    { Key: 'push48.gif', File: require('./push48.gif') },
    { Key: 'push49.gif', File: require('./push49.gif') },
    { Key: 'push5.gif', File: require('./push5.gif') },
    { Key: 'push50.gif', File: require('./push50.gif') },
    { Key: 'push6.gif', File: require('./push6.gif') },
    { Key: 'push7.gif', File: require('./push7.gif') },
    { Key: 'push8.gif', File: require('./push8.gif') },
    { Key: 'push9.gif', File: require('./push9.gif') }
  ]
};

export default push;
