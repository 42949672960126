import { faPaw, faBookmark, faVideo, faMap } from '@fortawesome/free-solid-svg-icons';

export const homeRoutes = [
  {
    name: 'Trang Chủ',
    to: '/',
    exact: true,
    icon: 'home',
    className: 'light-icon icon-color-sky'
  },
  {
    name: 'Tài Khoản',
    to: `/profile`,
    icon: 'user',
    exact: true,
    className: 'light-icon icon-color-blue'
  },
  {
    name: 'Thông Báo',
    to: '/notifications',
    exact: true,
    icon: 'bell',
    className: 'light-icon icon-color-pink'
  },
  {
    name: 'Video',
    to: '/video',
    exact: true,
    icon: faVideo,
    className: 'light-icon icon-color-netflix'
    // badge: {
    //   text: 'New!',
    //   color: 'danger'
    // }
  },
  {
    name: 'Vườn Thú',
    to: '/zoo',
    exact: false,
    icon: faPaw,
    className: 'light-icon icon-color-hue'
  },
  // {
  //   name: 'Sự Kiện',
  //   to: '/events',
  //   exact: true,
  //   icon: faCalendarAlt,
  //   className: 'gradient-icon gradient-icon-brown'
  //   // badge: {
  //   //   text: 1,
  //   //   color: 'danger'
  //   // }
  // },
  // {
  //   name: 'Wishlist',
  //   to: '/wishlist',
  //   exact: true,
  //   icon: 'heart',
  //   className: 'gradient-icon gradient-icon-hue'
  // },
  {
    name: 'Phòng Khám',
    to: '/place',
    exact: false,
    icon: faMap,
    className: 'light-icon icon-color-yellow',
    badge: {
      text: 'Mới!',
      color: 'danger'
    }
  },
  {
    name: 'Kiến Thức',
    to: 'https://pety.vn/blog',
    exact: true,
    icon: 'book',
    className: 'light-icon icon-color-purple'
  },
  {
    name: 'Đã Lưu',
    to: '/bookmark',
    exact: true,
    icon: faBookmark,
    className: 'light-icon icon-color-green'
  }
];

export const landingRoutes = {};

export default homeRoutes;
