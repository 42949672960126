import moment from 'moment';
import 'moment/locale/vi';
import 'moment-timezone';
moment.tz('Asia/Ho_Chi_Minh').format();
moment.locale('vi');
// moment.updateLocale('vi	', {
//   monthsShort: 'T01_T02_T03_T04_T05_T06_T07_T08_T09_T10_T11_T12'.split('_')
// });

export default moment;
