import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import DashboardLayout from './DashboardLayout';
import AtomLayout from './AtomLayout';
import LandingWrapper from '../pages/landing/LandingWrapper';
import ErrorLayout from './ErrorLayout';
import AppContext from '../context/Context';

import loadable from '@loadable/component';
const AuthRoutes = loadable(() => import('../route/AuthRoutes'));
const PublicRoutes = loadable(() => import('../route/PublicRoutes'));

const Layout = () => {
  const { isGuest } = useContext(AppContext);

  useEffect(() => {
    AuthRoutes.preload();
    PublicRoutes.preload();
  }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/auth" component={AuthRoutes} />
        <Route path="/errors" component={ErrorLayout} />
        <Route path="/post/:id" component={AtomLayout} />
        <Route exact path="/" component={isGuest ? LandingWrapper : DashboardLayout} />
        <Route component={DashboardLayout} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.TOP_RIGHT} />
    </Router>
  );
};

export default Layout;
