import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Row, Col, Container } from 'reactstrap';
import { PostContext } from '../../context/Context';
import Avatar from '../common/Avatar';
import sticker from '../../sticker/sticker';

function offset(el) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

const Stickers = ({ onSelect }) => {
  const { PostId } = useContext(PostContext);
  const [activeTab, setActiveTab] = useState(sticker[0].Key);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [Collection, setCollection] = useState(sticker[currentTabIndex]);
  const [indicatorLeft, setIndicatorLeft] = useState(null);
  const [indicatorRight, setIndicatorRight] = useState(null);
  const [isReverse, setIsReverse] = useState(false);

  const updateIndicator = id => {
    const navbar = document.getElementById(`fancy-tab-sticker-${PostId}`);
    const tabnavCurrentItem = document.getElementById(id);
    const navbarLeft = offset(navbar).left;
    const left = offset(tabnavCurrentItem).left - navbarLeft;
    const right = navbar.offsetWidth - (left + tabnavCurrentItem.offsetWidth);
    setIndicatorLeft(left);
    setIndicatorRight(right);
  };

  const handleActiveTab = ({ target }) => {
    const { id, tabIndex } = target;
    setActiveTab(id);
    updateIndicator(id);
    setIsReverse(currentTabIndex > tabIndex);
    setCurrentTabIndex(tabIndex);
  };

  useEffect(() => {
    handleActiveTab({ target: { id: sticker[0].Key, tabIndex: 0 } });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCollection(sticker[currentTabIndex]);
  }, [currentTabIndex]);

  return (
    <div className="fancy-tab" style={{ minWidth: '30rem' }} id={`fancy-tab-sticker-${PostId}`}>
      <div className="nav-bar">
        {sticker.map((El, Idx) => {
          return (
            <div
              key={`sticker-tab-handle-${PostId}-${Idx}`}
              className={classNames('nav-bar-item p-0', { active: activeTab === El.Key })}
            >
              <div
                tabIndex={Idx}
                id={El.Key}
                onClick={() => {
                  handleActiveTab({ target: { id: El.Key, tabIndex: Idx } });
                }}
                className="mt-1 text-center py-0 px-2 fs--1"
              >
                <Avatar src={El.Icon} />
              </div>
            </div>
          );
        })}
        <div
          className={classNames('tab-indicator', {
            'transition-reverse': isReverse
          })}
          style={{ left: indicatorLeft, right: indicatorRight }}
        />
      </div>
      <div className="tab-contents m-0">
        <div className="tab-content p-1 active">
          {typeof Collection.Items !== 'undefined' && Array.isArray(Collection.Items) && (
            <Container>
              <Row>
                {Collection.Items.map((Item, Idx) => (
                  <Col
                    tag="a"
                    onClick={() => onSelect(Collection.Key, Item)}
                    className="text-center p-1 sticker-item-container"
                    key={`sticker-item-${Collection.Key}-${Idx}`}
                    xs="2"
                  >
                    <Avatar src={Item.File} size="2xl" />
                  </Col>
                ))}
              </Row>
            </Container>
          )}
        </div>
      </div>
    </div>
  );
};

export default Stickers;

/**
 * nhận vào item là một sticker ở client-side và parse thành đối tượng
 * để post lên server side
 *
 * @param {*} item
 */
export const parseForServerside = item => {
  return typeof item.Key !== undefined ? { pack: item.Pack, id: item.Key } : '';
};

/**
 * nhận vào item là một đối tượng dạng server-side và trả về sticker
 * tương ứng ở client-side
 *
 * @param {*} item
 */
export const getSticker = item => {
  let need = null;
  sticker.forEach(Collection => {
    if (Collection.Key === item.pack) {
      Collection.Items.forEach(El => {
        if (El.Key === item.id) {
          need = El;
          return;
        }
      });
    }
  });

  return need;
};
