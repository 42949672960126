const domainEnv = process.env.REACT_APP_DOMAIN;
const fbAppId = process.env.REACT_APP_FB;
const ggCLientId = process.env.REACT_APP_GG;
const serverApp = process.env.REACT_APP_SERVER_APP;

export const Config = {
  listLimit: 10,
  shortListLimit: 4,
  photoUploadMaxSize: 1200,
  facebookAppId: fbAppId,
  googleClientId: ggCLientId,

  get domain() {
    return domainEnv;
  },

  // request base url
  get url() {
    return domainEnv + `/${serverApp}/`;
  },

  get pathAvatar() {
    return domainEnv + '/files/avatar/thumbs/';
  },

  get pathArticle() {
    return domainEnv + '/files/images/article/';
  },

  get pathCover() {
    return domainEnv + '/files/images/';
  },

  get linkArticle() {
    return domainEnv + `/${serverApp}/guest/news?_fm=html&id=`;
  },

  get mobLink() {
    return domainEnv + '/mob/';
  }
};

/**
 * a:xYZ --> a: module; xYZ: action
 * i/j/k --> dùng để get (i: module; j: view; k: layout)
 */

export const apis = {
  home: Config.url + 'guest/home',
  cat: Config.url + 'guest/news?CategoryId=',
  art: Config.url + 'guest/news?_fm=html&id=',
  login: 'user:login',
  loginFb: 'user:loginFb',
  loginGg: 'user:loginGg',
  loginApple: 'user:loginApple',
  signup: 'user:signup',
  logout: 'user:logout',

  // block
  reasonBlock: 'block/block/breason',
  reasonReport: 'block/block/rreason',
  blockPost: 'block:blockPost',
  blockUser: 'block:blockUser',
  reportPost: 'block:reportPost',
  undoBlockedPost: 'block:undoBlockedPost',
  undoBlockedUser: 'block:undoBlockedUser',
  undoReported: 'block:undoReported',

  geo: {
    provinces: 'geo',
    districts: 'geo/geo/district',
    wards: 'geo/geo/ward',
    places: 'geo/place',
    place: 'geo/place/detail'
  },

  // profiles
  profile: {
    info: 'user/profile',
    setting: 'user/profile/setting',
    bookmark: 'wall/profile/bookmark',
    bookmarkMember: 'wall/profile/bmember',
    bookmarkHastag: 'wall/profile/bhastag',
    uploadAvatar: 'user:Logedin.uploadAvatar',
    uploadCover: 'user:Logedin.uploadCover',
    updateName: 'user:Logedin.updateName',
    updateStatus: 'user:Logedin.updateProfileStatus',
    fpets: 'user/profile/fpets', // following pet, order by the last-touch-time
    photos: 'user/profile/photos' // highlight photos of user
  },
  // profile: 'user/profile',
  // profileSetting: 'user/profile/setting',
  // myBookmark: 'user/profile/bookmark',
  uploadAvatar: 'user:Logedin.uploadAvatar',
  uploadCover: 'user:Logedin.uploadCover',
  // updateName: 'user:Logedin.updateName',
  // updateStatus: 'user:Logedin.updateProfileStatus',

  // ---------- apis for verify & update account --------------
  user: {
    verify: 'user:verifyAccount',
    resendVerificationCode: 'user:resendVerificationCode',
    updateMobile: 'user:Logedin.updateMobile',
    updateProfile: 'user:Logedin.updateProfile',
    offers: 'user/profile/offers'
  },
  // ----------------------------------------------------------

  // last active
  getLastActive: 'user:Logedin.getLastActive',

  // apis for wall
  wall: 'wall',
  wallPet: 'wall/pet',
  favo: 'wall:favo',
  delPost: 'wall:del',
  sharePost: 'wall:share',
  comment: 'wall/wall/comment',
  favoComment: 'wall:favoComment',
  delComment: 'wall:delComment',
  postComment: 'wall:postComment',
  post: 'wall/wall/post',
  postStatus: 'wall:postStatus',

  // apis for follow
  follow: 'follow',
  followed: 'follow/follow/followed',
  follower: 'follow/follow/follower',
  doFollow: 'follow:follow',
  following: 'follow/follow/following',
  // followingNum: 'follow/follow/numFollowing',

  // apis for post-favorite list
  postFavorite: 'wall/wall/favorite',
  postCommentors: 'wall/wall/commentor',
  commentFavorite: 'wall/wall/cmfavorite',

  // apis for newfeed (new)
  newfeed: 'newfeed',
  video: 'newfeed/newfeed/video',
  atomPost: 'newfeed/newfeed/post',
  bookmark: 'newfeed:bookmark',
  birthday: 'newfeed/newfeed/birthday',

  // apis for search
  search: 'search:search',
  saveSearch: 'search:save',
  recentSearch: 'search/search/recent',

  // api for pet
  pet: {
    breed: 'pet/pet/breed',
    owner: 'pet/pet/owner',
    wish: 'pet/pet/wish',
    similar: 'pet/pet/similar',
    info: 'pet',
    save: 'pet:savePet',
    saveWish: 'pet:saveWishPet',
    delWish: 'pet:delWishPet',
    statistic: 'pet/pet/statistic',
    follow: 'pet:follow',
    followed: 'pet:followed',
    gallery: 'pet/pet/gallery',
    followers: 'pet/pet/followers',
    favorites: 'pet/pet/favorites',
    all: 'pet/zoo',
    news: 'pet/zoo/news',
    uploadAvatar: 'pet:uploadAvatar',
    uploadCover: 'pet:uploadCover'
  },

  hashtag: {
    trending: 'search/hashtag/trending'
  },

  // api for notification
  noti: {
    all: 'notification',
    read: 'notification:read',
    quantity: 'notification/notification/quantity'
  },

  // api for knowledge
  knowledge: 'knowledge',
  category: 'knowledge/knowledge/category',
  tag: 'knowledge/knowledge/tag',

  // api for Leader Board
  board: 'board',
  boardWeek: 'board/board/week',
  boardMonth: 'board/board/month',

  // ---------- apis for news-feed (old)------
  feed: {
    search: 'feed/feed/search',
    highlight: 'feed/feed/highlight',
    trending: 'feed/feed/trending',
    member: 'feed/feed/member',
    category: 'feed/feed/category'
  },
  // -----------------------------------------

  // ---------- bridget to query ogp----------
  bridge: {
    ogp: 'bridge/bridge/ogp?_fm=raw'
  },

  // ---------- apis for contest ------
  contest: {
    info: 'contest',
    rank: {
      all: 'contest/rank',
      today: 'contest/rank/day',
      thisweek: 'contest/rank/week'
    }
  }
  // -----------------------------------------
};
