import React, { useState, useContext, useEffect, Fragment } from 'react';
import { Card, Dropdown, DropdownMenu, Media, DropdownToggle, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import ListGroup from 'reactstrap/es/ListGroup';
import ListGroupItem from 'reactstrap/es/ListGroupItem';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile, isBrowser } from 'react-device-detect';

import _ from '../../lib/Var';
import { apis } from '../../lib/Config';
import J from '../../lib/Jalio';
import m from '../../lib/Moment';

import { isIterableArray } from '../../helpers/utils';
import AppContext from '../../context/Context';
import NotificationItem from '../../components/noti/Notification';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import Loader from '../../components/common/Loader';

const NotificationDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [FilterUnread, setFilterUnread] = useState(false);

  const {
    UnreadNotificationQuantity,
    setUnreadNotificationQuantity,
    loadNotification,
    loadUnreadNotification,
    Notification
  } = useContext(AppContext);

  const [ShowNotification, setShowNotification] = useState(Notification);
  const [TodayNotification, setTodayNotification] = useState(
    Notification.filter(el => m.unix(el.TriggeredAt).isSame(m(), 'day'))
  );

  const isEmpty = () => {};

  // load number of unread notification
  useEffect(() => {
    J.get(apis.noti.quantity).then(res => {
      setUnreadNotificationQuantity(_.getInt(res, 'module.UnreadQuantity', 0));
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoading) return;

    if (!!isOpen) {
      if (!!FilterUnread) {
        setIsLoading(true);
        loadUnreadNotification(res => {
          setShowNotification(res.filter(el => !m.unix(el.TriggeredAt).isSame(m(), 'day')));
          setTodayNotification(res.filter(el => m.unix(el.TriggeredAt).isSame(m(), 'day')));
          // re-binding the number of notification
          isIterableArray(res) && setUnreadNotificationQuantity(res.length);
          setIsLoading(false);
        });
      } else {
        if (Array.isArray(Notification) && Notification.length === 0) {
          setIsLoading(true);
          loadNotification(res => {
            setShowNotification(res.filter(el => m.unix(el.TriggeredAt).isSame(m(), 'day')));
            setTodayNotification(res.filter(el => m.unix(el.TriggeredAt).isSame(m(), 'day')));
            setIsLoading(false);
          });
        } else if (isIterableArray(Notification)) {
          setShowNotification(Notification.filter(el => !m.unix(el.TriggeredAt).isSame(m(), 'day')));
          setTodayNotification(Notification.filter(el => m.unix(el.TriggeredAt).isSame(m(), 'day')));
        }
      }
    }
    // eslint-disable-next-line
  }, [isOpen, FilterUnread]);

  // Handler
  const handleToggle = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      {isMobile && (
        <li className="nav-item">
          <Link
            to={'/notifications'}
            nav
            className={classNames('px-0', {
              'nav-link notification-indicator notification-indicator-primary': parseInt(UnreadNotificationQuantity) > 0
            })}
          >
            {UnreadNotificationQuantity > 0 && (
              <span className="notification-indicator-number">{_.round(UnreadNotificationQuantity)}</span>
            )}
            <Media tag="a" className="rounded-capsule bg-light p-1">
              <FontAwesomeIcon
                style={{ width: '1.5rem', height: '1.5rem', fontSize: '2rem', lineHeight: '1.5rem' }}
                icon="bell"
                transform="shrink-6"
                className="fs-4"
              />
            </Media>
          </Link>
        </li>
      )}
      {isBrowser && (
        <Dropdown nav inNavbar isOpen={isOpen} toggle={handleToggle}>
          <DropdownToggle
            nav
            className={classNames('px-0', {
              'notification-indicator notification-indicator-primary': parseInt(UnreadNotificationQuantity) > 0
            })}
          >
            {UnreadNotificationQuantity > 0 && (
              <span className="notification-indicator-number">{_.round(UnreadNotificationQuantity)}</span>
            )}
            <Media className="rounded-capsule bg-light p-1">
              <FontAwesomeIcon
                style={{ width: '1.5rem', height: '1.5rem', fontSize: '2rem', lineHeight: '1.5rem' }}
                icon="bell"
                transform="shrink-6"
                className="fs-4"
              />
            </Media>
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-card">
            <Card className="card-notification shadow-none" style={{ maxWidth: '20rem' }}>
              <FalconCardHeader
                className="card-header"
                title={<div className="font-weight-semi-bold my-1">Thông báo</div>}
                titleTag="h5"
                light={false}
              >
                {parseInt(UnreadNotificationQuantity) > 0 && (
                  <Label check className="align-middle">
                    <Input
                      type="checkbox"
                      className="align-middle"
                      style={{ marginTop: '0.2rem' }}
                      onChange={({ target }) => {
                        setFilterUnread(target.checked);
                      }}
                    />
                    <span className="align-middle">Chưa xem</span>
                  </Label>
                )}
              </FalconCardHeader>
              {!!isLoading && (
                <div className="bg-light">
                  <Loader />
                </div>
              )}
              {!isLoading && (
                <div className="smooth-scroll" style={{ maxHeight: '500px', overflow: 'auto' }}>
                  <ListGroup flush className="font-weight-normal fs--1">
                    {isIterableArray(TodayNotification) && (
                      <Fragment>
                        <div className="list-group-title">Hôm nay</div>
                        {TodayNotification.map((Item, Idx) => (
                          <ListGroupItem key={Idx} onClick={handleToggle}>
                            <NotificationItem {...Item} className="notification-flush" />
                          </ListGroupItem>
                        ))}
                      </Fragment>
                    )}

                    {isIterableArray(ShowNotification) && (
                      <Fragment>
                        {isIterableArray(TodayNotification) && <div className="list-group-title">Cũ hơn</div>}
                        {ShowNotification.map((Item, Idx) => (
                          <ListGroupItem key={Idx} onClick={handleToggle}>
                            <NotificationItem {...Item} className="notification-flush" />
                          </ListGroupItem>
                        ))}
                      </Fragment>
                    )}
                  </ListGroup>
                </div>
              )}
              {/* không có notification nào cả */}
              {!isLoading && isEmpty() && (
                <div className="text-center">
                  <img alt="Empty" className="w-75" src={require('../../assets/img/generic/nothing-here.png')} />
                  <div className="text-muted m-5 mt-n3">
                    <small>Không có thông báo nào, danh sách trống không à...</small>
                  </div>
                </div>
              )}
              {!isLoading && (
                <div className="card-footer text-center border-top-0" onClick={handleToggle}>
                  <Link className="card-link d-block" to="/notifications">
                    Xem tất cả
                  </Link>
                </div>
              )}
            </Card>
          </DropdownMenu>
        </Dropdown>
      )}
    </Fragment>
  );
};

export default NotificationDropdown;
