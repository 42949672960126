import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, CustomInput, DropdownToggle, UncontrolledDropdown, Media } from 'reactstrap';
import Avatar from '../../components/common/Avatar';
import AppContext from '../../context/Context';

const ProfileDropdown = () => {
  const { isFluid, setIsFluid, isDark, setIsDark, User, setIsGuest } = useContext(AppContext);

  const handleLogout = () => {
    // logout local user and send logout request
    User.logout().then(() => {
      // set guest
      setIsGuest(true);
    });
  };

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav className="pr-0">
        <Media style={{ transition: 'all 0.3s' }} className="rounded-capsule bg-light p-1 pr-2">
          <Avatar src={User.Avatar} size="m" />
          <Media
            body
            className="ml-2"
            style={{
              lineHeight: '1.5rem',
              whiteSpace: 'nowrap',
              maxWidth: '100px',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {User.Name}
          </Media>
        </Media>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2 px-2">
          <DropdownItem tag={Link} to="/profile">
            Tài Khoản Cá Nhân
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            <CustomInput
              type="switch"
              id="dark"
              name="dark"
              label={<span style={{ fontSize: '1rem' }}>Dark Mode</span>}
              checked={isDark}
              onChange={({ target }) => setIsDark(target.checked)}
            />
          </DropdownItem>
          <DropdownItem>
            <CustomInput
              type="switch"
              id="fluid"
              name="fluid"
              label={<span style={{ fontSize: '1rem' }}>Rộng Toàn Trang</span>}
              checked={isFluid}
              onChange={({ target }) => setIsFluid(target.checked)}
            />
          </DropdownItem>
          <DropdownItem divider />
          {/* <DropdownItem tag={Link} to="/pages/settings">
            Settings
          </DropdownItem> */}
          <DropdownItem onClick={handleLogout}>Đăng Xuất</DropdownItem>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ProfileDropdown;
