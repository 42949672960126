import React, { useState } from 'react';
import { Card, Button } from 'reactstrap';

import ModalFullBoard from './ModalFullBoard';
import TabBoard from './TabBoard';

const LeaderBoard = () => {
  const [IsModalOpen, setIsModalOpen] = useState(false);
  return (
    <Card className="pt-1 shadow-sm mb-3">
      <TabBoard LoadMore={false} TabId="widget" />
      <div className="p-3 pt-0">
        <Button onClick={() => setIsModalOpen(true)} color="info" className="btn-block">
          Xem Tất Cả
        </Button>
        <ModalFullBoard {...{ IsModalOpen, setIsModalOpen }} />
      </div>
    </Card>
  );
};

export default LeaderBoard;
