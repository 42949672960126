import React, { useContext, useEffect, useRef } from 'react';
import { Collapse, Nav, Navbar } from 'reactstrap';
import Scrollbar from 'react-scrollbars-custom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import Logo from './Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import AppContext from '../../context/Context';
import Flex from '../../components/common/Flex';
import routes from '../../routes';
import { navbarBreakPoint } from '../../config';

const NavbarVertical = () => {
  const navBarRef = useRef(null);

  const {
    // isFluid,
    // setIsFluid,
    isRTL,
    // setIsRTL,
    isDark,
    // setIsDark,
    showBurgerMenu,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed
  } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  //Control Component did mount and unmount of hover effect
  if (isNavbarVerticalCollapsed) {
    HTMLClassList.add('navbar-vertical-collapsed');
  }

  useEffect(() => {
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };

  return (
    <Navbar expand={navbarBreakPoint} className="navbar-vertical navbar-glass" light>
      <Flex align="center">
        <ToggleButton
          isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
          setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
        />
        <Logo Branch="Pety" at="navbar-vertical" width={40} />
      </Flex>

      <Collapse
        navbar
        isOpen={showBurgerMenu}
        // className="bg-200"
        style={{
          ...(isDark
            ? { backgroundColor: '#232e3c' }
            : { backgroundImage: 'linear-gradient(-45deg, #FDC830, #ffa000)' }),
          ...{ borderRadius: '0.375rem', paddingTop: 0 }
        }}
        innerRef={navBarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          clearTimeout(time);
          HTMLClassList.remove('navbar-vertical-collapsed-hover');
        }}
      >
        <Scrollbar
          style={{ height: 'calc(100vh - 67px)', display: 'block' }}
          rtl={isRTL}
          trackYProps={{
            renderer(props) {
              const { elementRef, ...restProps } = props;
              return <span {...restProps} ref={elementRef} className="TrackY" />;
            }
          }}
        >
          <Nav navbar vertical className="p-2">
            <NavbarVerticalMenu routes={routes} />
          </Nav>

          <div className={`settings p-2 px-${navbarBreakPoint}-2`}>
            <hr className="border-100 my-3" />
            <a href="https://apps.apple.com/vn/app/pety/id1506375124" size="md" className="btn btn-secondary btn-block">
              <FontAwesomeIcon size="lg" icon={faApple} className="mr-3" />
              App Store
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.pety.moon"
              size="md"
              className="btn btn-secondary btn-block"
            >
              <FontAwesomeIcon size="1x" icon={faGooglePlay} className="mr-3" />
              Google Play
            </a>
            {/* <hr className="border-100 my-3" /> */}
            <div className="mt-3 line-height-2 text-center">
              <span className="text-muted" style={{ fontSize: '90%' }}>
                © Pety 2019. Toàn bộ bản quyền thuộc về Pety Technology JSC.
              </span>
            </div>
          </div>
        </Scrollbar>
      </Collapse>
    </Navbar>
  );
};

export default NavbarVertical;
