import React, { useState } from 'react';
import { Fade } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import lo from 'lodash';

import J from '../../lib/Jalio';
import _ from '../../lib/Var';
import { apis, Config } from '../../lib/Config';
import { isIterableArray } from '../../helpers/utils';
import PostSimple from '../../components/post/PostSimple';
import PostProvider from '../../provider/PostProvider';
import Loader from '../../components/common/Loader';

const EventPost = () => {
  const [Posts, setPosts] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const [LoadedAll, setLoadedAll] = useState(false);
  const [Lmstart, setLmstart] = useState(0);

  const loadPosts = () => {
    if (IsLoading || LoadedAll) return;

    setIsLoading(true);
    J.post(apis.newfeed, { Hastag: '#pety', lmstart: Lmstart }).then(res => {
      setIsLoading(false);

      const NewPosts = _.get(res, 'module.Posts', []);

      if (Array.isArray(NewPosts)) {
        // dispatch posts
        setPosts(lo.union(Posts, NewPosts));
        setLmstart(Lmstart + NewPosts.length);

        // check and update the limit-start
        if (NewPosts.length < Config.listLimit) {
          setLoadedAll(true);
        }
      }
    });
  };

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={() => {
        !IsLoading && !LoadedAll && loadPosts();
      }}
      threshold={500}
      hasMore={true}
      loader={!LoadedAll && <Loader key="pety-post-loader" />}
    >
      {isIterableArray(Posts) &&
        Posts.map(Post => {
          return (
            <PostProvider Post={Post} key={`event-post-${Post.PostId}`}>
              <Fade>
                <PostSimple />
              </Fade>
            </PostProvider>
          );
        })}
    </InfiniteScroll>
  );
};

export default EventPost;
