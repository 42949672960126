import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';

const PhotoGrid = () => {
  const [fades, setFades] = useState([]);
  const [handler, setHandler] = useState(null);
  useEffect(() => {
    setHandler(setInterval(randomFade, 5000));

    return () => handler !== null && clearInterval(handler);
  }, []);

  const randomFade = () => {
    const numChange = rand(4, 9);
    setFades([...Array(numChange)].map(() => rand(0, 42)));
  };

  const rand = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  };

  const renderItem = idx => {
    return (
      <li className="avatar-grid-item" key={`photo-grid-item-${idx}`}>
        <div>
          <img src={users[idx].avatar} alt={users[idx].name} className="avatar-grid-img" />
          <img
            src={users[idx + 42].avatar}
            alt={users[idx + 42].name}
            className={Array.isArray(fades) && fades.includes(idx) ? 'fade avatar-grid-img' : 'avatar-grid-img'}
          />
        </div>
      </li>
    );
  };

  const overlay = () => (
    <div className="avatar-grid-overlay">
      <div className="container text-center text-light mt-7">
        <span>
          <span className="h1 font-weight-bold mx-2 text-light">hơn 18k</span>
          boss và
          <span className="h1 font-weight-bold mx-2 text-light">hơn 40k</span>
          sen đang hiện diện trên Pety,
        </span>
        <p className="text-light mt-3">
          Nếu bạn yêu thú cưng thì <b>hãy tham gia chung với chúng mình ngay đi nào...</b>
        </p>
      </div>
    </div>
  );

  return (
    <div>
      <div className="container mt-9 mb-3">
        <div className="w-75 text-center mx-auto mb-5">
          <h2 className="font-size-md-down-3 font-weight-bold">Cộng đồng lớn và đáng yêu</h2>
          <p className="font-weight-normal text-muted">
            Hơn 40.000 người yêu thú cưng từ 6 quốc gia. Và tăng lên nhanh chóng mỗi ngày
          </p>
        </div>
      </div>
      <LazyLoad>
        <div className="avatar-grid-wrap">
          <ul className="avatar-grid">{[...Array(42)].map((el, idx) => renderItem(idx))}</ul>
          {overlay()}
        </div>
      </LazyLoad>
    </div>
  );
};

const users = [
  {
    name: 'Khánh Phương',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.khanh-phuong-2.60f6dc3f70c3c.webp'
  },
  {
    name: 'Ngọc Tuyết',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.22444.60fa44fb18931.1627014395.JPEG.webp'
  },
  {
    name: 'Hoàng Minh',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.hoang-minh-11.60fa42e2eb4a8.webp'
  },
  {
    name: 'Joce',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/22119.1626837480.3h_ql3z2_400x400.jpg.webp'
  },
  {
    name: 'Quỳnh Nga',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.quynh-nga-4.60fa3bd932aaf.webp'
  },
  {
    name: 'Ph Vu Thien My',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.ph-vu-thien-my.60fa3b0612ec6.webp'
  },
  {
    name: 'Thong Huynh',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.thong-huynh.60fa37b0994b2.webp'
  },
  { name: 'Sơn Ngọc', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.son-ngoc-2.60fa36fcba546.webp' },
  { name: 'Duy Tran', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.duy-tran-3.60fa36d26fd1e.webp' },
  {
    name: 'Minh Hiếu',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.minh-hieu-4.60fa261adcc85.webp'
  },
  { name: 'Lộc Thành', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.loc-thanh.60fa24ea5fdb6.webp' },
  {
    name: 'Kim Tuyền',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.kim-tuyen-4.60fa242f8503e.webp'
  },
  {
    name: 'Nhi Uyen Nguyen Trieu',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.nhi-uyen-nguyen-trieu.60fa235d526c1.webp'
  },
  {
    name: 'Hương Nguyễn',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.u60f7b25174da7.60f7b25174dbd.webp'
  },
  {
    name: 'Hương Hương',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.huong-huong-2.60fa16d6698fe.webp'
  },
  {
    name: 'Thu Hà',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.thu-ha-13.60f8186c9e547.webp'
  },
  {
    name: 'Cong Le',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.22425.60fa15bbcf634.1627002299.JPEG.webp'
  },
  { name: 'Phạm Hoàn', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.pham-hoan.60fa04d166f3b.webp' },
  { name: 'Cường Híp', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.cuong-hip.60fa03867f0be.webp' },
  {
    name: 'Nguyễn Uyển Trang',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.nguyen-uyen-trang.60f711f3df628.webp'
  },
  {
    name: 'Trang Dương',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.trang-duong-2.60f9fb6c1266a.webp'
  },
  {
    name: 'Cô Út Nhỏ',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.co-ut-nho.60f7c95e5825f.webp'
  },
  {
    name: 'Nguyễn Hữu Dũng',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.nguyen-huu-dung.60f9e342a347c.webp'
  },
  { name: 'Dũng', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.dung-68.60f9cca45e7f6.webp' },
  {
    name: 'Phạm Trương Quỳnh Như',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.pham-truong-quynh-nhu.60f6d86a44439.webp'
  },
  {
    name: 'My Duyen Tran Thi',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.my-duyen-tran-thi.60f9c7e79d6cc.webp'
  },
  {
    name: 'Minh Hoa',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.22415.60f9c6c65415f.1626982086.jpg.webp'
  },
  {
    name: 'Ngọc Trần',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.ngoc-tran-9.60f9b21adb366.webp'
  },
  { name: 'Dv Pea', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.dv-pea.60f9ae38ea94b.webp' },
  {
    name: 'Huệ Phương',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.hue-phuong.60f7abd379996.webp'
  },
  {
    name: 'Thùy Dương',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.22411.60f9acea10f6d.1626975466.JPEG.webp'
  },
  {
    name: 'Trần Phương Nhung',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.tran-phuong-nhung.60f9a9bba4f9b.webp'
  },
  {
    name: 'Bạch Lộ Hoa',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.22409.60f9a3e6d978a.1626973158.JPEG.webp'
  },
  {
    name: 'Lê Thị Cẩm Nhung',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.le-thi-cam-nhung.60f74519a0654.webp'
  },
  {
    name: 'Nguyễn Thuỳ Trang',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.nguyen-thuy-trang-3.60f99c8276907.webp'
  },
  {
    name: 'Trang Nguyen',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.22097.60f98e06f0d03.1626967558.jpg.webp'
  },
  {
    name: 'Nguyễn Thị Tố Trinh',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.nguyen-thi-to-trinh.60f996cbd3710.webp'
  },
  {
    name: 'my linh',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.22404.60f9935c9263b.1626968924.JPEG.webp'
  },
  {
    name: 'Đức An Nguyễn',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.duc-an-nguyen.60f991e7e05ed.webp'
  },
  {
    name: 'Trân Trân',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.tran-tran-8.60f990e93c0a1.webp'
  },
  {
    name: 'Nhật Cường',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.22401.60f99d9dc7fed.1626971549.JPEG.webp'
  },
  { name: 'Anh Thơ', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.anh-tho.60f98e0ebc856.webp' },
  {
    name: 'Kem kem poodle',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.22398.60f9900215185.1626968066.JPEG.webp'
  },
  {
    name: 'Thu Huyền',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.thu-huyen-7.60f81960d9539.webp'
  },
  {
    name: 'Bùi Bích Hà',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.bui-bich-ha.60f98a57d1d65.webp'
  },
  {
    name: 'Bơ',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.22395.60f989c4a19df.1626966468.JPEG.webp'
  },
  { name: 'Ngọc Sang', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.ngoc-sang.60f9887b008d2.webp' },
  {
    name: 'Thiên Hương',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.u60f9860b90192.60f9860b901a9.webp'
  },
  { name: 'Kim Hằng', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.kim-hang-3.60f9850dd925b.webp' },
  {
    name: 'Linh Nguyễn',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.linh-nguyen-28.60f982ec8557d.webp'
  },
  {
    name: 'Nguyễn Thùy Dung',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.nguyen-thuy-dung-3.60f81ef14f7f0.webp'
  },
  { name: 'Van Anh', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.van-anh-23.60f7db33ce251.webp' },
  {
    name: 'Huyền Jessica',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.huyen-jessica.60f82016cf17c.webp'
  },
  {
    name: 'Trấn Thành',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.tran-thanh-19.60f980d01ae61.webp'
  },
  {
    name: 'Đô Đô',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.22386.60f98124269b3.1626964260.JPEG.webp'
  },
  { name: 'Thư Hoàng', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.thu-hoang.60f9809c51270.webp' },
  {
    name: 'Quang Đồng Nguyễn',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.quang-dong-nguyen.60f97f0a229d7.webp'
  },
  {
    name: 'An Tường',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.an-tuong.60f818adaa6b3.webp'
  },
  {
    name: 'Thục Trâm',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.22382.60f97eb9e46b2.1626963641.jpg.webp'
  },
  {
    name: 'Nguyễn Phùng Bảo Khang',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.22381.60f97a2ca0113.1626962476.JPEG.webp'
  },
  {
    name: 'Ngân Nhok Nhok',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.ngan-nhok-nhok.60f9796ec68b9.webp'
  },
  {
    name: 'Hai Nguyen Duy',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.hai-nguyen-duy.60f97966daefb.webp'
  },
  {
    name: 'Như Nguyễnn',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.nhu-nguyenn.60f974d950244.webp'
  },
  {
    name: 'MH Anna',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.mh-anna.60f7b4e187546.webp'
  },
  {
    name: 'Dương Dương',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.duong-duong-2.60f96f4d84de9.webp'
  },
  {
    name: 'Echam Thuỷ Thương',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.22080.60f6f0464de19.1626796102.jpg.webp'
  },
  {
    name: 'Diepngoc Nguyen',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.diepngoc-nguyen.60f7b332692c0.webp'
  },
  { name: 'Trần Toại', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.tran-toai.60f96d6d5e60f.webp' },
  {
    name: 'Trần Như Thảo',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.tran-nhu-thao.60f96caaebbe7.webp'
  },
  {
    name: 'Vũ Thịnh',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.22369.60f98e202ac9d.1626967584.JPEG.webp'
  },
  {
    name: 'Dung Dung',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.dung-dung.60f79278759eb.webp'
  },
  {
    name: 'Bull Hí',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.22132.60f7a051abf88.1626841169.jpg.webp'
  },
  {
    name: 'Bảo Trân',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.u60f7e4eda3c2e.60f7e4eda3c45.webp'
  },
  {
    name: 'Nguyễn Quỳnh Thanh Ngân',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.nguyen-quynh-thanh-ngan.60f80a5116f90.webp'
  },
  {
    name: 'Tieuho Chau',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.tieuho-chau.60f9644c13437.webp'
  },
  {
    name: 'Trang Trang',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.u60f95f8e2aeb7.60f95f8e2aecf.webp'
  },
  {
    name: 'Huỳnh Khánh Vân',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.huynh-khanh-van.60f95e1a3504b.webp'
  },
  {
    name: 'Đỗ Thanh Huyền',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.do-thanh-huyen.60f959cd40c8e.webp'
  },
  { name: 'Moon Nhi', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.moon-nhi.60f959c084edc.webp' },
  { name: 'Bảo Bình', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.bao-binh-7.60f9558a3f6e6.webp' },
  {
    name: 'Vi Hoàng Yến Nhi',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.vi-hoang-yen-nhi.60f70033e3ef8.webp'
  },
  {
    name: 'An N.Bảo Ngọc',
    avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.an-nbao-ngoc.60f8058dbd409.webp'
  },
  { name: 'Anh Anna', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.anh-anna.60f9535522a98.webp' },
  { name: 'Mai Lan', avatar: 'https://d3u2xd68tejot5.cloudfront.net/avatar/thumbs/av.mai-lan-3.60f822c1d6410.webp' }
];

export default PhotoGrid;
