import React, { Fragment, useState, useEffect } from 'react';
import PeopleToFollow from '../../widget/people/PeopleToFollow';
import HashtagTrending from '../../widget/hashtag/HashtagTrending';
import PetBirthdayNotice from '../../widget/newfeed/PetBirthdayNotice';
import Sticky from 'react-stickynode';
import { isIterableArray } from '../../helpers/utils';
import { apis } from '../../lib/Config';
import J from '../../lib/Jalio';
import _ from '../../lib/Var';
import MobileOffer from './MobileOffer';

const FeedSideBar = () => {
  const [Pets, setPets] = useState([]);

  useEffect(() => {
    J.get(apis.birthday).then(res => {
      setPets(_.get(res, 'module.Birthdays'));
    });
  }, []);

  return (
    <Fragment>
      {isIterableArray(Pets) && <PetBirthdayNotice Pets={Pets} />}
      <MobileOffer />
      <HashtagTrending />
      <Sticky top="#navbar">
        <PeopleToFollow />
      </Sticky>
    </Fragment>
  );
};

export default FeedSideBar;
