import React from 'react';
import { CSSTransition } from 'react-transition-group';

import './anim.css';

const Fade = props => {
  const { children } = props;
  return (
    <CSSTransition timeout={400} {...props} classNames="fade-item">
      {children}
    </CSSTransition>
  );
};

export default Fade;
