import React from 'react';
import FbImageLibrary from 'react-fb-image-grid';
import LazyLoad from 'react-lazyload';

const ImageLightbox = ({ Photos }) => {
  return (
    <div className="m-n3">
      <LazyLoad>
        <FbImageLibrary images={Photos} hideOverlay={true} renderVideoOverlay={() => <VideoOverlay />} />
      </LazyLoad>
    </div>
  );
};

const VideoOverlay = () => {
  return (
    <div
      style={{
        width: '3rem',
        height: '3rem',
        borderRadius: '50%',
        position: 'relative',
        left: '50%',
        top: '50%',
        marginLeft: '-1.5rem',
        marginTop: '-1.5rem',
        border: 'solid 2px rgba(255, 255, 255, 0.3)',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        opacity: '0.6'
      }}
    >
      <div
        style={{
          borderTop: '12px solid transparent',
          borderBottom: '12px solid transparent',
          borderLeft: '20px solid #ffa000',
          height: 0,
          width: 0,
          position: 'absolute',
          left: '50%',
          top: '50%',
          marginLeft: '-8px',
          marginTop: '-12px'
        }}
      />
    </div>
  );
};

export default ImageLightbox;
