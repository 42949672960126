import React, { useState, useContext } from 'react';
import { FormGroup, Button, Col, Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { Config, apis } from '../../lib/Config';
import Message from '../../lib/Message';
import J from '../../lib/Jalio';
import _ from '../../lib/Var';
import AppContext from '../../context/Context';

const SocialAuthButtons = ({ setRedirect }) => {
  const [isFbLogin, setIsFbLogin] = useState(false);
  const [isGgLogin, setIsGgLogin] = useState(false);
  const { setIsGuest, User } = useContext(AppContext);

  const Params = {};
  // refer and other param be stick to login/signup
  if (window.location.hash.length > 1) {
    const hash = new URLSearchParams(window.location.hash.substr(1));

    hash.forEach(function(Val, Key) {
      Params[Key] = Val;
    });
  }

  const onGoogleLogin = result => {
    if (_.getString(result, 'profileObj.googleId', '').length === 0) return;

    setIsGgLogin(true);

    J.post(apis.loginGg, {
      ...{
        Id: _.getString(result, 'profileObj.googleId', ''),
        Email: _.getString(result, 'profileObj.email', ''),
        Name: _.getString(result, 'profileObj.name', ''),
        FirstName: _.getString(result, 'profileObj.givenName', ''),
        LastName: _.getString(result, 'profileObj.familyName', ''),
        Avatar: _.getString(result, 'profileObj.imageUrl')
      },
      ...Params
    })
      .then(
        res => {
          if (_.get(res, 'module.user.UserId', false)) {
            //save data to local-storage
            User.save(_.get(res, 'module.user', {}));

            // save fb token
            User.set('ggAccessToken', _.getString(result, 'idToken', ''));

            // redirect
            setIsGuest(User.isGuest());
            setRedirect(!User.isGuest());
          } else if (_.get(res, 'messages', false)) {
            Message.show(_.get(res, 'messages'));
          }
        },
        error => {
          console.log(error);
        }
      )
      .finally(() => {
        setIsGgLogin(false);
      });
  };

  const onFacebookLogin = result => {
    if (_.getString(result, 'id', '').length === 0) return;

    setIsFbLogin(true);

    J.post(apis.loginFb, {
      ...{
        Id: _.getString(result, 'id', ''),
        Name: _.getString(result, 'name', ''),
        FirstName: _.getString(result, 'first_name', ''),
        LastName: _.getString(result, 'last_name', ''),
        Avatar: _.getString(result, 'picture.data.url'),
        Email: _.getString(result, 'email')
      },
      ...Params
    })
      .then(res => {
        if (_.get(res, 'module.user.UserId', false)) {
          //save data to local-storage
          User.save(_.get(res, 'module.user', {}));

          // save fb token
          User.set('fbAccessToken', _.get(result, 'accessToken'));

          // redirect
          setIsGuest(User.isGuest());
          setRedirect(!User.isGuest());
        } else if (_.get(res, 'messages', false)) {
          Message.show(_.get(res, 'messages'));
        }
      })
      .finally(() => {
        setIsFbLogin(false);
      });
  };

  return (
    <FormGroup className="mb-0">
      <Row noGutters>
        <Col sm={6} className="pr-sm-1">
          <GoogleLogin
            clientId={Config.googleClientId}
            render={renderProps => (
              <Button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                color="outline-google-plus"
                size="md"
                block
                className="mt-2"
              >
                <FontAwesomeIcon size="sm" icon={['fab', 'google-plus-g']} transform="grow-8" className="mr-2" />
                google
                {isGgLogin && <Spinner size="sm" as="span" variant="primary" className="float-right" />}
              </Button>
            )}
            buttonText="Login"
            onSuccess={onGoogleLogin}
            onFailure={onGoogleLogin}
            cookiePolicy={'single_host_origin'}
          />
        </Col>
        <Col sm={6} className="pl-sm-1">
          <FacebookLogin
            appId={Config.facebookAppId}
            callback={onFacebookLogin}
            disableMobileRedirect={true}
            fields="name,email,picture"
            render={({ onClick }) => (
              <Button onClick={() => onClick()} color="outline-facebook" size="md" block className="mt-2">
                <FontAwesomeIcon size="sm" icon={['fab', 'facebook-square']} transform="grow-8" className="mr-2" />
                facebook
                {isFbLogin && <Spinner size="sm" as="span" variant="primary" className="float-right" />}
              </Button>
            )}
          />
        </Col>
      </Row>
    </FormGroup>
  );
};

export default SocialAuthButtons;
