import cat from './cat/cat.js';
import mars from './mars/mars.js';
// import puny from './puny/puny.js';
import push from './push/push.js';
import bird from './bird/bird.js';
import dog from './dog/dog.js';
import fox from './fox/fox.js';
import troll from './troll/troll.js';
import rabbit from './rabbit/rabbit.js';
import shiba from './shiba/shiba.js';

export default [cat, shiba, mars, bird, push, rabbit, fox, dog, troll];
