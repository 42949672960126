import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Loader from '../../components/common/Loader';
import { isIterableArray } from '../../helpers/utils';
import J from '../../lib/Jalio';
import _ from '../../lib/Var';
import { apis } from '../../lib/Config';
import { Link } from 'react-router-dom';
import corner1 from '../../assets/img/illustrations/corner-1.png';
import Background from '../../components/common/Background';
import { faSlackHash } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HashtagTrending = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const [Loaded, setLoaded] = useState(false);
  const [Trending, setTrending] = useState([]);

  useEffect(() => {
    if (IsLoading || Loaded) return;
    J.get(apis.hashtag.trending)
      .then(res => {
        setTrending(_.get(res, 'module.Trending', []));
      })
      .finally(() => {
        setIsLoading(false);
        setLoaded(true);
      });

    // eslint-disable-next-line
  }, []);

  return (
    <Card className="mb-3 shadow-sm">
      <CardHeader className="position-relative" style={{ borderBottom: 'solid 1px rgba(0,0,0,.1)' }}>
        <Background image={corner1} className="p-card bg-card" style={{ zIndex: 0 }} />
        <h5 className="m-0 font-weight-semi-bold text-500">
          <FontAwesomeIcon icon={faSlackHash} className="mr-3" size="lg" />
          Thịnh Hành
        </h5>
      </CardHeader>
      <CardBody>
        {IsLoading ? (
          <Loader />
        ) : (
          isIterableArray(Trending) &&
          Trending.map((El, Idx) => (
            <Link
              className="my-1 font-weight-semi-bold"
              key={`trending-hashtag-${Idx}`}
              to={`/hashtag/${El.Hastag.replace('#', '')}`}
            >
              <span className="text-nowrap">{El.Hastag}</span>
              {` `}
            </Link>
          ))
        )}
      </CardBody>
    </Card>
  );
};

export default HashtagTrending;
