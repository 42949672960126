import React, { useContext } from 'react';
import Typed from 'react-typed';
import { Row, Col, Media } from 'reactstrap';
import classNames from 'classnames';

import cover from '../../assets/img/landing/cover.webp';
import coverDark from '../../assets/img/landing/cover-dark.webp';
import { ReactComponent as Wawe } from '../../assets/img/generic/wave-9-bottom.svg';
import { ReactComponent as AppleIcon } from '../../assets/img/landing/apple.svg';
import { ReactComponent as GooglePlayIcon } from '../../assets/img/landing/google-play.svg';

import Section from '../../components/common/Section';
import AppContext from '../../context/Context';
import Account from './Account';

const Hero = () => {
  const { isDark } = useContext(AppContext);
  return (
    <div className="position-relative">
      <Section
        className="py-0 overflow-hidden"
        title="Pety cover"
        image={isDark ? coverDark : cover}
        position="center bottom"
      >
        <Row className="justify-content-center pt-8">
          <Col lg={8} className="pb-md-10 pt-md-6 pt-sm-3 pb-sm-5 text-center text-xl-left">
            <h1
              className="d-sm-h3 pb-3 pb-md-5 mx-2 text-white font-weight-bold"
              style={{ lineHeight: '1.4', textShadow: '1px 2px 3px rgba(0, 0, 0, 0.36)' }}
            >
              Ứng dụng hữu ích
              <br />
              cho người yêu
              <Typed
                strings={['thú cưng', 'mèo', 'chó', 'hamster', 'trăn', 'rùa', 'chim']}
                typeSpeed={60}
                backSpeed={80}
                className="font-weight-bolder ml-2"
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.16)' }}
                loop
              />
            </h1>
            <a
              className="btn btn-lg btn-light w-75 w-md-auto shadow font-weight-semi-bold border-2x text-dark rounded-lg btn-lg mt-3 py-1 px-2 mx-2"
              href="https://apps.apple.com/vn/app/pety/id1506375124"
            >
              <Media style={{ lineHeight: 1 }}>
                <AppleIcon style={{ width: '36px', height: '36px' }} className="mr-2 my-1" />
                <Media body className="text-left">
                  <p className="small m-0">Tải miễn phí</p>
                  <p className="h4 m-0 font-weight-bold">App Store</p>
                </Media>
              </Media>
            </a>
            <a
              className="btn btn-lg btn-light w-75 w-md-auto shadow border-2x rounded text-dark font-weight-semi-bold mt-3 py-1 px-2 mx-2"
              href="https://play.google.com/store/apps/details?id=com.pety.moon"
            >
              <Media style={{ lineHeight: 1 }}>
                <GooglePlayIcon style={{ width: '36px', height: '36px' }} className="mr-2 my-1" />
                <Media body className="text-left">
                  <p className="small m-0">Tải miễn phí</p>
                  <p className="h4 m-0 font-weight-bold">Google Play</p>
                </Media>
              </Media>
            </a>
          </Col>
          <Col className="pb-10">
            <Account />
          </Col>
        </Row>
      </Section>
      <figure className="position-absolute r-0 b-0 l-0 m-0">
        <Wawe className={classNames('w-100 h-100 svg', { dark: isDark })} />
      </figure>
    </div>
  );
};

export default Hero;
