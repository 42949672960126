import React, { useContext, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import PostNormal from '../../components/post/PostNormal';
import PostNewPet from '../../components/post/PostNewPet';
import { PostContext } from '../../context/Context';
import _ from '../../lib/Var';
import m from '../../lib/Moment';

const Post = () => {
  const { PostTypeId, Content, PostedAt, PostedByName, PostUrl, Photos, Pets } = useContext(PostContext);
  // console.log(m.unix(PostedAt).format());
  return (
    <Fragment>
      <Helmet>
        <title>{Content}</title>
        <meta name="keywords" content="pety,mèo,chó,mạng xã hội,thú cưng,mang xa hoi,thu cung,meo,cho,ung dung" />
        <meta name="author" content={PostedByName} />
        <meta property="og:description" content={Content} />
        <meta name="googlebot" content="noarchive" />
        <meta name="robots" content="noarchive" />
        <meta property="og:published_time" content={m.unix(PostedAt).format()} />
        <meta property="og:url" content={`https://pety.vn/post/${PostUrl}`} />
        {parseInt(PostTypeId) === 1 && !!Photos && !!Photos[0] && (
          <meta property="og:image:url" content={_.get(Photos[0], 'FileThumbnail')} />
        )}
        {parseInt(PostTypeId) === 21 && !!Pets && !!Pets[0] && (
          <meta property="og:image:url" content={_.get(Pets[0], 'Avatar')} />
        )}
        <meta property="og:type" content="article" />
        <meta name="generator" content="Pety JSC" />
        <meta property="article:publisher" content="https://pety.vn" />
        <meta property="og:site_name" content="Pety.vn" />
        <meta property="og:locale" content="vi_VN" />
        <meta property="og:rich_attachment" content="true" />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="480" />
      </Helmet>
      {parseInt(PostTypeId) === 1 && <PostNormal />}
      {parseInt(PostTypeId) === 21 && <PostNewPet />}
    </Fragment>
  );
};

export default Post;
