import React, { useState, useContext, Fragment } from 'react';
import { isMobile } from 'react-device-detect';

import { PostContext } from '../../context/Context';
import _ from '../../lib/Var';

import PopoverCommentPeople from './PopoverCommentPeople';
import ModalCommentPeople from './ModalCommentPeople';

const CountText = ({ Count, PostId, onClick }) => {
  return (
    <a className="text-700 pl-1" href="#!" id={`post-comment-${PostId}`} onClick={() => onClick()}>
      {` ${_.round(Count)} bình luận `}
    </a>
  );
};

const CountComment = () => {
  const { PostId, Comment } = useContext(PostContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Fragment>
      {Comment > 0 && (
        <Fragment>
          •
          <CountText onClick={() => setIsModalOpen(true)} PostId={PostId} Count={Comment} />
          <ModalCommentPeople isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
          {!isMobile && <PopoverCommentPeople Target={`post-comment-${PostId}`} />}
        </Fragment>
      )}
    </Fragment>
  );
};

export default CountComment;
