import React, { useState, useContext } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { PostContext } from '../../context/Context';
import Loader from '../common/Loader';
import Member from './MemberSimple';
import { Config } from '../../lib/Config';
import _ from '../../lib/Var';

const PopoverCommentPeople = ({ Target }) => {
  const { PostId, CommentPeople, CommentPeopleCount, loadCommentPeople } = useContext(PostContext);

  const [People, setPeople] = useState(CommentPeople);
  const [Total, setTotal] = useState(CommentPeopleCount);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const toggle = () => {
    !isOpen && People.length === 0 && loadPeople();
    setIsOpen(!isOpen);
  };

  const loadPeople = () => {
    setIsLoading(true);
    loadCommentPeople((People, Total) => {
      setPeople(People.slice(0, Config.shortListLimit));
      setTotal(Total);
      setIsLoading(false);
    });
  };

  return (
    <Popover
      delay={{ show: 400, hide: 100 }}
      isOpen={isOpen}
      innerClassName="shadow"
      placement="bottom"
      trigger="hover"
      target={Target}
      toggle={toggle}
    >
      <PopoverBody className="pt-3 pl-3 pr-4">
        {!isLoading &&
          People.length > 0 &&
          People.map((Person, Idx) => (
            <Member {...{ ...Person, At: Person.CommentedAt }} key={`comment-post-${PostId}-people-${Idx}`} />
          ))}
        {!isLoading && Total > People.length && (
          <div className="ml-n2 mr-n3">
            <hr className="mt-0 mb-1" />
            <div className="text-500 d-block text-center">{`và ${_.round(Total - People.length)} người khác`}</div>
          </div>
        )}
        {isLoading && <Loader className="mx-6" />}
      </PopoverBody>
    </Popover>
  );
};

export default PopoverCommentPeople;
