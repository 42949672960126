import axios from 'axios';
import moment from 'moment';
import Store from './Store';
import Message from './Message';
import _ from './Var';
import Crypt from './Crypt';

// axios.defaults.withCredentials = true;

// thiết lập truy vấn có log thông tin ra hay không
const showLog = process.env.REACT_APP_DEV_MODE;
const showLogKey = 'data';
// const AppName = process.env.REACT_APP_NAME;
// const AppVersion = process.env.REACT_APP_VERSION;
// const ClientInfo = `${AppName}[${AppVersion}]`;

const Network = {
  get: function(uri, params) {
    return Store.exist(Store.key(uri, params)) ? Store.get(uri, params) : this._get(uri, params);
  },

  post: function(uri, params) {
    return Store.exist(Store.key(uri, params)) ? Store.get(uri, params) : this._post(uri, params);
  },

  key: function() {
    let str = '';
    for (var i = 0; i < arguments.length; i++) {
      str += JSON.stringify(arguments[i]);
    }
    return Crypt.sha1(str);
  },

  _get: async (uri, params) => {
    const data = await Network._axios('GET', uri, params);
    if (data !== false) {
      Store.push(Store.key(uri, params), data);
    }

    return data;
  },

  _post: async (uri, params) => {
    // save to store without token param
    const storeParams = Object.assign({}, params);

    // init params object
    if (typeof params == 'undefined') params = {};

    const data = await Network._axios('POST', uri, params);
    if (data !== false) {
      Store.push(Store.key(uri, storeParams), data);
    }

    return data;
  },

  // _token: async () => {
  //   // attacted async-storage token
  //   let token = await localStorage.getItem('token');

  //   if (token == null) {
  //     const json = await Network._axios('GET', Config.url + 'user');

  //     if ((token = _.get(json, 'module.token', false))) {
  //       localStorage.setItem('token', token);
  //     }
  //   }

  //   return token;
  // },

  _axios: async (method, url, params, configs) => {
    const start = moment().valueOf();
    // const data = new URLSearchParams();
    const data = new FormData();

    //transform form js-object to param-object
    for (let prop in params) {
      // skip loop if the property is from prototype
      if (!params.hasOwnProperty(prop)) continue;
      data.append(prop, params[prop]);
    }

    if (typeof configs == 'undefined' || configs == null)
      configs = {
        // 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        Accept: '*/*'
      };

    return axios({
      url,
      method,
      data,
      withCredentials: true,
      headers: configs
    }).then(
      res => {
        showLog &&
          console.log({
            method,
            uri: url,
            params: params,
            response: showLogKey.length > 0 ? _.get(res, showLogKey) : res,
            time: _.format(moment().valueOf() - start),
            size: _.size(res)
          });
        return res.data;
      },
      err => {
        showLog && console.log('_axios [' + method + '] ' + url, params, configs, err);
        Message.show({ type: 0, contentRaw: 'Network error. Please check your internet connection!' });
        return false;
      }
    );
  }
};

export default Network;
