import p1 from './p1.jpg';
import p2 from './p2.jpg';
import p3 from './p3.jpg';
import p4 from './p4.jpg';
import p5 from './p5.jpg';
import p6 from './p6.jpg';
import p7 from './p7.jpg';
import p8 from './p8.jpg';
import p9 from './p9.jpg';
import p10 from './p10.jpg';
import p11 from './p11.jpg';
import p12 from './p12.jpg';
import p13 from './p13.jpg';
import p14 from './p14.jpg';
import p15 from './p15.jpg';

const bg = [
  {
    file: p1,
    name: 'p1.jpg',
    color: '#787878',
    tag: 'Phá'
  },
  {
    file: p2,
    name: 'p2.jpg',
    color: '#787878',
    tag: 'Đói...'
  },
  {
    file: p3,
    name: 'p3.jpg',
    color: '#787878',
    tag: 'Hí hí'
  },
  {
    file: p4,
    name: 'p4.jpg',
    color: '#ffffff',
    tag: 'Sen Đâuu'
  },
  {
    file: p5,
    name: 'p5.jpg',
    color: '#787878',
    tag: 'Cái zì đấy'
  },
  {
    file: p6,
    name: 'p6.jpg',
    color: '#787878',
    tag: ':('
  },
  {
    file: p7,
    name: 'p7.jpg',
    color: '#787878',
    tag: 'Hè hè hè'
  },
  {
    file: p8,
    name: 'p8.jpg',
    color: '#787878',
    tag: 'So Sad!'
  },
  {
    file: p9,
    name: 'p9.jpg',
    color: '#787878',
    tag: 'Huh?'
  },
  {
    file: p10,
    name: 'p10.jpg',
    color: '#787878',
    tag: 'Nhớ sen'
  },
  {
    file: p11,
    name: 'p11.jpg',
    color: '#787878',
    tag: 'Hổng zui!'
  },
  {
    file: p12,
    name: 'p12.jpg',
    color: '#787878',
    tag: 'Wat?'
  },
  {
    file: p13,
    name: 'p13.jpg',
    color: '#787878',
    tag: 'Love...'
  },
  {
    file: p14,
    name: 'p14.jpg',
    color: '#ffffff',
    tag: 'Ngáo'
  },
  {
    file: p15,
    name: 'p15.jpg',
    color: '#787878',
    tag: 'Rìa lý?'
  }
];

export const getBgFromName = function(name) {
  return bg.filter(item => item.name === name).pop();
};

export default bg;
