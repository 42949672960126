import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import Avatar from '../common/Avatar';
import m from '../../lib/Moment';

import Angry from '../../assets/reacted/Angry.png';
import Haha from '../../assets/reacted/Haha.png';
import Like from '../../assets/reacted/Like.png';
import Love from '../../assets/reacted/Love.png';
import Sad from '../../assets/reacted/Sad.png';
import Wow from '../../assets/reacted/Wow.png';

const Reacted = {
  Angry,
  Haha,
  Like,
  Love,
  Sad,
  Wow
};

const MemberSimple = ({ AvatarThumb, Name, ProfileUrl, At, Type }) => (
  <Media className="pr-2">
    <div style={{ position: 'relative' }}>
      <Avatar size="xl" src={AvatarThumb} className="border rounded-circle" />
      {typeof Type !== 'undefined' && Type.length > 0 && (
        <img style={{ position: 'absolute', top: '20px', left: '20px' }} src={Reacted[Type]} alt="reacted" width="16" />
      )}
    </div>
    <Media body className="ml-2">
      <h6 className="mb-0 font-weight-semi-bold">
        <Link to={`/bud/${ProfileUrl}`}>{Name}</Link>
      </h6>
      <p className="text-muted text-sm">{m.unix(At).fromNow()}</p>
    </Media>
  </Media>
);

MemberSimple.propTypes = {
  Avatar: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  At: PropTypes.string.isRequired
};

export default MemberSimple;
