import React, { useState, useEffect, useContext } from 'react';
import { Card, CardHeader, CardBody, Form, FormGroup, Input, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';

import AppContext from '../../context/Context';
import _ from '../../lib/Var';
import Message from '../../lib/Message';
import withRedirect from '../../hoc/withRedirect';
import SocialAuthButtons from '../auth/SocialAuthButtons';

const Account = ({ setRedirect }) => {
  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoginin, setLogingIn] = useState(false);
  const { setIsGuest, User, setUser } = useContext(AppContext);

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    setLogingIn(true);
    await User.login(Email, Password).then(res => {
      if (_.get(res, 'messages', false) !== false) {
        Message.show(_.get(res, 'messages'));
      }
      setUser(User);
      setLogingIn(false);
    });
    setIsGuest(User.isGuest());
    setRedirect(!User.isGuest());
  };

  useEffect(() => {
    setIsDisabled(!Email || !Password);

    // eslint-disable-next-line
  }, [Email, Password]);

  return (
    <Card className="shadow">
      <CardHeader className="bg-white h5 py-2 m-0 font-weight-bold">Đăng Nhập</CardHeader>
      <CardBody className="bg-light p-3">
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Input
              type="text"
              bsSize="lg"
              value={Email}
              onChange={({ target }) => setEmail(target.value)}
              placeholder="Số Phone hoặc Email"
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="password"
              bsSize="lg"
              value={Password}
              onChange={({ target }) => setPassword(target.value)}
              placeholder="Mật khẩu"
            />
          </FormGroup>
          <button type="submit" disabled={isDisabled} className="btn btn-primary btn-block">
            Đăng Nhập
            {isLoginin && <Spinner size="sm" as="span" variant="primary" className="float-right" />}
          </button>
          <center className="py-2 text-muted small">hoặc đăng nhập bằng</center>
          <SocialAuthButtons setRedirect={setRedirect} />
          {/* <Row noGutters className="m-n1">
            <Col className="p-1">
              <button className="btn bg-facebook text-white btn-block">Facebook</button>
            </Col>
            <Col className="p-1">
              <button className="btn bg-danger text-white btn-block">Google</button>
            </Col>
          </Row> */}
          {/* <hr className="mb-1 mt-4" /> */}
          <center className="text-muted mt-3 small">
            <span className="bg-light px-2">Bạn chưa có tài khoản?</span>
          </center>
          <Link to="auth/signup" className="btn bg-light border btn-block mt-2">
            Đăng Ký Mới
          </Link>
        </Form>
      </CardBody>
    </Card>
  );
};

export default withRedirect(Account);
