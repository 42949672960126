import React, { useContext, Fragment, useState } from 'react';
import { Media, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import m from '../../lib/Moment';
import _ from '../../lib/Var';
import J from '../../lib/Jalio';

import AvatarComponent from '../common/Avatar';
import createMarkup from '../../helpers/createMarkup';
import ModalFavoComment from './ModalFavoComment';

import AppContext, { CommentContext, PostContext } from '../../context/Context';

const CommentText = ({ Comment, ReplyTo, ReplyToName, ReplyToUrl }) => {
  let CommentPart = null;
  // check the reply or not
  if (typeof Comment !== 'undefined' && parseInt(ReplyTo) > 0 && ReplyToName.length > 0 && Comment !== null) {
    CommentPart = Comment.split(`@${ReplyToName}`);
  }

  return Array.isArray(CommentPart) && CommentPart.length === 2 ? (
    <span className="ml-1 text-900">
      {parseLink(CommentPart[0])}@
      <Link to={`/bud/${ReplyToUrl}`} className="font-weight-semi-bold">{`${ReplyToName}`}</Link>
      {parseLink(CommentPart[1])}
    </span>
  ) : (
    <span className="ml-1 text-900" dangerouslySetInnerHTML={createMarkup(Comment)} />
  );
};

const parseLink = Text => {
  if (Text !== null) {
    const Link = J.linkify(Text);
    if (Link !== false) {
      const TextPart = Text.split(Link);
      return (
        <span>
          {TextPart[0]}
          <a href={Link}>{Link}</a>
          {TextPart[1]}
        </span>
      );
    }
  }
  return <span>{Text}</span>;
};

const Comment = ({ setReplyTo, setReplyToName }) => {
  const {
    PostCommentId,
    CommentedBy,
    ReplyTo,
    ReplyToName,
    Avatar,
    Favo,
    Favod,
    Avatar1,
    Commentor,
    Comment,
    CommentedAt,
    Sticker,
    Photo,
    PhotoUrl,
    toggleFavo,
    ProfileUrl
  } = useContext(CommentContext);

  const { delComment } = useContext(PostContext);
  const { User, isGuest } = useContext(AppContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShowDeleteConfirm, setIsShowDeleteConfirm] = useState(false);

  const isAuthor = parseInt(User.UserId) === parseInt(CommentedBy);

  const reply = (ReplyTo, ReplyToName) => {
    setReplyTo(ReplyTo);
    setReplyToName(ReplyToName);
  };

  return (
    <Media className="mt-3">
      <AvatarComponent
        src={typeof Avatar1 !== 'undefined' ? Avatar1 : Avatar}
        size="xl"
        className="rounded-circle border"
      />
      <Media body className="ml-2 fs--1">
        <div className="mb-1 bg-white shadow-sm d-inline-block rounded-soft p-2 position-relative">
          <Link className="font-weight-semi-bold mr-1" to={`/bud/${ProfileUrl}`}>
            {Commentor}
          </Link>
          <CommentText Comment={Comment} ReplyToUrl={ReplyTo} ReplyTo={ReplyTo} ReplyToName={ReplyToName} />

          {/* sticker đã được chọn khi comment */}
          {Sticker !== null && (
            <div>
              <img alt="sticker" src={Sticker} style={{ maxWidth: '5rem' }} />
            </div>
          )}

          {/* hình đã được upload khi comment */}
          {Photo !== null && typeof Photo !== 'undefined' && Photo !== '' && (
            <div className="rounded-soft overflow-hidden" style={{ maxWidth: '20rem' }}>
              <img alt="comment by using img" src={PhotoUrl} style={{ width: '100%' }} />
            </div>
          )}

          {/* số lượng favorite một comment */}
          {parseInt(Favo) > 0 && (
            <Fragment>
              <div
                className="rounded-capsule shadow-sm bg-white position-absolute d-inline-block px-1 font-weight-semi-bold"
                style={{ bottom: '-0.5rem', right: '1rem' }}
              >
                <a
                  href="#!"
                  className="hover-scale"
                  onClick={() => setIsModalOpen(true)}
                  style={{ color: '#ec407a', textDecoration: 'none' }}
                >
                  <FontAwesomeIcon icon="heart" /> <span className="text-700">{_.round(Favo)}</span>
                </a>
              </div>
              <ModalFavoComment isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
            </Fragment>
          )}
        </div>
        <div>
          {!isGuest && (
            <Fragment>
              <a
                href="#!"
                className={`px-1 ${parseInt(Favod) === 1 ? 'text-danger' : 'text-500'}`}
                onClick={() => toggleFavo()}
              >
                {parseInt(Favod) === 1 ? 'Bỏ thích' : 'Thích'}
              </a>
              <span className="text-500"> • </span>
              <a href="#!" className="px-1 text-500" onClick={() => reply(CommentedBy, Commentor)}>
                Trả lời
              </a>
              {isAuthor && (
                <Fragment>
                  <span className="text-500"> • </span>
                  <a href="#!" className="px-1 text-500" onClick={() => setIsShowDeleteConfirm(true)}>
                    Xóa
                  </a>
                  {/* delete confirm */}
                  <Modal
                    isOpen={isShowDeleteConfirm}
                    toggle={() => {
                      setIsModalOpen(!isShowDeleteConfirm);
                    }}
                  >
                    <ModalBody className="text-center text-700 pt-4">
                      <FontAwesomeIcon icon={faInfoCircle} size="3x" />
                      <p className="mt-3">Bạn muốn xóa bình luận này?</p>
                    </ModalBody>
                    <ModalFooter className="pt-1 justify-content-center">
                      <button
                        onClick={() => {
                          setIsShowDeleteConfirm(false);
                          delComment(PostCommentId);
                        }}
                        className="btn btn-primary"
                      >
                        <FontAwesomeIcon icon="trash-alt" className="mr-2" />
                        Xóa bình luận
                      </button>
                      <button onClick={() => setIsShowDeleteConfirm(false)} className="btn btn-outline-dark">
                        Không
                      </button>
                    </ModalFooter>
                  </Modal>
                </Fragment>
              )}
              <span className="text-500"> • </span>
            </Fragment>
          )}
          <span className="text-muted px-1">{m.unix(CommentedAt).fromNow()}</span>
        </div>
      </Media>
    </Media>
  );
};

export default Comment;
