import React from 'react';
import PropTypes from 'prop-types';
import { CardBody } from 'reactstrap';
import Tilt from 'react-tilt';
import LazyLoad from 'react-lazyload';

import createMarkup from '../../helpers/createMarkup';
import J from '../../lib/Jalio';
import ImageLightbox from './ImageLightbox';
import LinkPreview from './LinkPreview';
import Pets from './Pets';

import { getBgFromName } from '../../assets/pety/bg/bg.js';

const PostContent = ({ Content, Photos, Bg, Pets: TagPets }) => {
  const Url = J.linkify(Content.trim());
  return (
    <CardBody>
      {!Bg && (
        <div className="mb-4">
          <p dangerouslySetInnerHTML={createMarkup(Content)} />
          <Pets />
        </div>
      )}
      {!!Bg && !!TagPets && TagPets.length > 0 && (
        <div className="mb-4">
          <Pets />
        </div>
      )}
      {!!Bg && (
        <LazyLoad>
          <PostBackground {...{ Content, Bg }} />
        </LazyLoad>
      )}
      {!!Photos.length > 0 && <ImageLightbox Photos={Photos} />}
      {!!Url && <LinkPreview Url={Url} />}
      {/* {!!video && (
        <div className="rounded-soft overflow-hidden position-relative">
          <FalconPlyr {...video} />
        </div>
      )} */}
    </CardBody>
  );
};

const PostBackground = ({ Content, Bg }) => {
  return (
    <Tilt className="Tilt" options={{ max: 6, scale: 1.01 }}>
      <div
        className="text-center px-3 py-8 rounded mx-n3 my-n3"
        style={{
          backgroundImage: `url(${getBgFromName(Bg).file})`,
          backgroundSize: 'cover',
          backgroundPosition: 'bottom'
        }}
      >
        {!!Content && Content.length <= 32 && (
          <h1
            className="font-weight-semi-bold text-white text-shadow"
            dangerouslySetInnerHTML={createMarkup(Content)}
          />
        )}
        {!!Content && Content.length > 32 && Content.length <= 100 && (
          <h3
            className="font-weight-semi-bold text-justify text-white text-shadow"
            dangerouslySetInnerHTML={createMarkup(Content)}
          />
        )}
        {!!Content && Content.length > 100 && (
          <h5
            className="font-weight-semi-bold text-justify px-6 text-white text-shadow"
            dangerouslySetInnerHTML={createMarkup(Content)}
          />
        )}
      </div>
    </Tilt>
  );
};

PostContent.propTypes = {
  Content: PropTypes.string
};

export default PostContent;
