const Var = {
  get: function(obj, keys, dft) {
    keys = Array.isArray(keys) ? keys : keys.replace(/(\[(\d)\])/g, '.$2').split('.');
    if (typeof obj !== 'undefined' && !!obj) {
      obj = obj[keys[0]];
      if (keys.length > 1) {
        return this.get(obj, keys.slice(1), dft);
      }
    }

    return obj === undefined ? dft : obj;
  },

  getInt: function(obj, keys, dft) {
    let val = Var.get(obj, keys, dft);
    val = parseInt(val);
    return isNaN(val) ? dft : val;
  },

  getString: function(obj, keys, dft) {
    let val = Var.get(obj, keys, dft);
    if (val === null || typeof val === 'undefined') val = '';
    return val.toString();
  },

  find: function(arrObjs, key, val) {
    if (Array.isArray(arrObjs)) {
      for (let i = 0; i < arrObjs.length; i++) {
        if (Var.get(arrObjs[i], key) === val) return arrObjs[i];
      }
    }
  },

  findAll: function(arrObjs, key, val) {
    const Results = [];
    if (Array.isArray(arrObjs)) {
      for (let i = 0; i < arrObjs.length; i++) {
        if (Var.get(arrObjs[i], key) === val) Results.push(arrObjs[i]);
      }
    }

    return Results;
  },

  replace: function(arrObjs, Obj, key, val) {
    if (Array.isArray(arrObjs)) {
      for (let i = 0; i < arrObjs.length; i++) {
        if (Var.get(arrObjs[i], key) === val) {
          arrObjs[i] = Obj;
          return arrObjs;
        }
      }
    }
  },

  // định dạng kiểu số, tiền tệ
  format: function(number, n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
      num = parseInt(number).toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
  },

  round: function(number) {
    if (number > 1000000000) {
      return Math.floor(number / 1000000000) + 'B';
    } else if (number > 1000000) {
      return Math.floor(number / 1000000) + 'M';
    } else if (number > 1000) {
      return Math.floor(number / 1000) + 'K';
    }

    return number;
  },

  // tính kích thước trên RAM của một biến bất kì
  size: function(obj) {
    var bytes = 0;

    function sizeOf(obj) {
      if (obj !== null && obj !== undefined) {
        switch (typeof obj) {
          case 'number':
            bytes += 8;
            break;
          case 'string':
            bytes += obj.length * 2;
            break;
          case 'boolean':
            bytes += 4;
            break;
          default:
            var objClass = Object.prototype.toString.call(obj).slice(8, -1);
            if (objClass === 'Object' || objClass === 'Array') {
              for (var key in obj) {
                if (!obj.hasOwnProperty(key)) continue;
                sizeOf(obj[key]);
              }
            } else bytes += obj.toString().length * 2;
        }
      }
      return bytes;
    }

    function formatByteSize(bytes) {
      if (bytes < 1024) return bytes + ' bytes';
      else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + ' KiB';
      else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + ' MiB';
      else return (bytes / 1073741824).toFixed(3) + ' GiB';
    }

    return formatByteSize(sizeOf(obj));
  }
};

export default Var;
