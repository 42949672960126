import React, { useContext, useState, Fragment, useEffect, useRef } from 'react';
import { Col, Row, Modal, ModalBody, InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitter, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { FacebookSelector } from 'react-reactions';

import Flex from '../common/Flex';
import AppContext, { PostContext } from '../../context/Context';
import LikeInactive from '../../assets/img/illustrations/like-inactive.png';
import shareActive from '../../assets/img/illustrations/share-active.png';
import shareInactive from '../../assets/img/illustrations/share-inactive.png';
import bookmarkActive from '../../assets/img/illustrations/bookmark-active.png';
import bookmarkInActive from '../../assets/img/illustrations/bookmark-inactive.png';
import { Config } from '../../lib/Config';
import { toast } from 'react-toastify';

import Angry from '../../assets/reacted/Angry.png';
import Haha from '../../assets/reacted/Haha.png';
import Like from '../../assets/reacted/Like.png';
import Love from '../../assets/reacted/Love.png';
import Sad from '../../assets/reacted/Sad.png';
import Wow from '../../assets/reacted/Wow.png';

import like from '../../assets/reacted/Like.gif';
import love from '../../assets/reacted/Love.gif';
import sad from '../../assets/reacted/Sad.gif';
import angry from '../../assets/reacted/Angry.gif';
import wow from '../../assets/reacted/Wow.gif';
import haha from '../../assets/reacted/Haha.gif';

const icons = {
  facebook: {
    like,
    love,
    haha,
    wow,
    sad,
    angry
  }
};

const PostAction = () => {
  const { Favod, Reacted, toggleFavo, Bookmarked, toggleBookmark } = useContext(PostContext);
  const { isGuest, isDark } = useContext(AppContext);
  // const canComment = parseInt(Followed) === 1 || parseInt(User.UserId) === parseInt(PostedBy);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showReacted, setShowReacted] = useState(false);

  const reactedRef = useRef(null);
  let reactedShowHandler;
  let reactedHideHandler;

  const Labels = { haha: 'Haha', love: 'Yêu thích', like: 'Thích', wow: 'Wow', angry: 'Giận dữ', sad: 'Buồn' };

  // check outside reacted click
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (reactedRef.current && !reactedRef.current.contains(event.target)) {
        setShowReacted(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [reactedRef]);

  return (
    <Fragment>
      {!isGuest ? (
        <Row noGutters className="font-weight-semi-bold text-center py-2 fs--1">
          <Col xs="auto">
            <Flex
              align="center"
              className="rounded text-700 mr-3 cursor-pointer"
              onMouseOver={() => {
                reactedShowHandler = setTimeout(() => setShowReacted(true), 300);
                clearTimeout(reactedHideHandler);
              }}
              onMouseOut={() => {
                clearTimeout(reactedShowHandler);
                reactedHideHandler = setTimeout(() => setShowReacted(false), 800);
              }}
            >
              {!!showReacted && (
                <div style={{ position: 'absolute', bottom: '36px', zIndex: 1000 }} ref={reactedRef}>
                  <FacebookSelector
                    iconSize={40}
                    labels={Labels}
                    onSelect={Type => {
                      toggleFavo(Type.charAt(0).toUpperCase() + Type.slice(1));
                    }}
                    styleSelector={isDark ? { backgroundColor: '#09111b' } : {}}
                    customIcons={{
                      find: (scope, name) => {
                        return (icons[scope] && icons[scope][name]) || 'n/a';
                      }
                    }}
                  />
                </div>
              )}
              <div
                className="d-flex hover-scale"
                style={{ lineHeight: '20px' }}
                onClick={() => {
                  // by default, toggle love
                  toggleFavo(!Favod ? 'Love' : Reacted);
                }}
              >
                <ReactedIcon />
                <span className="ml-1" style={{ transform: 'scale(1)', lineHeight: '20px' }}>
                  {Labels[!Favod ? 'love' : Reacted.toLowerCase()]}
                </span>
              </div>
            </Flex>
          </Col>
          {/* <Col xs="auto">
            <Flex align="center" className="rounded text-700 mr-3 cursor-pointer hover-scale">
              <img src={canComment ? commentActive : commentInActive} alt="" width="20" />
              <span
                style={{ transform: 'scale(1)', lineHeight: '20px' }}
                className={`ml-1 ${canComment ? '' : 'text-muted'}`}
              >
                Bình luận
              </span>
            </Flex>
          </Col> */}
          <Col xs="auto">
            <Flex
              onClick={() => {
                toggleBookmark();
              }}
              align="center"
              className="rounded text-700 mr-3 cursor-pointer hover-scale"
            >
              <img src={parseInt(Bookmarked) > 0 ? bookmarkActive : bookmarkInActive} alt="" width="20" />
              <span style={{ transform: 'scale(1)', lineHeight: '20px' }} className="ml-1">
                {parseInt(Bookmarked) === 0 ? 'Lưu' : 'Bỏ Lưu'}
              </span>
            </Flex>
          </Col>
          <Col xs="auto">
            <Flex
              onClick={() => {
                setIsModalOpen(!isModalOpen);
              }}
              align="center"
              className="rounded text-700 mr-3 cursor-pointer hover-scale"
            >
              <img src={true ? shareActive : shareInactive} alt="" width="20" />
              <span style={{ transform: 'scale(1)' }} className="ml-1">
                Chia sẻ
              </span>
            </Flex>
          </Col>
        </Row>
      ) : (
        <div />
      )}
      {isModalOpen && <ModalShare isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}
    </Fragment>
  );
};

const ReactedIcon = () => {
  const { Reacted } = useContext(PostContext);
  const Reacteds = {
    Haha,
    Like,
    Love,
    Sad,
    Wow,
    Angry
  };
  return <img src={Reacted.length > 0 ? Reacteds[Reacted] : LikeInactive} alt="reacted" width="20" height="20" />;
};

const ModalShare = ({ isModalOpen, setIsModalOpen }) => {
  const { PostUrl, Content } = useContext(PostContext);
  const handleCopy = () => {
    navigator.clipboard.writeText(`${Config.domain}/post/${PostUrl}`);
    toast('Đã copy liên kết vào bộ nhớ');
  };

  return (
    <Modal isOpen={isModalOpen}>
      <ModalBody className="p-3 text-center">
        <a
          onClick={() => setIsModalOpen(false)}
          href="#!"
          style={{ width: '32px', height: '32px', display: 'block', textAlign: 'center', top: '16px', right: '16px' }}
          className="position-absolute text-500"
        >
          <FontAwesomeIcon style={{ lineHeight: '32px' }} icon="times" />
        </a>
        <h5 className="text-700 text-left pt-2 pl-2">Chia sẻ bài đăng</h5>
        <Row className="mt-4">
          <Col>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${Config.domain}/post/${PostUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-pill btn-light btn"
              style={{ width: '50px', height: '50px', lineHeight: '38px', textAlign: 'center' }}
            >
              <FontAwesomeIcon color="#3b5998" size="xl" icon={faFacebookSquare} />
            </a>
          </Col>
          <Col>
            <a
              href={`fb-messenger://share/?link=${Config.domain}/post/${PostUrl}/send-dialog&app_id=2520558274689291`}
              className="rounded-pill btn-light btn"
              style={{ width: '50px', height: '50px', lineHeight: '38px', textAlign: 'center' }}
            >
              <FontAwesomeIcon color="#0084ff" icon={faFacebookMessenger} />
            </a>
          </Col>
          <Col>
            <a
              href={`http://twitter.com/share?text=${Content}&url=${Config.domain}/post/${PostUrl}`}
              className="rounded-pill btn-light btn"
              style={{ width: '50px', height: '50px', lineHeight: '38px', textAlign: 'center' }}
            >
              <FontAwesomeIcon color="#1da1f2" icon={faTwitter} />
            </a>
          </Col>
        </Row>
        <div className="px-2 pt-5 pb-3">
          <p className="text-left text-900 mb-1 font-weight-semi-bold">Hoặc sao chép liên kết</p>
          <InputGroup>
            <Input
              readOnly
              value={`${Config.domain}/post/${PostUrl}`}
              className="border-right-0 bg-white text-600 pl-2"
            />
            <InputGroupAddon className="bg-white border-left-0" addonType="append">
              <InputGroupText className="bg-white font-weight-semi-bold">
                <a href="#!" onClick={handleCopy}>
                  Copy
                </a>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default PostAction;
