import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'reactstrap';

const NavbarVerticalMenuItem = ({ route }) => (
  <div className="position-relative">
    {route.icon && (
      <span className={`nav-link-icon d-inline-block ${route.className}`}>
        <FontAwesomeIcon icon={route.icon} size="1x" />
      </span>
    )}
    <span className="nav-link-text font-weight-semi-bold ml-2">{route.name}</span>
    {!!route.badge && (
      <Badge
        color={route.badge.color || 'soft-success'}
        style={{ top: '0.6rem', right: '0.6rem' }}
        pill
        className="ml-4 position-absolute"
      >
        {route.badge.text}
      </Badge>
    )}
  </div>
);

NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
    name: PropTypes.string.isRequired
  }).isRequired
};

export default React.memo(NavbarVerticalMenuItem);
