import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Media, CardFooter, CardBody } from 'reactstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons';
import PropTypes from 'prop-types';
import _ from '../../lib/Var';
import Flex from '../../components/common/Flex';

import Background from '../../components/common/Background';
import corner1 from '../../assets/img/illustrations/corner-2.png';
import StarCount from '../../components/common/StarCount';
import CardHeader from 'reactstrap/lib/CardHeader';

const iosRating = 'https://itunes.apple.com/lookup?id=1506375124&country=vn';
const andRating = 'https://pety.vn/tra/bridge/bridge/ggplay';

const AppReviews = () => {
  return (
    <div className="container mt-9 mb-3">
      <div className="w-75 text-center mx-auto mb-5 ">
        <h2 className="font-weight-bold">Pety nhận nhiều đánh giá tích cực</h2>
        <span className="font-weight-normal text-muted">Trên Appstore & Google Play</span>
      </div>

      <Row>
        <Col md="6" className="pb-4">
          <ReviewsIos />
        </Col>
        <Col md="6" className="pb-4">
          <ReviewsAndroid />
        </Col>
      </Row>
    </div>
  );
};

const ReviewsIos = () => {
  const [rating, setRating] = useState('5.0 ');
  const [ratingCount, setRatingCount] = useState(0);
  const reviews = [
    {
      name: 'Cao Moon',
      rating: 5,
      title: 'Hay',
      review: 'Đây là app cho tôi hiểu biết thêm về các em boss trong nhà tôi rất hài lòng về cái app này'
    },
    {
      name: 'Bảo_Như_4129',
      rating: 5,
      title: '❤️🐶',
      review: 'App rất tốt giúp các sen có thể theo dõi các hoạt động sức khỏe của Boss và tìm được nhìu bạn mới 😙😙'
    },
    {
      name: 'Nhat Nong',
      rating: 5,
      title: '5 sao luôn hehe',
      review: 'App dễ thương, đúng một app xịn cho boss luôn, có nhiều phần thưởng để đổi lắm nhé mọi người ơi'
    }
  ];

  // loading store data
  useEffect(() => {
    axios.post(iosRating, { Accept: 'application/json' }).then(res => {
      setRating(_.get(res, 'data.results[0].averageUserRating'));
      setRatingCount(_.get(res, 'data.results[0].userRatingCount'));
    });
  }, []);

  return (
    <Card className="shadow-sm h-100 pb-5">
      <CardHeader className="position-relative pb-2 bg-soft-info text-1000">
        <Background image={corner1} className="p-card bg-card" style={{ zIndex: 0, borderBottomRightRadius: 0 }} />
        <AppRating
          Rating={rating}
          RatingCount={ratingCount}
          AppIcon={() => <FontAwesomeIcon color="black" size="3x" icon={faApple} />}
        />
      </CardHeader>
      <CardBody className="p-3">
        {Array.isArray(reviews) &&
          reviews
            .splice(0, 3)
            .map((item, index) => (
              <ReviewItem
                key={`ios-review-${index}`}
                Title={_.getString(item, 'title')}
                Message={_.getString(item, 'review')}
                Name={_.getString(item, 'name')}
                Rating={_.getString(item, 'rating')}
              />
            ))}
      </CardBody>
      <CardFooter style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
        <a
          className="btn btn-block btn-sm bg-soft-info text-900"
          href="https://apps.apple.com/vn/app/pety/id1506375124#see-all/reviews"
        >
          Xem toàn bộ nhận xét trên Appstore
        </a>
      </CardFooter>
    </Card>
  );
};

const ReviewsAndroid = () => {
  const [rating, setRating] = useState('4.6');
  const [ratingCount, setRatingCount] = useState(212);
  const reviews = [
    {
      name: 'My Doan',
      rating: 5,
      title: null,
      review:
        'Chời ơi dù ko phải là fan của pet thì dô đây kiểu gì cũng nghiện, cũng muốn kiếm một boss về để nguyện làm con sen 😁😁'
    },
    {
      name: 'Thư Nguyễn',
      rating: 5,
      title: null,
      review:
        'App có nhiều tính năng rất hay vd như theo dõi lịch xổ giun hoặc tiêm ngừa, khi đủ điểm thưởng sẽ đc tặng pate hoặc các ưu đãi khác. Mọi thứ đều rất ok trừ việc app load rất chậm, hay bị lag, nói chung thao tác chậm nên hơi tốn thời gian sử dụng. Tổng thể thì mình khá thích xài app vì có cơ hội giao lưu với các sen khác, mong mọi người cải thiện nhenn. Mình cảm ơn (Bánh Bao Mami 🥰)'
    },
    {
      name: 'ss taikhoan',
      rating: 5,
      title: null,
      review: 'Ứng dụng dễ thương quá'
    }
  ];

  // loading store data
  useEffect(() => {
    axios.get(andRating).then(res => {
      setRating(_.get(res, 'data.module.AppInfo.Rating'));
      setRatingCount(_.get(res, 'data.module.AppInfo.Reviews'));
    });
  }, []);

  return (
    <Card className="shadow-sm h-100 pb-5">
      <CardHeader className="position-relative bg-soft-info text-1000 pb-2">
        <Background image={corner1} className="p-card bg-card" style={{ zIndex: 0, borderBottomRightRadius: 0 }} />
        <AppRating
          Rating={rating}
          RatingCount={ratingCount}
          AppIcon={() => <FontAwesomeIcon color="black" size="3x" icon={faGooglePlay} />}
        />
      </CardHeader>
      <div className="p-3">
        {Array.isArray(reviews) &&
          reviews
            .splice(0, 3)
            .map((item, index) => (
              <ReviewItem
                key={`and-review-${index}`}
                Title={_.getString(item, 'title')}
                Message={_.getString(item, 'review')}
                Name={_.getString(item, 'name')}
                Rating={_.getString(item, 'rating')}
              />
            ))}
      </div>
      <CardFooter style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
        <a
          className="btn btn-block btn-sm bg-soft-info text-900"
          href="https://play.google.com/store/apps/details?id=com.pety.moon&showAllReviews=true"
        >
          Xem toàn bộ nhận xét trên Google Play
        </a>
      </CardFooter>
    </Card>
  );
};

const ReviewItem = ({ Title, Message, Name, Rating }) => {
  return (
    <Card className="shadow-none text-900 mb-3 p-2 px-3" style={{ fontSize: '0.9rem', backgroundColor: '#f5f5f5' }}>
      <Flex justify="between" style={{ fontSize: '0.8rem' }}>
        <div>
          <StarCount stars={Rating} color="#ffd081" />
        </div>
        <p className="font-weight-semi-bold text-muted mb-2">{Name}</p>
      </Flex>
      <p className="font-weight-semi-bold mb-1">{Title}</p>
      <p className="mb-0">{Message}</p>
    </Card>
  );
};

const AppRating = ({ AppIcon, Rating, RatingCount }) => {
  return (
    <Media className="mb-1">
      <Media left className="mr-3">
        <AppIcon />
      </Media>
      <Media body>
        <div className="font-size-medium">
          <span>
            <b>{`${Rating}`.substring(0, 4)}</b> • <b>{RatingCount}</b> đánh giá
          </span>
        </div>
        <div className="position-relative">
          <StarCount stars={5} color="#9da9bb" />
          <div
            className="position-absolute"
            style={{
              top: '4px',
              left: 0,
              width: `${1.125 * parseFloat(Rating)}em`,
              height: '1.5em',
              overflow: 'hidden',
              display: 'flex'
            }}
          >
            <StarCount stars={Math.round(Rating)} color="#ffa000" />
          </div>
        </div>
      </Media>
    </Media>
  );
};

export default AppReviews;

AppRating.propTypes = {
  AppIcon: PropTypes.func.isRequired,
  Rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  RatingCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

ReviewItem.propTypes = {
  Title: PropTypes.string,
  Message: PropTypes.string,
  Name: PropTypes.string,
  Rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
