import React, { useState, useEffect, useRef } from 'react';
import lo from 'lodash';
import { SearchContext } from '../context/Context';
import { apis, Config } from '../lib/Config';
import J from '../lib/Jalio';
import _ from '../lib/Var';
import useStorage from '../lib/Store';

const SearchProvider = ({ children }) => {
  // states for feed
  const [Keyword, setKeyword] = useStorage('SearchKeyword', '');
  const [Posts, setPosts] = useState([]);
  const [Lmstart, setLmstart] = useState(0);
  const [LoadedAll, setLoadedAll] = useState(false);
  const [Pets, setPets] = useState([]);
  const [Buds, setBuds] = useState([]);
  const [Arts, setArts] = useState([]);
  const [Vets, setVets] = useState([]);
  const [Searching, setSearching] = useState(false);
  const [Searched, setSearched] = useState(false);
  const [Recents, setRecents] = useStorage('SearchRecents', []);
  const [SearchType, setType] = useStorage('SearchType', '');
  const [Total, setTotal] = useState(0);

  const [isShowPanel, setIsShowPanel] = useState(false);

  let searchTimeoutHandle = useRef(null);

  const searchPeopleAndPet = callback => {
    J.post(apis.search, { SearchForType: 5, SearchKey: Keyword }).then(res => {
      setPets(_.get(res, 'module.Results.Pets', []));
      setBuds(_.get(res, 'module.Results.People', []));
      typeof callback === 'function' && callback(res);
    });
  };

  const search = callback => {
    const TypeVals = { Post: 0, Bud: 1, Article: 2, Pet: 3, Vet: 6 };
    J.post(apis.search, { SearchForType: TypeVals[SearchType], SearchKey: Keyword, lmstart: Lmstart }).then(res => {
      // response items
      const Items = _.get(res, 'module.Results', []);

      // total results
      setTotal(_.getInt(res, 'module.Total', 0));

      if (Array.isArray(Items)) {
        switch (SearchType) {
          case 'Post':
            setPosts(...[lo.union(Posts, Items)]);
            break;
          case 'Bud':
            setBuds(...[lo.union(Buds, Items)]);
            break;
          case 'Article':
            setArts(...[lo.union(Arts, Items)]);
            break;
          case 'Vet':
            setVets(...[lo.union(Vets, Items)]);
            break;
          default:
            setPets(...[lo.union(Pets, Items)]);
        }

        // lmstart and loaded all
        setLmstart(Lmstart + Items.length);

        // check and update the limit-start
        if (Items.length < Config.listLimit) {
          setLoadedAll(true);
        }
      } else setLoadedAll(true);

      typeof callback === 'function' && callback(res);
    });
  };

  useEffect(() => {
    if (searchTimeoutHandle !== null) clearTimeout(searchTimeoutHandle);

    if (Searching || LoadedAll) return;

    // pending 0.4s on changing keyword before request the server
    if (typeof Keyword !== 'undefined' && Keyword.length >= 3) {
      setSearching(true);
      // eslint-disable-next-line
      searchTimeoutHandle = setTimeout(() => {
        switch (SearchType) {
          case 'SearchBox':
            searchPeopleAndPet(() => {
              setSearched(true);
              setSearching(false);
            });
            break;
          default:
            search(() => setSearching(false));
        }
      }, 400);
    }
  }, [Keyword]);

  useEffect(() => {
    if (searchTimeoutHandle !== null) clearTimeout(searchTimeoutHandle);

    if (Searching || LoadedAll) return;

    // pending 0.4s on changing keyword before request the server
    if (typeof Keyword !== 'undefined' && Keyword.length >= 3) {
      setSearching(true);

      switch (SearchType) {
        case 'SearchBox':
          searchPeopleAndPet(() => {
            setSearched(true);
            setSearching(false);
          });
          break;
        default:
          search(() => setSearching(false));
      }
    }

    // eslint-disable-next-line
  }, [SearchType, LoadedAll]);

  useEffect(() => {
    setLmstart(0);
    setPosts([]);
    setArts([]);
    setBuds([]);
    setPets([]);
    setVets([]);
    setLoadedAll(false);
    setSearching(false);
  }, [SearchType]);

  const Values = {
    SearchType,
    setType,
    Keyword,
    setKeyword,
    Posts,
    Pets,
    Buds,
    Arts,
    Vets,
    Searching,
    setSearching,
    Searched,
    LoadedAll,
    isShowPanel,
    setIsShowPanel,
    Recents,
    setRecents,
    Total,
    search
  };

  return <SearchContext.Provider value={Values}>{children}</SearchContext.Provider>;
};

export default SearchProvider;
