import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import LazyLoad from 'react-lazyload';

import qrCode from '../../assets/pety/qr-code-download-app.jpg';
import { ReactComponent as AppleIcon } from '../../assets/img/landing/apple.svg';
import { ReactComponent as GooglePlayIcon } from '../../assets/img/landing/google-play.svg';

const AboutPety = () => {
  return (
    <div className="container pt-8 mb-10" id="about-pety">
      <div className="w-md-75 px-2 px-md-4 mx-auto text-center mb-5">
        <p className="h5 font-weight-medium mb-3 text-600" style={{ lineHeight: 1.6 }}>
          <FontAwesomeIcon icon={faQuoteLeft} className="mr-3" />
          Pety là ứng dụng để bạn kết nối với những người có cùng tình yêu thú cưng, mua sắn sản phẩm, dịch vụ, chia sẻ
          kinh nghiệm chăm sóc, tra cứu và tìm kiếm các thông tin hữu ích, và cũng là để ngắm hàng ngàn "boss" đáng yêu
          từ khắp nơi!
        </p>
        <p className="h5 font-weight-medium mb-3 text-600" style={{ lineHeight: 1.6 }}>
          Bạn có thể tạo hồ sơ sức khỏe cho thú cưng của mình, và chúng tôi sẽ dùng công nghệ 4.0 để trợ giúp bạn trong
          quá trình chăm sóc và nuôi dưỡng các bạn ấy.
          <FontAwesomeIcon icon={faQuoteRight} className="ml-3" />
        </p>
      </div>
      <div className="text-center">
        <span>🐈 🐕 🦜 🦚 🐍 🐁 🐇 🦉 🐠 🦎</span>
      </div>
      <div className="mt-7">
        <Row>
          <Col className="text-center text-md-right" sm="12" md="6">
            <span className="d-none d-lg-inline-block text-muted">
              Quét mã QR để cài &nbsp; <FontAwesomeIcon icon={faArrowRight} />
            </span>
            <div style={{ width: '138px' }} className="d-inline-block mx-2">
              <LazyLoad>
                <img
                  style={{ width: '136px', height: '136px' }}
                  src={qrCode}
                  alt="Scan QR Code to download Pety"
                  title="Scan QR Code to download Pety"
                  className="border"
                />
              </LazyLoad>
              <span className="d-block d-lg-none text-muted mt-2">Quét mã QR để cài</span>
            </div>
          </Col>
          <Col
            className="text-center text-md-left"
            style={{ paddingTop: '20px', paddingBottom: '20px' }}
            sm="12"
            md="6"
          >
            <div className="d-inline-block mr-2" style={{ lineHeight: '80px', verticalAlign: 'middle' }}>
              <a
                className="text-dark mx-2 d-inline-block rounded-circle bg-soft-info text-center"
                href="https://apps.apple.com/vn/app/pety/id1506375124"
                alt="Apple Store"
                title="Apple Store"
                style={{ width: '89px', height: '89px', fontSize: '2.5rem' }}
              >
                <AppleIcon style={{ width: '46px', height: '46px' }} />
              </a>
              <a
                className="text-dark rounded-circle d-inline-block bg-soft-info text-center"
                href="https://play.google.com/store/apps/details?id=com.pety.moon"
                alt="Google Play"
                title="Google Play"
                style={{ width: '89px', height: '89px', fontSize: '2.5rem' }}
              >
                <GooglePlayIcon style={{ width: '46px', height: '46px' }} />
              </a>
            </div>
            <span className="d-block d-lg-none text-muted mt-2">Bấm để cài cho iOS/Android</span>
            <span className="d-none d-lg-inline-block text-muted" style={{ lineHeight: '80px' }}>
              <FontAwesomeIcon icon={faArrowLeft} /> &nbsp; Bấm để cài cho iOS/Android
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AboutPety;
